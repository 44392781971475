import React, { useEffect, useState } from "react"
import axios from "axios"
import "./login-screen.css"
import EnelsaLogo from "../assets/logo-enelsa.png"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEyeSlash, faEye, faCircleInfo, faRegistered, faXmarksLines } from "@fortawesome/free-solid-svg-icons"
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import md5 from "md5-hash"
import Swal from 'sweetalert2'
import IconButton from '@mui/material/IconButton'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom"
import CookieManager from "../cookieManager"

function telefonInputOlustur(valueDegeri, valueDegeriFonksiyonu) {
    return (
        <div className="phone-input">
            <PhoneInput
                style={{ backgroundColor: "rgb(70, 85, 105)" }}
                international
                countryCallingCodeEditable={false}
                defaultCountry="TR"
                value={valueDegeri}
                onChange={valueDegeriFonksiyonu}
                error={valueDegeri ? (isValidPhoneNumber(valueDegeri) ? undefined : 'Geçersiz Telefon Numarası') : 'Telefon Numarası Giriniz'} />
        </div>
    )
}

const CustomToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgb(70, 85, 105)',
        color: 'aliceblue',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '2px dotted rgb(202, 203, 204)',
        padding: '15px'
    },
}));


const LoginScreen = (props) => {

    let navigate = useNavigate()
    const [ilkAcilis, setIlkacilis] = useState(true)
    const [kullaniciAdi, setKullaniciAdi] = useState(CookieManager.getCookie("kullaniciAdi") === null ? "" : CookieManager.getCookie("kullaniciAdi"))
    const [mailAdresi, setMailAdresi] = useState("")
    const [telefonNo, setTelefonNo] = useState("")
    const [nameSurname, setNameSurname] = useState("")
    const [kullaniciSifresi, setKullaniciSifresi] = useState("")
    const [sifreGizle, setSifreGizle] = useState(true)
    const [beniHatirla, setBeniHatirla] = useState(CookieManager.getCookie("beniHatirla") === null ? false : CookieManager.getCookie("beniHatirla") === "true")
    const [hata, setHata] = useState(false)
    const [isLogin, setIsLogin] = useState(true)
    const [hataText, setHataText] = useState("")
    const [kodVar, setKodVar] = useState(false)
    const [dogrulamaKodu, setDogrulamaKodu] = useState("")
    const [yeniSifre, setYeniSifre] = useState("")
    const [yeniSifreDogrulama, setyeniSifreDogrulama] = useState("")
    const [referans, setReferans] = useState("")
    const [dogrulama, setDogrulama] = useState("")

    const girisYap = async () => {
        CookieManager.deleteCookie("token")
        if (kullaniciAdi === "" || kullaniciSifresi === "") return;

        let gonderilecekData = {
            kullaniciAdi: kullaniciAdi,
            kullaniciSifresi: kullaniciSifresi
        }

        try {
            const res = await axios.put("https://api.enelsa.com:5015/api/login", gonderilecekData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (res.data.giris === "Başarılı") {
                let kaydedilecekKullaniciAdi = "";
                let kaydedilecekKullaniciSifresi = "";

                if (beniHatirla) {
                    kaydedilecekKullaniciAdi = kullaniciAdi;
                    kaydedilecekKullaniciSifresi = kullaniciSifresi;
                }

                let hesaplar = {
                    anaHesap: {
                        kullaniciAdi: kaydedilecekKullaniciAdi,
                        kullaniciSifresi: kaydedilecekKullaniciSifresi,
                        dToken: "",
                        token: res.data.token,
                        id: res.data.kullaniciBilgileri.kullaniciId
                    },
                    ekliHesaplar: []
                };

                // console.log(res.data.kullaniciBilgileri)

                CookieManager.setCookie("token", res.data.token, beniHatirla ? 30 : null);
                CookieManager.setCookie("phone", res.data.kullaniciBilgileri.phone, beniHatirla ? 30 : null)
                CookieManager.setCookie("email", res.data.kullaniciBilgileri.email, beniHatirla ? 30 : null)
                CookieManager.setCookie("id", res.data.kullaniciBilgileri.kullaniciId, beniHatirla ? 30 : null);
                CookieManager.setCookie("hesaplar", JSON.stringify(hesaplar), beniHatirla ? 30 : null);
                CookieManager.setCookie("kullaniciAdi", res.data.kullaniciBilgileri.kullaniciAdi, beniHatirla ? 30 : null);
                CookieManager.setCookie("beniHatirla", beniHatirla, beniHatirla ? 30 : null);
                CookieManager.setCookie("kullaniciAdiSoyadi", res.data.kullaniciBilgileri.kullaniciAdiSoyadi, beniHatirla ? 30 : null)

                props.loginAyarlari({ token: res.data.token, tarih: Math.floor((new Date()).getTime() / 1000 / 60 / 60) });
                navigate("/");
                props.loginGuncelle();
                return;
            } else {
                setHata(true);
                setHataText("Kullanıcı Adı ya da Şifre Hatalı")
                Swal.fire({
                    icon: 'error',
                    title: 'Kullanıcı Adı ya da Şifre Hatalı',
                    text: 'Lütfen bilgilerinizi kontrol ediniz.'
                })
            }
        } catch (error) {
            setHataText("Kullanıcı Adı ya da Şifre Hatalı")
            Swal.fire({
                icon: 'error',
                title: 'Kullanıcı Adı ya da Şifre Hatalı',
                text: 'Lütfen bilgilerinizi kontrol ediniz.'
            })
        }

    };

    const sifremiUnuttum = async () => {
        if (kullaniciAdi === "")
            return

        try {
            let gonderilecekData = {
                kullaniciAdi: kullaniciAdi
            }
            const res = await axios.post("https://api.enelsa.com:5015/api/reset-password", gonderilecekData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if (res.data == "kullaniciHata") {
                setHataText("Kullanıcı Adı Hatalı")
                Swal.fire({
                    icon: 'error',
                    title: 'Kullanıcı Adı Hatalı',
                    text: 'Lütfen kullanıcı adını kontrol ediniz.'
                })
            } else if (res.status == 500) {
                setHataText("Hata")
                Swal.fire({
                    icon: 'error',
                    title: 'Bir Sorun Oluştu',
                    text: 'Lütfen daha sonra tekrar deneyin.'
                })
            } else {
                setKodVar(true)
                handleClick()
                setReferans(res.data.referansCode)
                setDogrulama(res.data.code)
            }

        } catch (error) {
            console.error("İstek hatası:", error.response?.status, error.response?.data); // Hata durumunu yakala
            Swal.fire({
                icon: 'error',
                title: 'Bir Sorun Oluştu',
                text: 'Lütfen daha sonra tekrar deneyin.'
            })
        }
    }

    const sifreyiDegistir = async () => {
        const gonderilecekData = {
            kullaniciAdi: kullaniciAdi,
            yeniSifre: yeniSifre,
            dogrulamaKodu: dogrulamaKodu
        }
        setHataText("")
        if (yeniSifre === "" || yeniSifreDogrulama === "") {
            setHataText("Şifre Boş Bırakılamaz")
            Swal.fire({
                icon: 'error',
                title: 'Şifre Boş Bırakılamaz',
                text: 'Lütfen şifre bölümlerini doldurunuz.'
            })
        }
        else if (yeniSifre !== yeniSifreDogrulama) {
            setHataText("Şifreler Uyuşmuyor")
            Swal.fire({
                icon: 'error',
                title: 'Şifreler Uyuşmuyor',
                text: 'Lütfen şifrelerin aynı olduğundan emin olunuz.'
            })
        }
        else if (dogrulamaKodu === "") {
            setHataText("Kod Boş Bırakılamaz")
            Swal.fire({
                icon: 'error',
                title: 'Doğrulama Kodu Boş',
                text: 'Lütfen doğrulama kodunu giriniz.'
            })
        }
        else if (dogrulama !== dogrulamaKodu) {
            setHataText("Doğrulama Kodu Hatalı")
            Swal.fire({
                icon: 'error',
                title: 'Doğrulama Kodu Hatalı',
                text: 'Lütfen doğrulama kodunu kontrol ediniz.'
            })
        }
        else {
            try {
                const res = await axios.post("https://api.enelsa.com:5015/api/set-password", gonderilecekData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                if (res.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Şifre Değiştirme İşlemi Başarılı',
                        text: 'Yeni şifreniz ile giriş yapabilirsiniz.',
                        confirmButtonText: 'Tamam',
                        confirmButtonColor: 'rgb(70, 85, 105)',
                        width: '25em'
                    })
                    let location = window.location.href
                    location = location.split("#")[0]
                    location = location + "#modal-closed"
                    window.location.href = location
                } else if (res.status === 400) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Kullanıcı Adı Hatalı',
                        text: 'Lütfen kullanıcı adını kontrol ediniz.'
                    })
                    // let location = window.location.href
                    // location = location.split("#")[0]
                    // location = location + "#modal-closed"
                    // window.location.href = location
                }
            } catch (e) {
                // console.log(e)
            }

        }

    }

    const kayitOl = async () => {
        const reg = /^[A-Za-zÇĞÜŞÖİçğüşöı0-9 ]{4,}$/;
        const regKullaniciAdi = /^[a-z0-9-_.]{4,}$/;
        const regMail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        const gonderilecekData = {
            UserName: kullaniciAdi,
            Password: kullaniciSifresi,
            NameSurname: nameSurname,
            email: mailAdresi,
            phone: telefonNo,
            aktivasyon: 0
        }
        if (reg.test(nameSurname) === true) {
            if (regKullaniciAdi.test(kullaniciAdi)) {
                if (regMail.test(mailAdresi) === true) {
                    if (telefonNo !== undefined && isValidPhoneNumber(telefonNo.toString())) {
                        setHataText("")
                        try {
                            const res = await axios.post("https://api.enelsa.com:5015/api/register", JSON.stringify(gonderilecekData),
                                {
                                    headers: {
                                        'Content-Type': "application/json"
                                    }
                                })
                            if (res.data === "duplicate") {
                                setHataText("Lütfen farklı bir kullanıcı adı deneyiniz.")
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Kullanılan Kullanıcı Adı',
                                    text: 'Lütfen farklı bir kullanıcı adı deneyin.'
                                })
                            } else if (res.data === "ok") {
                                setIsLogin(true)
                                setNameSurname("")
                                setMailAdresi("")
                                setTelefonNo("")
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Yeni Kayıt İşlemi Başarılı',
                                    text: 'Bilgileriniz ile giriş yapabilirsiniz.',
                                    confirmButtonText: 'Tamam',
                                    confirmButtonColor: 'rgb(70, 85, 105)',
                                    width: '25em',

                                })
                            }
                        } catch (e) {
                            // console.log(e)
                        }
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Geçersiz Telefon Numarası',
                            text: 'Lütfen geçerli bir telefon numarası girin.',
                            confirmButtonText: 'Tamam',
                            confirmButtonColor: 'rgb(70, 85, 105)',
                            width: '25em',
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Geçersiz Mail Adresi',
                        text: 'Lütfen geçerli bir mail adresi giriniz',
                        confirmButtonText: 'Tamam',
                        confirmButtonColor: 'rgb(70, 85, 105)',
                        width: '25em'
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Geçersiz Kullanıcı Adı',
                    text: 'Lütfen geçerli bir kullanıcı adı giriniz.',
                    confirmButtonText: 'Tamam',
                    confirmButtonColor: 'rgb(70, 85, 105)',
                    width: '25em'
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Geçersiz İsim',
                text: 'Lütfen geçerli bir isim giriniz.',
                confirmButtonText: 'Tamam',
                confirmButtonColor: 'rgb(70, 85, 105)',
                width: '25em'
            })
        }

    }

    useEffect(() => {
        // setHataText("")
        if (ilkAcilis) {
            props.cikisYap()
            setIlkacilis(false)
        }
    }, [ilkAcilis, props])

    const [isDisabled, setIsDisabled] = useState(false);
    const [countdown, setCountdown] = useState(0);

    const handleClick = () => {
        setIsDisabled(true); // Butonu devre dışı bırak
        setTimeout(() => {
            setIsDisabled(false); // Belirli bir süre sonra tekrar etkin hale getir
        }, 2 * 60 * 1000); // 2 dakika
    };

    useEffect(() => {
        if (isDisabled) {
            let timer = 120; // 2 dakika = 120 saniye
            const interval = setInterval(() => {
                if (timer > 0) {
                    setCountdown(timer);
                    timer--;
                } else {
                    clearInterval(interval);
                }
            }, 1000); // Her saniye güncelle
            return () => clearInterval(interval);
        }
    }, [isDisabled]);

    return (
        <div onKeyDown={(e) => { if (e.key === "Enter") girisYap() }} className="login-screen">
            <div style={{ margin: "auto", width: "90%", position: "relative" }}>
                <div id="login-screen-pencere">
                    <img src={EnelsaLogo} alt="enelsa-logo" id="login-screen-img" />
                    <div className="screen-chose-button-container">
                        <button className={isLogin ? "screen-chose-button screen-chose-button-selected giris-button giris-button-selected" : "screen-chose-button giris-button"} onClick={() => { setIsLogin(true) }}>Giriş Yap</button>
                        <button className={isLogin ? "screen-chose-button kayit-button" : "screen-chose-button screen-chose-button-selected kayit-button kayit-button-selected"} onClick={() => { setIsLogin(false) }}>Kayıt Ol</button>
                    </div>
                    <div className="login-frame">
                        <div id="login-container" className={isLogin ? "login-frame-to-right" : "login-frame-to-left"}>
                            <div id="login-screen-div">
                                <input value={kullaniciAdi} onChange={(e) => setKullaniciAdi(e.target.value)} className="login-screen-input" placeholder="Kullanıcı Adı" />
                                <div style={{ position: "relative" }}>
                                    <input value={kullaniciSifresi} onChange={(e) => setKullaniciSifresi(e.target.value)} className="login-screen-input" style={{ marginTop: "10px" }} placeholder="Şifre" type={sifreGizle ? "password" : "input"} />
                                    <FontAwesomeIcon icon={sifreGizle ? faEye : faEyeSlash} id="sifre-ico" onClick={() => setSifreGizle(!sifreGizle)} />
                                </div>
                                <div id="beni-hatirla-ls" className="beni-hatirla">
                                    <div style={{ display: "flex", alignItems: "center" }} onClick={() => setBeniHatirla(!beniHatirla)}>
                                        <input checked={beniHatirla} type="checkbox" readOnly />
                                        <span>Beni Hatırla</span>
                                    </div>
                                    <div className="login-buttons">
                                        <a href="#sifre-modal" className="sifre-unuttum-buton">
                                            <div style={{ textDecoration: "underline" }}>Şifremi Unuttum</div>
                                        </a>
                                    </div>
                                </div>
                                <div className="login-buttons">
                                    <div id="login-screen-giris-buton" onClick={girisYap}>
                                        <span>Giriş Yap</span>
                                    </div>
                                </div>
                                <span style={{ color: "red", fontSize: "14px", position: "absolute", bottom: "0px", display: hata ? "block" : "none" }} >Hatalı Kullanıcı Adı veya Şifre</span>
                            </div>
                            <div id="login-screen-div" style={{ marginLeft: "120px" }}>
                                <div className="icolu-input-div">
                                    <input value={nameSurname} onChange={(e) => setNameSurname(e.target.value)} className="login-screen-input" placeholder="İsim Soyisim" />
                                    <div className="tooltip">
                                        <CustomToolTip title="İsim Soyisim giriniz." placement="right">
                                            <IconButton>
                                                <FontAwesomeIcon icon={faCircleInfo} className="info-icon" beat style={{ color: "rgb(70, 85, 105)", fontSize: "20px" }} />
                                            </IconButton>
                                        </CustomToolTip>
                                    </div>
                                </div>
                                <div className="icolu-input-div">
                                    <input value={kullaniciAdi} onChange={(e) => setKullaniciAdi(e.target.value)} className="login-screen-input" placeholder="Kullanıcı Adı" />
                                    <div className="tooltip">
                                        <CustomToolTip title="Kullanıcı adı sadece küçük harf,rakam,tire ( - ) veya alt çizgi ( _ ) içerebilir. Türkçe karakter içeremez." placement="right">
                                            <IconButton>
                                                <FontAwesomeIcon icon={faCircleInfo} className="info-icon" beat style={{ color: "rgb(70, 85, 105)", fontSize: "20px" }} />
                                            </IconButton>
                                        </CustomToolTip>
                                    </div>
                                </div>
                                <div className="icolu-input-div">
                                    <div style={{ position: "relative" }}>
                                        <input value={kullaniciSifresi} onChange={(e) => setKullaniciSifresi(e.target.value)} className="login-screen-input" placeholder="Şifre" type={sifreGizle ? "password" : "input"} />
                                        <FontAwesomeIcon icon={sifreGizle ? faEye : faEyeSlash} id="sifre-ico" style={{ top: "7px" }} onClick={() => setSifreGizle(!sifreGizle)} />
                                    </div>
                                    <div className="tooltip">
                                        <CustomToolTip title="Şifreniz en az 8 karakterden oluşmalıdır. Harf, rakam ve mutlaka özel karakter içermelidir. Küçük ve büyük harf kullanılmalıdır." placement="right">
                                            <IconButton>
                                                <FontAwesomeIcon icon={faCircleInfo} className="info-icon" beat style={{ color: "rgb(70, 85, 105)", fontSize: "20px" }} />
                                            </IconButton>
                                        </CustomToolTip>
                                    </div>
                                </div>
                                <div className="icolu-input-div">
                                    <input value={mailAdresi} onChange={(e) => setMailAdresi(e.target.value)} className="login-screen-input" placeholder="Mail Adresi" />
                                    <div className="tooltip">
                                        <CustomToolTip title="Doğrulama ve bilgi mailleri alabilmek için lütfen geçerli bir mail adresi giriniz." placement="right">
                                            <IconButton>
                                                <FontAwesomeIcon icon={faCircleInfo} className="info-icon" beat style={{ color: "rgb(70, 85, 105)", fontSize: "20px" }} />
                                            </IconButton>
                                        </CustomToolTip>
                                    </div>
                                </div>
                                <div className="icolu-input-div">
                                    {telefonInputOlustur(telefonNo, setTelefonNo)}
                                    <div className="tooltip">
                                        <CustomToolTip title="Doğrulama ve bilgi SMSleri alabilmek için lütfen geçerli bir telefon numarası giriniz." placement="right">
                                            <IconButton>
                                                <FontAwesomeIcon icon={faCircleInfo} className="info-icon" beat style={{ color: "rgb(70, 85, 105)", fontSize: "20px" }} />
                                            </IconButton>
                                        </CustomToolTip>
                                    </div>
                                </div>
                                <div className="login-buttons">
                                    <div id="login-screen-giris-buton" style={{ marginTop: "5px" }} onClick={kayitOl}>
                                        <span>Kayıt Ol</span>
                                    </div>
                                </div>
                                <span style={{ color: "red", fontSize: "14px", position: "relative", display: hataText !== "" ? "block" : "block" }} >{hataText}</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-container" id="sifre-modal" >
                <div className="modal" style={{ overflow: "hidden" }}>
                    <div className="modal__details">
                        <h1 className="modal__title">Şifre Sıfırla</h1>
                    </div>
                    <div className="sifre-sifirla-mail-div">
                        <div>
                            <span>Kullanıcı Adı :</span>
                            <input style={{ width: "169px", outline: "none", paddingLeft: "4px", marginLeft: "5px", color: "#42494F" }} className="arka-taraf-birimler-input2" value={kullaniciAdi} onChange={(e) => setKullaniciAdi(e.target.value)} />
                        </div>
                        <div style={{ textAlign: "center" }}>
                            Hesabınıza tanımlı mail adresinize/telefon numaranıza doğrulama kodu iletilecektir.
                        </div>
                        <div className="kod-gonder-buton-div">
                            <button className={isDisabled ? "modal__btn_disabled" : "modal__btn"} onClick={() => { sifremiUnuttum({ kullaniciAdi }, props.bildirimleriYonet) }} disabled={isDisabled}>Kod Gönder</button>
                            {isDisabled && <p>{countdown} saniye sonra yeniden kod gönderebilirsiniz.</p>}
                        </div>
                    </div>
                    {kodVar &&
                        <div className="sifre-sifirla-mail-div">
                            <div>
                                <div className="sifre-degistir-div">
                                    <span className="sifre-degistir-ilk-span">Referans Kodu </span> :
                                    <span style={{ marginLeft: "5px" }}>{referans}</span>
                                </div>
                                <div className="sifre-degistir-div">
                                    <span className="sifre-degistir-ilk-span">Doğrulama Kodu </span>:
                                    <form>
                                        <input style={{ width: "169px", outline: "none", paddingLeft: "4px", marginLeft: "5px", color: "#42494F" }} className="arka-taraf-birimler-input2" value={dogrulamaKodu} onChange={(e) => setDogrulamaKodu(e.target.value)} />
                                    </form>
                                </div>
                                <div className="sifre-degistir-div">
                                    <span className="sifre-degistir-ilk-span">Yeni Şifre </span>:
                                    <form>
                                        <input type="password" style={{ width: "169px", outline: "none", paddingLeft: "4px", marginLeft: "5px", color: "#42494F" }} className="arka-taraf-birimler-input2" value={yeniSifre} onChange={(e) => setYeniSifre(e.target.value)} />
                                    </form>
                                </div>
                                <div className="sifre-degistir-div">
                                    <span className="sifre-degistir-ilk-span">Yeni Şifrenizi Doğrulayın </span>:
                                    <form>
                                        <input type="password" style={{ width: "169px", outline: "none", paddingLeft: "4px", marginLeft: "5px", color: "#42494F" }} className="arka-taraf-birimler-input2" value={yeniSifreDogrulama} onChange={(e) => setyeniSifreDogrulama(e.target.value)} />
                                    </form>
                                </div>
                            </div>
                            <button className="modal__btn" onClick={() => { sifreyiDegistir({ kullaniciAdi }, props.bildirimleriYonet) }}>Şifreyi Değiştir</button>
                        </div>
                    }
                    <a href="#modal-closed" className="link-2"></a>
                    <FontAwesomeIcon className="modal-alt-icon" icon={faXmarksLines} />
                </div>
            </div>
        </div>
    )
}

export default LoginScreen