import React from "react"
import NavBar from "./components/navbar/navbar"
import "./app.css"
import Menu from "./components/menu/menu"
import { Navigate, Route, Routes } from "react-router-dom"
import Anasayfa from "./ana-sayfa/ana-sayfa"
import Cihazlar from "./cihazlar/pages/cihazlar-sayfasi/cihazlar"
import Api from "./api/api"
import Cihaz from "./cihazlar/pages/cihaz-sayfasi/cihaz"
import Bildirimler from "./components/bildirimler/bildirimler"
import AltMenu from "./components/menu/bottom-menu"
import axios from "axios"
import LoginScreen from "./login/login-screen"
import LoginPopup from "./components/login/login"
import Profil from "./profil/profil"
import Grafikler from "./cihazlar/pages/grafikler/grafikler"
import CizgiGrafik from "./cihazlar/pages/grafikler/grafik"
import Lisans from "./lisans-islemleri/lisans"
import KullaniciEkle from "./kullanici-islemleri/kullanici-ekle"
import Gruplar from "./gruplar/gruplar"
import NovaBilgiKarti from "./cihazlar/pages/cihaz-sayfasi/components/nova/nova-bilgi-karti"
import SaltBilgiKarti from "./cihazlar/pages/cihaz-sayfasi/components/salt/salt-bilgi-karti"
import CihazEkle from "./cihazlar/pages/cihaz-ekle/components/cihaz-ekle"
import ActivationPage from "./components/login/activation"
import Tablo from "./cihazlar/pages/tablo/tablo"
import CihazOnay from "./cihazlar/pages/cihaz-onay/components/cihaz-onay"
import CookieManager from "./cookieManager"
import { Cookie } from "@mui/icons-material"
import FullScreenPage from "./cihazlar/pages/cihaz-sayfasi/infopanel"


axios.interceptors.request.use(
    (config) => {
        const token = CookieManager.getCookie('token');

        if (config.url.includes('login') || config.url.includes('register') || config.url.includes('sifre') || config.url.includes('reset-password') || config.url.includes('set-password') || config.url.includes('token')) {
            return config;
        }

        if (!token) {
            //console.warn('Token bulunamadı, istek iptal ediliyor.');
            window.location.replace('/login');
            return Promise.reject(new Error('Token bulunamadı, istek iptal edildi.'));
        }

        //config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);



let kullaniciBilgileriIntervar = null

class App extends React.Component {

    loginKarari() {
        if (window.location.pathname === "/login")
            return false
        let hesaplar = CookieManager.getCookie("hesaplar")
        if (hesaplar === null) {
            if (window.location.pathname !== "/login")
                //window.location.replace('/login')
                return false
            return false
        }
        hesaplar = JSON.parse(hesaplar)
        if (hesaplar.anaHesap === undefined) {
            if (window.location.pathname !== "/login")
                //window.location.replace('/login')
                return false
            return false
        }
        return true
    }

    loginBilgileri() {
        if (window.location.pathname === "/login")
            return {}
        let hesaplar = CookieManager.getCookie("hesaplar")
        if (hesaplar === null) {
            if (window.location.pathname !== "/login")
                //window.location.replace('/login')
                return {}
            return {}
        }
        hesaplar = JSON.parse(hesaplar)
        if (hesaplar.anaHesap === undefined) {
            if (window.location.pathname !== "/login")
                //window.location.replace('/login')
                return {}
            return {}
        }
        return { token: hesaplar.anaHesap.token }
    }

    state = {
        konum: window.location.pathname.split("/")[1],
        menuGenisligi: 60,
        tema: localStorage.getItem("tema") ? localStorage.getItem("tema") : "tema1",
        temelFiltre: "#005fab",
        ekranGenisligi: window.innerWidth,
        bildirimler: {},
        tokenBilgileri: this.loginBilgileri(),
        login: this.loginKarari(),
        tokenKontrol: true,
        yeniKayit: false,
        hesapDegistir: false,
        degisecekHesap: "",
        kullaniciBilgileri: {
            kullaniciAdi: "-",
            kullaniciTipi: "-",
            email: "-",
            kullaniciId: "-",
            kullaniciAdiSoyadi: "-"
        },
        cihazBilgileri: ["-", "-", "-", "-", "-"],
        cihazBilgileri2: ["-"],
        temaAyar: false,
        pin: false,
        cihazIsim: "-"
    }

    kullaniciBilgileriGuncelle = async () => {
        // console.log(CookieManager.getCookie("token"))
        // let gonderilecekData = {
        //     id: CookieManager.getCookie("id")
        // }
        // try {
        //     let kullaniciBilgileri = await axios.post("https://api.enelsa.com:5015/api/kullaniciBilgisi", gonderilecekData, {
        //         headers: {
        //             'authorization': 'Bearer ' + CookieManager.getCookie("token")
        //         }
        //     })
        //     this.setState({
        //         kullaniciBilgileri: kullaniciBilgileri.data
        //     })


        //     CookieManager.setCookie("id", kullaniciBilgileri.data.kullaniciId, 7);
        // } catch {
        //     // // console.log("kullaniciBilgileri.data")
        //     //window.location.replace('/anasayfa')
        // }

    }

    cihazBilgileriGuncelle = (cihazBilgileri) => {
        this.setState({
            cihazBilgileri: cihazBilgileri
        })
    }
    cihazBilgileriGuncelle2 = (cihazBilgileri2) => {
        this.setState({
            cihazBilgileri2: cihazBilgileri2
        })
    }

    hesapDegistirGuncelle = async (durum, hesap) => {
        if (!durum) {
            this.setState({
                hesapDegistir: durum,
                degisecekHesap: ""
            })

            return
        }
        try {

            let res = await axios.get("https://api.enelsa.com:5015/api/tokenKontrol", {
                headers: {
                    'authorization': 'Bearer ' + CookieManager.getCookie("token")
                }
            })

            let hesaplar = CookieManager.getCookie("hesaplar")
            hesaplar = JSON.parse(hesaplar)

            let transferEdilecekAnaHesap = JSON.parse(JSON.stringify(hesaplar.anaHesap))

            let index = -1
            let takip = 0
            for (let e of hesaplar.ekliHesaplar) {
                if (e.kullaniciAdi === hesap.kullaniciAdi) {
                    index = takip
                }
                takip++
            }

            if (index !== -1)
                hesaplar.ekliHesaplar.splice(index, 1)

            hesaplar.anaHesap = hesap
            hesaplar.ekliHesaplar.push(transferEdilecekAnaHesap)

            CookieManager.setCookie("hesaplar", JSON.stringify(hesaplar), 7)

            // // console.log(JSON.parse(CookieManager.getCookie("hesaplar")))

            this.loginAyarlari({ token: hesap.token, tarih: Math.floor((new Date()).getTime() / 1000 / 60 / 60) })

            //window.location.replace("http://api.enelsa.com:3000/anasayfa")
        }
        catch (e) {
            // // console.log("Hesap Deaktif")

            if (!hesap.kullaniciSifresi) {
                this.setState({
                    hesapDegistir: durum,
                    degisecekHesap: hesap.kullaniciAdi
                })

                return
            }

            const res = await axios.put("https://api.enelsa.com:5015/api/login", JSON.stringify({ kullaniciAdi: hesap.kullaniciAdi, kullaniciSifresi: hesap.kullaniciSifresi }), {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if (res.data.giris !== "Başarılı") {
                this.setState({
                    hesapDegistir: durum,
                    degisecekHesap: hesap.kullaniciAdi
                })

                return
            }
            CookieManager.setCookie("id", res.data.kullaniciBilgileri.kullaniciId);

            let hesaplar = CookieManager.getCookie("hesaplar")
            hesaplar = JSON.parse(hesaplar)

            let transferEdilecekAnaHesap = JSON.parse(JSON.stringify(hesaplar.anaHesap))

            let index = -1
            let takip = 0
            for (let e of hesaplar.ekliHesaplar) {
                if (e.kullaniciAdi === hesap.kullaniciAdi) {
                    index = takip
                }
                takip++
            }

            if (index !== -1)
                hesaplar.ekliHesaplar.splice(index, 1)

            hesap.token = res.data.token
            hesaplar.anaHesap = hesap
            hesaplar.ekliHesaplar.push(transferEdilecekAnaHesap)

            CookieManager.setCookie("hesaplar", JSON.stringify(hesaplar), 7)

            this.loginAyarlari({ token: res.data.token, tarih: Math.floor((new Date()).getTime() / 1000 / 60 / 60) })
            //window.location.replace("http://api.enelsa.com:3000/")
            return

        }

    }


    yeniKayitiGuncelle = (durum) => {
        this.setState({
            degisecekHesap: "",
            yeniKayit: durum
        })
    }

    temayiGuncelle = (tema) => {
        this.setState(
            {
                tema: tema
            }
        )
    }

    menuGenisligiAyarla = () => {
        if (this.state.menuGenisligi === 60) {
            this.setState(
                {
                    menuGenisligi: 220
                }
            )
        } else {
            this.setState(
                {
                    menuGenisligi: 60
                }
            )
        }
    }

    konumGuncelle = (konum) => {
        this.setState(
            {
                konum: konum,
            }
        )
    }

    temelFiltreDegistir = (filtre) => {
        this.setState(
            {
                temelFiltre: filtre
            }
        )
    }

    gosterilecekCihaziGuncelle = (cihaz) => {
        // // console.log(cihaz, "99")
        let gidilecekCihazDatasi = cihaz.split(" / ")
        let kaydedilecekData = "?kullaniciAdi=" + gidilecekCihazDatasi[1] + "&seriNo=" + gidilecekCihazDatasi[2] + "&cihazAdı=" + gidilecekCihazDatasi[0]
        CookieManager.setCookie("cihaz", kaydedilecekData, 7)
    }

    ekranBoyutuDegisti = () => {
        this.setState(
            {
                ekranGenisligi: window.innerWidth
            }
        )
    }

    bildirimlerinPozisyonlanmasiniYonet = (key, yeniPozisyon) => {
        let bildirimler = JSON.parse(JSON.stringify(this.state.bildirimler))
        if (bildirimler[key].sagaGorePozisyonu === yeniPozisyon)
            return
        if (!bildirimler[key])
            return -1
        bildirimler[key].sagaGorePozisyonu = yeniPozisyon
        this.setState({
            bildirimler: bildirimler
        })
    }

    bildirimiListedenCikar = (key, islem) => {
        let bildirimler = JSON.parse(JSON.stringify(this.state.bildirimler))
        delete bildirimler[key]
        this.setState(
            {
                bildirimler: bildirimler
            }
        )
    }

    bilidirimiGuncelle = (key, guncellenecekDeger) => {
        let bildirimler = JSON.parse(JSON.stringify(this.state.bildirimler))

        if (this.state.bildirimler[key] === undefined) return
        bildirimler[key].bildirim = guncellenecekDeger
        bildirimler[key].uyari = 0

        this.setState({
            bildirimler: bildirimler
        })

        if (guncellenecekDeger !== 2) {
            setTimeout(() => this.bildirimlerinPozisyonlanmasiniYonet(key, -250), 3000)
            setTimeout(() => this.bildirimiListedenCikar(key, "bildirimKaldir"), 3350)
        }
    }

    //!!!!!!! LOGIN DURUMU
    cihazAyarKaydiSorgulama = async (bildirim) => {
        if (this.state.bildirimler[bildirim.key] === undefined) return

        let seriNo = (bildirim.key.split("-"))[1]
        const res = await axios.get("https://api.enelsa.com:5001/api/readySettings/" + seriNo)
        let guncellenecekBildirimDegeri = bildirim.bildirim

        // // console.log(res)

        if (res.data === "İşleminiz Sürüyor")
            setTimeout(() => this.cihazAyarKaydiSorgulama(bildirim), 5000)
        else if (res.data === "İşlem Tam Gerçekleşemedi")
            guncellenecekBildirimDegeri = 3
        else
            guncellenecekBildirimDegeri = 1

        if (guncellenecekBildirimDegeri === 2)
            return
        this.bilidirimiGuncelle(bildirim.key, guncellenecekBildirimDegeri)
    }

    //!!!!!!! LOGIN DURUMU
    bildirimleriYonet = async (fonksiyondanBeklenenIslem, bildirim) => {


        if (fonksiyondanBeklenenIslem === "bildirimler") {
            return JSON.parse(JSON.stringify(this.state.bildirimler))
        } else if (fonksiyondanBeklenenIslem === "ayarBildirimi") {
            let bildirimlerKopya = JSON.parse(JSON.stringify(this.state.bildirimler))
            bildirimlerKopya[bildirim.key] = bildirim
            this.setState(
                {
                    bildirimler: bildirimlerKopya
                }
            )
            setTimeout(() => this.cihazAyarKaydiSorgulama(bildirimlerKopya[bildirim.key]), 5000)
        } else if (fonksiyondanBeklenenIslem === "kanalRenk") {
            let bildirimlerKopya = JSON.parse(JSON.stringify(this.state.bildirimler))
            bildirimlerKopya[bildirim.key] = bildirim
            this.setState(
                {
                    bildirimler: bildirimlerKopya
                }
            )
            setTimeout(() => {
                setTimeout(() => this.bildirimlerinPozisyonlanmasiniYonet(bildirim.key, -250), 3000)
                setTimeout(() => this.bildirimiListedenCikar(bildirim.key, "bildirimKaldir"), 3350)
            }, 5000)
        } else if (fonksiyondanBeklenenIslem === "bildirimGüncelle") {
            this.setState(
                {
                    bildirimler: bildirim
                }
            )
        }

    }

    cikisYap2 = () => {
        CookieManager.deleteCookie("login")
        CookieManager.deleteCookie("token")
        CookieManager.deleteCookie("tokenVerilisTarihi")
        CookieManager.deleteCookie("id")

        if (this.state.konum !== "login")
            this.setState(
                {
                    tokenBilgileri: {},
                    login: false,
                    konum: "login"
                }
            )
        // window.location.replace("/")
    }

    cikisYap = () => {
        CookieManager.deleteCookie("hesaplar")

        window.location.replace("/")
    }

    loginAyarlari = async (veriler) => {
        CookieManager.setCookie("login", true, 7)
        CookieManager.setCookie("token", veriler.token, 7)
        CookieManager.setCookie("tokenVerilisTarihi", veriler.tarih, 7)
        this.setState(
            {
                tokenBilgileri: { token: veriler.token, verilisTarihi: veriler.tarih },
                tokenKontrol: true,
                login: true,
                konum: "anasayfa"
            }
        )
    }

    async tokenKontrol() {
        if (this.state.konum === "login") {
            this.setState({
                tokenKontrol: false
            })
            return
        }

        let hesaplar = CookieManager.getCookie("hesaplar")
        if (!hesaplar) return this.setState({ tokenKontrol: false })
        hesaplar = JSON.parse(hesaplar)
        if (!hesaplar.anaHesap) return this.setState({ tokenKontrol: false })
        if (hesaplar.anaHesap.token === "" || !hesaplar.anaHesap.token) return this.setState({ tokenKontrol: false })
        try {
            let res = await axios.get("https://api.enelsa.com:5015/api/tokenKontrol", {
                headers: {
                    'authorization': 'Bearer ' + hesaplar.anaHesap.token
                }
            })
            this.setState({
                tokenKontrol: true
            })
        }
        catch (e) {
            let login = true
            if (hesaplar.anaHesap.kullaniciAdi === "" || !hesaplar.anaHesap.kullaniciAdi) login = false
            this.setState({
                tokenKontrol: false, login: login
            })
            CookieManager.deleteCookie("token")
        }
    }

    loginGuncelle = () => {
        this.state.login = true
    }

    componentDidUpdate() {
        // // console.log(this.state.login)
    }

    async componentDidMount() {
        // // console.log(123)
        window.addEventListener("resize", this.ekranBoyutuDegisti)
        await this.tokenKontrol()
    }

    temaAyarDegistir = () => {
        this.setState({
            temaAyar: !this.state.temaAyar
        })
    }

    pinle = () => {
        this.setState({
            pinle: !this.state.pin
        })
    }

    render() {
        return (
            <div className="app" style={{ gridTemplateColumns: this.state.ekranGenisligi > 640 ? "min-content 1fr" : "1fr", gridTemplateRows: "50px 1fr", position: "relative" }}>
                {
                    ((this.state.login && !this.state.tokenKontrol) || this.state.yeniKayit || this.state.hesapDegistir)
                        ?
                        <LoginPopup tokenKontrol={this.state.tokenKontrol} kullaniciBilgileriGuncelle={this.kullaniciBilgileriGuncelle} cihazBilgileriGuncelle={this.cihazBilgileriGuncelle} cihazBilgileriGuncelle2={this.cihazBilgileriGuncelle2} hesapDegistirGuncelle={this.hesapDegistirGuncelle} degisecekHesap={this.state.degisecekHesap} hesapDegistir={this.state.hesapDegistir} yeniKayit={this.state.yeniKayit} yeniKayitiGuncelle={this.yeniKayitiGuncelle} loginAyarlari={this.loginAyarlari} cikisYap={this.cikisYap} konumGuncelle={this.konumGuncelle} konum={this.state.konum} />
                        :
                        this.state.kullaniciBilgileri.kullaniciDurum === 0 &&
                        <ActivationPage tokenBilgileri={this.state.tokenBilgileri} kullaniciBilgileri={this.state.kullaniciBilgileri} cihazBilgileriGuncelle={this.cihazBilgileriGuncelle} cihazBilgileriGuncelle2={this.cihazBilgileriGuncelle2} loginAyarlari={this.loginAyarlari} cikisYap={this.cikisYap} konumGuncelle={this.konumGuncelle} konum={this.state.konum} />
                }
                {(this.state.login && this.state.konum !== "login") &&
                    <> {this.state.konum !== "infoPanel" &&
                        <NavBar
                            temaAyarDegistir={this.temaAyarDegistir}
                            temaAyar={this.state.temaAyar}
                            kullaniciBilgileri={this.state.kullaniciBilgileri}
                            cikisYap={this.cikisYap}
                            yeniKayitiGuncelle={this.yeniKayitiGuncelle}
                            hesapDegistirGuncelle={this.hesapDegistirGuncelle} />
                    }

                        {this.state.konum !== "infoPanel" &&
                            this.state.ekranGenisligi > 640 &&
                            <Menu kullaniciAdiSoyadi={this.state.kullaniciBilgileri.kullaniciAdiSoyadi} kullaniciTipi={this.state.kullaniciBilgileri.kullaniciTipi} menuGenisligi={this.state.menuGenisligi} menuGenisligiAyarla={this.menuGenisligiAyarla} konumGuncelle={this.konumGuncelle} konum={this.state.konum} tema={this.state.tema} temayiGuncelle={this.temayiGuncelle} />
                        }
                        <Bildirimler bildirimleriYonet={this.bildirimleriYonet} bildirimler={this.state.bildirimler} bildirimiListedenCikar={this.bildirimiListedenCikar} bildirimlerinPozisyonlanmasiniYonet={this.bildirimlerinPozisyonlanmasiniYonet} />
                    </>
                }
                {
                    this.state.login ?
                        <Routes>
                            <Route path="/anasayfa" element={<Anasayfa
                                menuGenisligi={this.state.menuGenisligi}
                                konum={this.state.konum}
                                konumGuncelle={this.konumGuncelle}
                                temelFiltreDegistir={this.temelFiltreDegistir}
                                ekranGenisligi={this.state.ekranGenisligi}
                                tokenBilgileri={this.state.tokenBilgileri}
                                deneme={this.state.login}
                                cihazBilgileri={this.state.cihazBilgileri}
                                cihazBilgileriGuncelle={this.cihazBilgileriGuncelle}
                                cihazBilgileri2={this.state.cihazBilgileri2}
                                cihazBilgileriGuncelle2={this.cihazBilgileriGuncelle2}
                                gosterilecekCihaziGuncelle={this.gosterilecekCihaziGuncelle}
                                kullaniciBilgileriGuncelle={this.kullaniciBilgileriGuncelle}
                                tokenKontrol={this.state.tokenKontrol}
                                kullaniciBilgileri={this.state.kullaniciBilgileri} />} />
                            <Route path="/cihazlar" element={<Cihazlar
                                menuGenisligi={this.state.menuGenisligi}
                                konum={this.state.konum}
                                konumGuncelle={this.konumGuncelle}
                                deneme={this.state.login}
                                tema={this.state.tema}
                                temelFiltre={this.state.temelFiltre}
                                tokenBilgileri={this.state.tokenBilgileri}
                                temelFiltreDegistir={this.temelFiltreDegistir}
                                gosterilecekCihaziGuncelle={this.gosterilecekCihaziGuncelle}
                                kullaniciBilgileri={this.state.kullaniciBilgileri}
                                kullaniciBilgileriGuncelle={this.kullaniciBilgileriGuncelle}
                                tokenKontrol={this.state.tokenKontrol} />} />
                            <Route path="/cihazlar/:id" element={<Cihaz
                                deneme={this.state.login}
                                temaAyarDegistir={this.temaAyarDegistir}
                                tokenBilgileri={this.state.tokenBilgileri}
                                ekranGenisligi={this.state.ekranGenisligi}
                                menuGenisligi={this.state.menuGenisligi}
                                gosterilecekCihaz={this.state.gosterilecekCihaz}
                                kullaniciBilgileri={this.state.kullaniciBilgileri}
                                kullaniciBilgileriGuncelle={this.kullaniciBilgileriGuncelle}
                                bildirimleriYonet={this.bildirimleriYonet}
                                temaAyar={this.state.temaAyar} />} />
                            <Route path="/cihazlar/nova/:id" element={<NovaBilgiKarti
                                // temaAyarDegistir={this.temaAyarDegistir}
                                tokenBilgileri={this.state.tokenBilgileri}
                                ekranGenisligi={this.state.ekranGenisligi}
                                menuGenisligi={this.state.menuGenisligi}
                                // gosterilecekCihaz={this.state.gosterilecekCihaz}
                                bildirimleriYonet={this.bildirimleriYonet}
                            // temaAyar={this.state.temaAyar} 
                            />} />
                            <Route path="/cihazlar/salt/:id" element={<SaltBilgiKarti
                                // temaAyarDegistir={this.temaAyarDegistir}
                                tokenBilgileri={this.state.tokenBilgileri}
                                ekranGenisligi={this.state.ekranGenisligi}
                                menuGenisligi={this.state.menuGenisligi}
                                // gosterilecekCihaz={this.state.gosterilecekCihaz}
                                bildirimleriYonet={this.bildirimleriYonet}
                            // temaAyar={this.state.temaAyar} 
                            />} />
                            <Route path="/grafik/:id" element={<CizgiGrafik
                                temaAyarDegistir={this.temaAyarDegistir}
                                tokenBilgileri={this.state.tokenBilgileri}
                                ekranGenisligi={this.state.ekranGenisligi}
                                menuGenisligi={this.state.menuGenisligi}
                                gosterilecekCihaz={this.state.gosterilecekCihaz}
                                bildirimleriYonet={this.bildirimleriYonet}
                                temaAyar={this.state.temaAyar} />} />
                            <Route path="/tablo/:id" element={<Tablo
                                ekranGenisligi={this.state.ekranGenisligi}
                                menuGenisligi={this.state.menuGenisligi} />} />
                            <Route path="/api" element={<Api
                                konumGuncelle={this.konumGuncelle} />} />
                            <Route path="/grafikler" element={<Grafikler
                                konumGuncelle={this.konumGuncelle} />} />
                            <Route path="/full-screen" element={<FullScreenPage
                                konumGuncelle={this.konumGuncelle} />} />
                            <Route path="/login" element={<LoginScreen
                                loginAyarlari={this.loginAyarlari}
                                cikisYap={this.cikisYap2}
                                konumGuncelle={this.konumGuncelle}
                                konum={this.state.konum} />} />
                            <Route path="/profil" element={<Profil
                                kullaniciBilgileri={this.state.kullaniciBilgileri}
                                kullaniciBilgileriGuncelle={this.kullaniciBilgileriGuncelle}
                                tokenBilgileri={this.state.tokenBilgileri}
                                ekranGenisligi={this.state.ekranGenisligi}
                                menuGenisligi={this.state.menuGenisligi}
                                bildirimleriYonet={this.bildirimleriYonet}
                                cikisYap={this.cikisYap} />} />
                            <Route path="/cihazekle" element={<CihazEkle
                                konumGuncelle={this.konumGuncelle}
                                kullaniciBilgileri={this.state.kullaniciBilgileri}
                                kullaniciBilgileriGuncelle={this.kullaniciBilgileriGuncelle}
                                tokenBilgileri={this.state.tokenBilgileri}
                                ekranGenisligi={this.state.ekranGenisligi}
                                menuGenisligi={this.state.menuGenisligi}
                                bildirimleriYonet={this.bildirimleriYonet} />} />
                            <Route path="/cihazonay" element={<CihazOnay
                                konumGuncelle={this.konumGuncelle}
                                kullaniciBilgileri={this.state.kullaniciBilgileri}
                                kullaniciBilgileriGuncelle={this.kullaniciBilgileriGuncelle}
                                tokenBilgileri={this.state.tokenBilgileri}
                                ekranGenisligi={this.state.ekranGenisligi}
                                menuGenisligi={this.state.menuGenisligi}
                                bildirimleriYonet={this.bildirimleriYonet} />} />
                            <Route path="/lisans" element={<Lisans
                                konumGuncelle={this.konumGuncelle}
                                kullaniciBilgileri={this.state.kullaniciBilgileri}
                                kullaniciBilgileriGuncelle={this.kullaniciBilgileriGuncelle}
                                tokenBilgileri={this.state.tokenBilgileri}
                                ekranGenisligi={this.state.ekranGenisligi}
                                menuGenisligi={this.state.menuGenisligi}
                                bildirimleriYonet={this.bildirimleriYonet} />} />
                            <Route path="/kullanici" element={<KullaniciEkle
                                konumGuncelle={this.konumGuncelle}
                                kullaniciBilgileri={this.state.kullaniciBilgileri}
                                kullaniciBilgileriGuncelle={this.kullaniciBilgileriGuncelle}
                                tokenBilgileri={this.state.tokenBilgileri}
                                ekranGenisligi={this.state.ekranGenisligi}
                                menuGenisligi={this.state.menuGenisligi}
                                bildirimleriYonet={this.bildirimleriYonet} />} />
                            <Route path="/gruplar" element={<Gruplar
                                konumGuncelle={this.konumGuncelle}
                                kullaniciBilgileri={this.state.kullaniciBilgileri}
                                kullaniciBilgileriGuncelle={this.kullaniciBilgileriGuncelle}
                                tokenBilgileri={this.state.tokenBilgileri}
                                ekranGenisligi={this.state.ekranGenisligi}
                                menuGenisligi={this.state.menuGenisligi}
                                bildirimleriYonet={this.bildirimleriYonet} />} />
                            <Route path="*" element={<Navigate to={this.state.login ? "/anasayfa" : "/login"} replace />} />
                        </Routes> :
                        (
                            <LoginScreen
                                loginAyarlari={this.loginAyarlari}
                                cikisYap={this.cikisYap2}
                                konumGuncelle={this.konumGuncelle}
                                konum={this.state.konum}
                                loginGuncelle={this.loginGuncelle} />
                        )
                }

                {(this.state.login && this.state.konum !== "login") &&
                    <>
                        <div></div>
                        {!(this.state.ekranGenisligi > 640) &&
                            <AltMenu konumGuncelle={this.konumGuncelle} konum={this.state.konum} tema={this.state.tema} temayiGuncelle={this.temayiGuncelle} />
                        }
                    </>
                }
            </div>
        )
    }
}

export default App