import "./gruplar.css"
import React,{ useEffect, useState } from "react"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGear, faRotateLeft } from "@fortawesome/free-solid-svg-icons"
import GruplarKart from "./components/gruplar-kart"

const Gruplar = (props) => {
	const [baslangic, setBaslangic] = useState(false)
    let [gruplar, setGruplar] = useState([])

	useEffect(() => {
		if (baslangic) return
		setBaslangic(true)
		try {
			props.kullaniciBilgileriGuncelle()
			props.konumGuncelle("gruplar")
            erisebilecegiGruplar()
		} catch {}
	}, [props])
	
    let erisebilecegiGruplar = async () => {
		let a = await axios.get("https://api.enelsa.com:5001/api/gruplar", {
			headers: {
				authorization: "Bearer " + props.tokenBilgileri.token,
			},
		})
		if ((a.data) == undefined) {
			setGruplar({})
		}else {
			setGruplar(a.data)
		}
	}

    const ekrandaGosterilecekleriOlustur = (datalar) => {
        let ekrandaGosterilecekler = []
        let veriler = Object.keys(datalar)
		veriler.forEach(e => {
			let a = datalar[e]
			ekrandaGosterilecekler.push(
				<div>
					<GruplarKart gruplar={a} tokenBilgileri={props.tokenBilgileri} bildirimleriYonet={props.bildirimleriYonet}/>
				</div>
			)
		})
        return ekrandaGosterilecekler
    }

    return (
        <>
			<div id="gruplar-grid" style={{ justifyContent: "start", backgroundColor: "#42494f", display: "flex", flexWrap: "wrap", alignContent: "flex-start" }}>
				{
					ekrandaGosterilecekleriOlustur(gruplar).map((e) => {
						return (
							e
						)
					})
				}
			</div>
        </>
    )
}
export default Gruplar