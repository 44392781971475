import React,{ useState } from "react"
import axios from "axios"
import "./gruplar-arka-taraf.css"
import { yetkiler } from "../../kullanici-islemleri/utils/kullanici-yetkileri"
import ListOlustur from "./list-olustur"

const GruplarArkaTaraf = (props)=>{
    const [parentYetkilerValues, setParentYetkilerValues] = useState([]);
    const handleYetkilerValuesChange = (updatedValues) => {
        setParentYetkilerValues(updatedValues);
      };

      // console.log(props, "list props")
    async function grupKaydet(datalar, bildirimleriYonet) {
		let gonderilecekData = {
			yetkiler: datalar.parentYetkilerValues,
            grupAdi: datalar.grupAdi
		}
        // console.log(datalar)
        try {
            const res = await axios.put('https://api.enelsa.com:5001/api/grupGuncelle', JSON.stringify(gonderilecekData), {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + props.tokenBilgileri.token
                }
            })
            let yeniBildirim = { mesaj: "Grup Ayarları", bildirim: 1 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
            props.onParentDondurChange(true)
        } catch(e) {
            let yeniBildirim = { mesaj: "Grup Ayarları", bildirim: 0 }
			props.bildirimleriYonet("kanalRenk", yeniBildirim)
        }
	}

    const ekrandaGosterilecekleriOlustur = (datalar) => {
		if(datalar === undefined || datalar === []) {
			return
		} else {
			let ekrandaGosterilecekler = []
            let yetkililer = (JSON.parse(datalar["grup_yetkilileri"])).yetkililer
            let a = (JSON.parse(datalar["grup_yetkileri"])).yetkiler
            const yetkilerx = a.map((value) => value === true);
            let kullanicilar= (JSON.parse(datalar["gruptaki_kullanicilar"])).grupKullanicilari
            let grupAdi = datalar["grup_adi"]
            let keys = Object.keys(yetkiler)
            let yetkiList = []
            keys.forEach((i) => {
                yetkiList.push(yetkiler[keys[i]]["name"])
            })
            let gonderilecekData = {grupAdi,parentYetkilerValues}
            ekrandaGosterilecekler.push(
                <>
                <div className={"grup editGrup"}>
                    <span><div> Grup Adı</div><em> {": " +grupAdi}</em></span>
                    <span><div> Grup Yetkilileri</div><em> {": " +yetkililer}</em> </span>
                    <span><div> Grup Kullanıcıları </div><em> {": " +kullanicilar}</em> </span>
                    <span><div style={{marginBottom: "5px"}}> Grup Yetkileri </div><em> {": "} <ListOlustur yetkilerValues={parentYetkilerValues} gruplar={props.gruplar} yetkiler={yetkilerx} onYetkilerValuesChange={handleYetkilerValuesChange}/> </em> </span>
                    <span className="buttonDiv"><button onClick={() =>grupKaydet(gonderilecekData, props.bildirimleriYonet)}>KAYDET</button></span>
                </div>
                </>
            )
			return ekrandaGosterilecekler
		}
	}
    return(
        <>
            <div id="Gruplar-arka-taraf">
                {ekrandaGosterilecekleriOlustur(props.gruplar,props.bildirimleriYonet).map((e) => { return e })}
            </div>
        </>
    )
}

export default GruplarArkaTaraf