import { faBell, faCheck, faEnvelope, faExpand, faFileLines, faGear, faPalette, faPen, faSlash, faSms, faTable, faTrashCan, faUserEdit, faUserGear, faWifi, faX } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { React, useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import "./cihaz.css"
import CihazBilgiKarti from "./components/cihaz-bilgi-karti"
import cihazSayfaDataIslemleri from "./data-islemleri/cihaz-sayfa-data"
import axios from "axios"
import { alarms, alarms2 } from "./components/arka-taraf-birimler/alarms"
import { alarmsBasic, alarmsControl } from "./components/nova/nova-alarms"
import { ConfigLock, EventBus, MailPopup, ApprovalPopup, Countdown } from "./components/nova/common-components"
import { ConfigClient } from "./components/nova/common-requests"
import Swal from "sweetalert2"
import CookieManager from "../../../cookieManager"
import queryString from "query-string"
import NovaBilgiKarti from "./components/nova/nova-bilgi-karti"

const REGEXP_SPECIAL_CHAR =
    /[\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g

let menuGenisligi = 0
// 1C5E37
let cihazKanalIsimArkaplanRengi = { "#0a0068": "#1301bb", "#E8953C": "#FFB564", "#00A895": "#00CCB4", "#009CEB": "#2fb4fc", "#8397a6": "#b5c1cb" }
let renkler = ["#0a0068", "#009CEB", "#8397a6", "#E8953C", "#00A895"]

let yetkiList = ["Anlık verileri görüntüleyebilir.", "Rapor oluşturabilir.", "Grafikleri görüntüleyebilir.", "SMS ve Mail alabilir.", "Ayar yapabilir."]

export let grafikYetkisi = false; // dışarıya aktarılma sebebi cihaz-on-taraf.js de grafik kısmı bulunduğundan dolayı. 

const Cihaz = (props) => {
    // console.log(props)
    const [underline, setUnderline] = useState(false);

    let [data, setData] = useState({})
    let [componentOlusturuldu, setComponentOlusturuldu] = useState(false)
    let [timeOutIslemde, setTimeOutIslemde] = useState(false)
    let [kanalRenk, setKanalRenk] = useState([])
    let [kanalIsim, setKanalIsim] = useState([])
    let [cihazIlkIsim, setCihazIlkIsim] = useState("")
    let [cihazAdi, setCihazAdi] = useState("")
    let [cihazAdiT, setCihazAdiT] = useState("")
    let [cihazKullanici, setCihazKullanici] = useState("")
    let [alarmlar, setAlarmlar] = useState([])
    let [alarmSayisi, setAlarmSayisi] = useState()
    let [raporFreq, setRaporFreq] = useState()
    let [raporFreqT, setRaporFreqT] = useState()
    let [zamanDamgasi, setZamanDamgasi] = useState()
    let [dataYok, setDataYok] = useState(false)
    let [kullaniciKodu, setKullaniciKodu] = useState("")
    let [yetkiliList, setYetkiliList] = useState({})
    let [acikKanallar, setAcikKanallar] = useState([])
    //Sonradan eklenen
    let [cihazSeriNo, setCihazSeriNo] = useState("")
    let [guncellenenTarih, setGuncellenenTarih] = useState()
    let [cihazTip, setCihazTip] = useState("")


    let [kullaniciAdiT, setKullaniciAdiT] = useState("")

    let raporYetkisi = false;
    let smsmailYetkisi = false;
    let ayarYetkisi = false;

    const keysArray = yetkiliList && Object.keys(yetkiliList);

    if (cihazKullanici.toLowerCase() != CookieManager.getCookie("kullaniciAdi").toLowerCase()) {
        if (Object.keys(yetkiliList).length > 0) {
            const yetkiler = yetkiliList[CookieManager.getCookie("kullaniciAdi")];
            if (yetkiler) {
                const kullaniciYetkileri = JSON.parse(yetkiler);
                if (kullaniciYetkileri.length > 4) {
                    raporYetkisi = kullaniciYetkileri[1];
                    grafikYetkisi = kullaniciYetkileri[2];
                    smsmailYetkisi = kullaniciYetkileri[3];
                    ayarYetkisi = kullaniciYetkileri[4];
                }
            }

        }
    }
    else {
        raporYetkisi = true;
        smsmailYetkisi = true;
        ayarYetkisi = true;
        grafikYetkisi = true;
    }

    if (CookieManager.getCookie("kullaniciAdi") == "arge_test") {
        raporYetkisi = true;
        smsmailYetkisi = true;
        ayarYetkisi = true;
        grafikYetkisi = true;
    }



    let [popupDurum2, setPopupDurum2] = useState(false)
    let [popupKonum2, setPopupKonum2] = useState(-250)

    const popupGoster2 = (data) => {
        setPopupDurum2(true)
        setTimeout(() => setPopupKonum2(50), 50)
        setKullaniciAdiT(data)
    }

    const kullaniciYetkileriFunc = (data) => {
        const kullaniciYetkileri = [];
    
        if (Object.keys(yetkiliList).length !== 0 && data !== "") {
            let yetkiliArray;
    
            try {
                // JSON formatında mı kontrolü
                yetkiliArray = JSON.parse(yetkiliList[data]);
            } catch (error) {
                console.error("Geçersiz JSON formatı:", error);
                return null;
            }
    
            yetkiliArray.forEach((value, index) => {
                if (value) {
                    kullaniciYetkileri.push(yetkiList[index]);
                }
            });
    
            return (
                <>
                    {kullaniciYetkileri.map((alarm, i) => (
                        <li key={i}>{alarm}</li>
                    ))}
                </>
            );
        } else {
            return null;
        }
    };


    const [checkboxValuesYetki, setCheckboxValuesYetki] = useState([true, true, true, true, true]);

    const [checkboxValuesSMS, setCheckboxValuesSMS] = useState([]);
    const [checkboxValuesMail, setCheckboxValuesMail] = useState([]);

    const [checkboxValuesSMS2, setCheckboxValuesSMS2] = useState([]);
    const [checkboxValuesMail2, setCheckboxValuesMail2] = useState([]);

    const [isTrue, setIsTrue] = useState(true)
    const [isTrue2, setIsTrue2] = useState(true)

    const [sifirla, setSifirla] = useState(false)

    const handleCheckboxChangeYetki = (index) => {
        if (index === 0) return;
        const newCheckboxValuesYetki = [...checkboxValuesYetki];
        newCheckboxValuesYetki[index] = !newCheckboxValuesYetki[index];
        setCheckboxValuesYetki(newCheckboxValuesYetki);
    };

    const handleCheckboxChangeSMS = (index) => {
        const newCheckboxValuesSMS = [...checkboxValuesSMS];
        newCheckboxValuesSMS[index] = !newCheckboxValuesSMS[index];
        setCheckboxValuesSMS(newCheckboxValuesSMS);
    };

    const handleCheckboxChangeMail = (index) => {
        const newCheckboxValuesMail = [...checkboxValuesMail];
        newCheckboxValuesMail[index] = !newCheckboxValuesMail[index];
        setCheckboxValuesMail(newCheckboxValuesMail);
    };

    const handleCheckboxChangeSMS2 = (index) => {
        const newCheckboxValuesSMS2 = [...checkboxValuesSMS2];
        newCheckboxValuesSMS2[index] = !newCheckboxValuesSMS2[index];
        setCheckboxValuesSMS2(newCheckboxValuesSMS2);
    };

    const handleCheckboxChangeMail2 = (index) => {
        const newCheckboxValuesMail2 = [...checkboxValuesMail2];
        newCheckboxValuesMail2[index] = !newCheckboxValuesMail2[index];
        setCheckboxValuesMail2(newCheckboxValuesMail2);
    };

    let [popupDurum, setPopupDurum] = useState(false)
    let [popupKonum, setPopupKonum] = useState(-250)

    let [configLock, setConfigLock] = useState(true)
    let [showConfigLockApprovalPopup, setShowConfigLockApprovalPopup] = useState(false)
    let [showCihazSilApprovalPopup, setShowCihazSilApprovalPopup] = useState(false)
    let [showYetkiliSilApprovalPopup, setShowYetkiliSilApprovalPopup] = useState(false)
    const [eventBus, _] = useState(new EventBus())

    //INFO PANEL MODU İÇİN
    const [selectedChannels, setSelectedChannels] = useState([]);
    const [customText, setCustomText] = useState('');
    const [intervalTime, setIntervalTime] = useState(3000);
    const [texts, setTexts] = useState([]);

    // Kullanıcı seçimlerini handle eden fonksiyonlar
    const handleChannelChange = (event) => {
        const value = event.target.value;
        setSelectedChannels(
            selectedChannels.includes(value)
                ? selectedChannels.filter((channel) => channel !== value)
                : [...selectedChannels, value]
        );
    };

    const handleIntervalChange = (event) => {
        setIntervalTime(Number(event.target.value));
    };

    const handleCustomTextChange = (event) => {
        setCustomText(event.target.value);
    };

    const handleAddText = () => {
        if (customText.trim() !== "") {
            setTexts([...texts, customText]);
            setCustomText(""); // Girdiyi temizle
        }
        // console.log(customText)
    };

    // URL parametreleri için formatlama
    const queryParams = queryString.stringify({
        channels: [...selectedChannels].filter(Boolean), // Kanal verileri
        texts: [...texts].filter(Boolean), // Metinleri array olarak gönderiyoruz
        intervalTime,
        seriNo: cihazSeriNo
    });

    // Boş olup olmadığını kontrol et
    const hasSelectedChannels = selectedChannels.length > 0;
    const hasText = customText.trim() !== "";

    // Seçili kanal ve metin olup olmadığını kontrol et
    const shouldShowLink = hasSelectedChannels || hasText;

    useEffect(() => {
        setInitialConfigLockState()
    }, [])

    async function setInitialConfigLockState() {
        lockConfigClient()
        ConfigClient.getExpiration(CookieManager.getCookie("token"))
            .then(response => {
                // // console.log(response.data)
                const { exp } = response.data
                if (exp !== null) {
                    setConfigLock(false)
                    eventBus.publish('set countdown', exp)
                }

            })
    }

    async function lockConfig() {
        lockConfigClient()
        eventBus.publish('clear countdown')
        const res = await ConfigClient.lock(CookieManager.getCookie("token"))
    }

    function lockConfigClient() {
        setConfigLock(true)
        setShowConfigLockApprovalPopup(false)
    }

    function cihazSilApprove() {
        let location = window.location.href
        location = location.split("#")[0]
        location = location + "#modal-closed"
        window.location.href = location
        setShowCihazSilApprovalPopup(true)
    }

    function yetkiliSilApprove() {
        let location = window.location.href
        location = location.split("#")[0]
        location = location + "#modal-closed"
        window.location.href = location
        setShowYetkiliSilApprovalPopup(true)
    }

    const popupGizle = () => {
        setPopupKonum(-250)
        setUnderline(false)
        setTimeout(() => setPopupDurum(false), 320)
    }

    let ekranGenisligi = props.ekranGenisligi - props.menuGenisligi

    menuGenisligi = props.menuGenisligi

    let navigate = useNavigate()
    // if(!props.gosterilecekCihaz)
    //     navigate("/cihazlar")

    let kanalRenkDegistirme = (kanal, renk) => {
        // // console.log(kanal,renk)
        let kanalRenkleriKopya = Array.from(kanalRenk)
        kanalRenkleriKopya[kanal - 1] = renk
        setKanalRenk(kanalRenkleriKopya)
    }

    // console.log(props, "props from cihaz")
    let listItems = null
    let listItems2 = null
    if (cihazTip.toLowerCase() === "omnicon") {
        let alarmlarFirst = alarmlar?.slice(0, 16)
        let alarmlarSecond = alarmlar?.slice(16, 32)
        if (alarmlar?.length > 0) {
            listItems = alarmlarFirst.map((e, i) => {
                return (
                    <>
                        <div>
                            {e === 1 && alarms[i]}
                        </div>
                    </>
                )
            });
            listItems2 = alarmlarSecond.map((e, i) => {
                return (
                    <>
                        <div>
                            {e === 1 && alarms2[i]}
                        </div>
                    </>
                )
            });
        }
    } else if (cihazTip.toLowerCase() === "nova") {
        let alarmlarFirst = alarmlar?.slice(0, 8)
        let alarmlarSecond = alarmlar?.slice(8,)
        if (alarmlar?.length > 0) {
            listItems = alarmlarFirst.map((e, i) => {
                return (
                    <>
                        <div>
                            {e === 1 && alarmsBasic[i]}
                        </div>
                    </>
                )
            });
            listItems2 = alarmlarSecond.map((e, i) => {
                return (
                    <>
                        <div>
                            {e === 1 && alarmsControl[i]}
                        </div>
                    </>
                )
            });
        }
    }


    // cihazVerileriniGuncelle(cihazSeriNo)
    const ekrandaGosterilecekleriOlustur = (datalar, bildirimleriYonet) => {
        let ekrandaGosterilecekler = [];

        // console.log(datalar, "-----------------------------")
        // Eğer datalar boş ya da undefined ise boş bir array olarak başla
        if (!datalar || datalar.length === 0) {
            return ekrandaGosterilecekler;
        }

        let veriler = Object.keys(datalar);

        // Eğer datalar[0] undefined veya boş obje ise loading veya bekleme durumu göster
        if (!datalar[0] || !datalar[0].kanalNo) {
            // Eğer kanalNo yoksa NovaBilgiKarti'na yönlendiriyoruz
            ekrandaGosterilecekler.push(
                <div>
                    <NovaBilgiKarti
                        ekranGenisligi={ekranGenisligi}
                        configLock={configLock}
                        veriler={datalar[0] || {}}
                        bildirimleriYonet={bildirimleriYonet}
                        cihaz={cihazSeriNo}
                        tokenBilgileri={props.tokenBilgileri}
                    />
                </div>
            );
            return ekrandaGosterilecekler;
        }

        // Eğer kanalNo varsa CihazBilgiKarti'na yönlendirme yapılacak
        if (veriler.length >= 1) {
            veriler.forEach(e => {
                const kanalNo = datalar[e].kanalNo;
                ekrandaGosterilecekler.push(
                    <div>
                        {props.temaAyar && (
                            <>
                                <div style={{ marginLeft: "auto", display: "flex", justifyContent: "center", paddingBottom: "10px" }}>
                                    {Array.from(Array(5).keys()).map(i => (
                                        <div
                                            onClick={() => { kanalRenkDegistirme(kanalNo, renkler[i]) }}
                                            style={{
                                                width: "35px", height: "35px", borderRadius: "100%", backgroundColor: renkler[i],
                                                marginRight: "5px", display: "flex", cursor: "pointer"
                                            }}
                                        >
                                            {kanalRenk[kanalNo - 1] === renkler[i] &&
                                                <FontAwesomeIcon icon={faCheck} style={{ margin: "auto", color: "#F7FBFF" }} />
                                            }
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        <CihazBilgiKarti
                            configLock={configLock}
                            veriler={datalar[e]}
                            kanal={kanalNo}
                            bildirimleriYonet={bildirimleriYonet}
                            key={e}
                            renk2={cihazKanalIsimArkaplanRengi[kanalRenk[kanalNo - 1]]}
                            renk={kanalRenk[kanalNo - 1]}
                            kanalIsim={kanalIsim[kanalNo - 1]}
                            temaAyar={props.temaAyar}
                            cihaz={cihazSeriNo}
                            tokenBilgileri={props.tokenBilgileri}
                        />
                    </div>
                );
            });
        }

        return ekrandaGosterilecekler;
    };


    const gridTemplateColumnsHesapla = () => {
        let dondurulecekDeger = ""

        if (ekranGenisligi < 770)
            dondurulecekDeger = "minmax(335px, 400px)"
        else if (ekranGenisligi < 1146)
            dondurulecekDeger = "repeat(2,minmax(335px, 400px))"
        else
            dondurulecekDeger = "repeat(3,minmax(335px, 400px))"

        return dondurulecekDeger
    }

    //TODO: Logindurumu gözden geçirilecek. F5 atılmadan login durumunda kalıyor.

    useEffect(() => {
        let acikKanallar = []
        const datalariOlustur = async () => {
            try {
                const e = await cihazSayfaDataIslemleri(CookieManager.getCookie("token"), CookieManager.getCookie("id"));

                setData(e?.veriler);
                setZamanDamgasi(e?.zamanDamgasi);
                setGuncellenenTarih(e?.tarih);
                setAlarmlar(e?.alarmlar)
                setAlarmSayisi(e?.alarmSayisi)
                setYetkiliList(e?.yetkiliList)

                if (data) {
                    for (let i = 0; i < data.length; i++) {
                        acikKanallar.push(data[i].kanalNo)
                    }
                }
                setAcikKanallar(acikKanallar)
                setTimeOutIslemde(false);
            } catch (error) {
                console.error("Veri alma hatası:", error);
            }
        };

        if (!timeOutIslemde) {
            setTimeout(datalariOlustur, 5000);
            setTimeOutIslemde(true);
        }

        if (!componentOlusturuldu) {
            setComponentOlusturuldu(true);
            datalariOlustur();
        }
    }, [timeOutIslemde, componentOlusturuldu, navigate, sifirla]);

    useEffect(() => {
        const datalariOlustur = async () => {
            try {
                const e = await cihazSayfaDataIslemleri(CookieManager.getCookie("token"), CookieManager.getCookie("id"));

                const isim = e?.isim || e?.ilkIsim;
                const kanalIsimleri = e?.kanalIsimleri || ["Kanal 1", "Kanal 2", "Kanal 3", "Kanal 4", "Kanal 5", "Kanal 6"];

                // Bu verileri bir kez güncellemek yeterli. 
                setCihazKullanici(e?.cihazKullanici);
                setDataYok(e?.dataYokMaili);
                setCihazIlkIsim(e?.ilkIsim);
                setCihazAdi(isim);
                setCihazAdiT(isim);
                setKanalRenk(e?.kanalRenkleri);
                setCihazSeriNo(e?.cihaz);
                setKanalIsim(kanalIsimleri);
                setRaporFreq(e?.periyot);
                setRaporFreqT(e?.periyot);
                setCihazTip(e?.cihazTuru)

                setCheckboxValuesSMS((e?.alarmBildirimSMS || []).slice(0, 16));
                setCheckboxValuesSMS2((e?.alarmBildirimSMS || []).slice(16));
                setCheckboxValuesMail((e?.alarmBildirimMail || []).slice(0, 16));
                setCheckboxValuesMail2((e?.alarmBildirimMail || []).slice(16));
            } catch (error) {
                console.error("Veri alma hatası:", error);
            }
        };

        // Bu useEffect sadece bileşen yüklendiğinde çalışacak
        datalariOlustur();
    }, []);

    let kanalRenkleriKaydet = async () => {
        // // console.log(kanalRenk)
        let kaydedilecekKanalRenkleri = {
            kanalRenkleri: kanalRenk,
            id: CookieManager.getCookie("id"),
            cihaz: cihazSeriNo
        }
        //console.log(kaydedilecekKanalRenkleri)
        try {
            let res = await axios.put("https://api.enelsa.com:5015/api/kanalRenkleriniGuncelle", JSON.stringify(kaydedilecekKanalRenkleri), {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + CookieManager.getCookie("token")
                }
            })
            props.temaAyarDegistir()
            let yeniBildirim = { mesaj: "Renk Ayarları", bildirim: 1, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
        } catch {
            let yeniBildirim = { mesaj: "Renk Ayarları", bildirim: 0, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
        }
    }

    let isimKaydet = async (isim) => {
        let regexIsim = isim?.cihazAdi.replace(REGEXP_SPECIAL_CHAR, '')
        let kaydedilecekİsim = {
            isim: regexIsim,
            id: CookieManager.getCookie("id"),
            cihaz: cihazSeriNo
        }
        try {
            let res = await axios.put("https://api.enelsa.com:5015/api/cihazIsmiGuncelle", JSON.stringify(kaydedilecekİsim), {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + CookieManager.getCookie("token")
                }
            })
            // props.temaAyarDegistir()
            let yeniBildirim = { mesaj: "Cihaz İsmi", bildirim: 1, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
            setCihazAdiT(regexIsim)
            let location = window.location.href
            location = location.split("#")[0]
            location = location + "#modal-closed"
            window.location.href = location
        } catch {
            let yeniBildirim = { mesaj: "Cihaz İsmi", bildirim: 0, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
        }
    }

    let raporFreqKaydet = async (freq) => {
        let gonderilecekData = {
            id: CookieManager.getCookie("id"),
            freq: freq ? freq : 3,
            cihaz: cihazSeriNo,
            isim: data.isim,
            cihazKullanici: cihazKullanici
        }
        // console.log(data)
        try {
            let res = await axios.put("https://api.enelsa.com:5015/api/raporFreqKaydet", JSON.stringify(gonderilecekData), {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + CookieManager.getCookie("token")
                }
            })
            // props.temaAyarDegistir()
            setRaporFreqT(gonderilecekData.freq)
            let yeniBildirim = { mesaj: "Rapor Alma İsteği", bildirim: 1, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
            let location = window.location.href
            location = location.split("#")[0]
            location = location + "#modal-closed"
            window.location.href = location
        } catch {
            let yeniBildirim = { mesaj: "Rapor Alma İsteği", bildirim: 0, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
        }
    }

    async function mailPass() {
        eventBus.publish('ask mail verification', {
            token: CookieManager.getCookie("token"),
            success({ exp }) {
                eventBus.publish('set countdown', exp)
                setConfigLock(false)
                // // console.log('code verification was success should open the gui')
            },
            reject() {
                // // console.log('code verification was rejected')
            }
        })
        lockConfigClient()
    }

    async function kanallariDuzenle() {
        let location = window.location.href
        location = location.split("#")[0]
        location = location + "#modal-closed"
        window.location.href = location
        props.temaAyarDegistir()
    }

    async function cihazSil() {
        let gonderilecekData = {
            id: CookieManager.getCookie("id"),
            seriNo: cihazSeriNo
        }
        // console.log("cihazSil")
        // console.log(CookieManager.getCookie("token"))
        let token = CookieManager.getCookie("token")
        try {
            let res = await axios.post("https://api.enelsa.com:5015/api/cihazSil", gonderilecekData, {
                headers: {
                    'authorization': 'Bearer ' + token
                }
            })
            // console.log(CookieManager.getCookie("token"))
            // props.temaAyarDegistir()
            let yeniBildirim = { mesaj: "Cihaz Silme İşlemi", bildirim: 1, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
            let location = window.location.href
            let locationx = location.split("/")
            let locationy = locationx[0] + "/" + locationx[1]
            window.location.href = locationy
            popupGizle()
        } catch {
            // console.log(CookieManager.getCookie("token"))

            let yeniBildirim = { mesaj: "Cihaz Silme İşlemi", bildirim: 0, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
        }
    }

    async function yetkiliSil(kullanici) {
        let gonderilecekData = {
            cihaz: cihazSeriNo,
            yetkili: kullanici
        }
        let token = CookieManager.getCookie("token")
        try {
            let res = await axios.put("https://api.enelsa.com:5015/api/yetkiliSil", JSON.stringify(gonderilecekData), {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                }
            })
            let yeniBildirim = { mesaj: "Yetkili Silme İşlemi", bildirim: 1, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
            let location = window.location.href
            let locationx = location.split("/")
            let locationy = locationx[0] + "/" + locationx[1]
            window.location.href = locationy
            popupGizle()
        } catch {
            let yeniBildirim = { mesaj: "Yetkili Silme İşlemi", bildirim: 0, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
        }
    }

    async function alarmBildirimleriSifirla() {
        cihazSayfaDataIslemleri(CookieManager.getCookie("token"), CookieManager.getCookie("id")).then((e) => {
            e.alarmBildirimSMS ? setCheckboxValuesSMS((e.alarmBildirimSMS).slice(0, 16)) : setCheckboxValuesSMS(new Array(16).fill(false))
            e.alarmBildirimSMS ? setCheckboxValuesSMS2((e.alarmBildirimSMS).slice(16,)) : setCheckboxValuesSMS2(new Array(16).fill(false))
            e.alarmBildirimMail ? setCheckboxValuesMail((e.alarmBildirimMail).slice(0, 16)) : setCheckboxValuesMail(new Array(16).fill(false))
            e.alarmBildirimMail ? setCheckboxValuesMail2((e.alarmBildirimMail).slice(16,)) : setCheckboxValuesMail2(new Array(16).fill(false))
        })
    }

    async function alarmBildirimleriSMSAll() {
        if (isTrue) {
            setCheckboxValuesSMS(new Array(16).fill(true))
            setCheckboxValuesSMS2(new Array(16).fill(true))
        } else {
            setCheckboxValuesSMS(new Array(16).fill(false))
            setCheckboxValuesSMS2(new Array(16).fill(false))
        }
        setIsTrue(!isTrue)
    }

    async function alarmBildirimleriMailAll() {
        if (isTrue2) {
            setCheckboxValuesMail(new Array(16).fill(true))
            setCheckboxValuesMail2(new Array(16).fill(true))
        } else {
            setCheckboxValuesMail(new Array(16).fill(false))
            setCheckboxValuesMail2(new Array(16).fill(false))
        }
        setIsTrue2(!isTrue2)
    }

    async function alarmBildirimleriYetki(i) {
        if (isTrue) {
            checkboxValuesYetki[i] = true
        } else {
            checkboxValuesYetki[i] = false
        }
        setIsTrue(!isTrue)
    }

    async function alarmBildirimleriSatir(i) {
        if (isTrue) {
            checkboxValuesSMS[i] = true
            checkboxValuesMail[i] = true
        } else {
            checkboxValuesSMS[i] = false
            checkboxValuesMail[i] = false
        }
        setIsTrue(!isTrue)
    }

    async function alarmBildirimleriSatir2(i) {
        if (isTrue2) {
            checkboxValuesSMS2[i] = true
            checkboxValuesMail2[i] = true
        } else {
            checkboxValuesSMS2[i] = false
            checkboxValuesMail2[i] = false
        }
        setIsTrue2(!isTrue2)
    }

    async function alarmBildirimleriKaydet() {
        const checkboxSMSArray = [...checkboxValuesSMS, ...checkboxValuesSMS2]
        const checkboxMailArray = [...checkboxValuesMail, ...checkboxValuesMail2]

        const checkbox1Array = checkboxSMSArray.map(value => value);
        const checkbox2Array = checkboxMailArray.map(value => value);

        let gonderilecekData = {
            alarmBildirimSMS: checkbox1Array,
            alarmBildirimMail: checkbox2Array,
            id: CookieManager.getCookie("id"),
            cihaz: cihazSeriNo
        }

        try {
            let res = await axios.put("https://api.enelsa.com:5015/api/alarmBildirimKaydet", JSON.stringify(gonderilecekData), {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + CookieManager.getCookie("token")
                }
            })
            let yeniBildirim = { mesaj: "Bildirim Ayarları", bildirim: 1, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)

            let location = window.location.href
            location = location.split("#")[0]
            location = location + "#modal-closed"
            window.location.href = location

        } catch {
            let yeniBildirim = { mesaj: "Bildirim Ayarları", bildirim: 0, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
            props.bildirimleriYonet("kanalRenk", yeniBildirim)
        }
    }

    async function yetkileriKaydet() {

        let gonderilecekData = {
            cihaz: cihazSeriNo,
            cihazKullanici: cihazKullanici,
            yetkili: kullaniciKodu,
            checkboxValuesYetki: checkboxValuesYetki,
            cihazTip: cihazTip.toLowerCase()
        }

        if (kullaniciKodu) {
            if (!(checkboxValuesYetki.every(value => !value))) {
                try {
                    let res = await axios.put("https://api.enelsa.com:5015/api/yetkileriKaydetOnay", JSON.stringify(gonderilecekData), {
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': 'Bearer ' + CookieManager.getCookie("token")
                        }
                    })
                    // console.log(res)
                    if (res.data == "kullaniciAdiHata") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Kullanıcı Adı Hatalı',
                            text: 'Lütfen geçerli bir kullanıcı adı giriniz.',
                            color: "aliceblue",
                            position: "center",
                            background: "#1C7EAF",
                            confirmButtonText: 'Tamam',
                            confirmButtonColor: '#003C8B',
                            width: '25em'
                        })
                    } else if (res.data == "duplicate") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Yetkili Kullanıcı',
                            text: 'Bu kullanıcı bu cihaz için zaten yetkili.',
                            color: "aliceblue",
                            position: "center",
                            background: "#1C7EAF",
                            confirmButtonText: 'Tamam',
                            confirmButtonColor: '#003C8B',
                            width: '25em'
                        })
                    } else if (res.data == "duplicateOnay") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Yetkili Kullanıcı',
                            text: 'Bu kullanıcıya bu cihaz için zaten onay isteği gönderilmiş.',
                            color: "aliceblue",
                            position: "center",
                            background: "#1C7EAF",
                            confirmButtonText: 'Tamam',
                            confirmButtonColor: '#003C8B',
                            width: '25em'
                        })
                    }
                    else {
                        let yeniBildirim = { mesaj: "Yetkilendirme Ayarları", bildirim: 1, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
                        props.bildirimleriYonet("kanalRenk", yeniBildirim)

                        let location = window.location.href
                        location = location.split("#")[0]
                        location = location + "#modal-closed"
                        window.location.href = location
                    }
                } catch {
                    let yeniBildirim = { mesaj: "Yetkilendirme Ayarları", bildirim: 0, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
                    props.bildirimleriYonet("kanalRenk", yeniBildirim)
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Tüm Yetkiler Boş',
                    text: 'Lütfen en az bir yetki seçiniz.',
                    color: "aliceblue",
                    position: "center",
                    background: "#1C7EAF",
                    confirmButtonText: 'Tamam',
                    confirmButtonColor: '#003C8B',
                    width: '25em'
                })
            }
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Kullanıcı Adı Boş',
                text: 'Lütfen geçerli bir kullanıcı adı giriniz.',
                color: "aliceblue",
                position: "center",
                background: "#1C7EAF",
                confirmButtonText: 'Tamam',
                confirmButtonColor: '#003C8B',
                width: '25em'
            })
        }


    }

    async function yetkileriKaydetEdit() {

        let gonderilecekData = {
            yetkileri: JSON.stringify(checkboxValuesYetki),
            kullaniciAdi: kullaniciAdiT,
            cihaz: cihazSeriNo,
            cihazKullanici: cihazKullanici
        }

        if (kullaniciAdiT) {
            if (!(checkboxValuesYetki.every(value => !value))) {
                try {
                    let res = await axios.put("https://api.enelsa.com:5015/api/yetkileriKaydetEdit", JSON.stringify(gonderilecekData), {
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': 'Bearer ' + CookieManager.getCookie("token")
                        }
                    })
                    setSifirla(true)
                    // console.log(res)
                    if (res.data == "kullaniciAdiHata") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Kullanıcı Adı Hatalı',
                            text: 'Lütfen geçerli bir kullanıcı adı giriniz.',
                            color: "aliceblue",
                            position: "center",
                            background: "#1C7EAF",
                            confirmButtonText: 'Tamam',
                            confirmButtonColor: '#003C8B',
                            width: '25em'
                        })
                    } else if (res.data == "duplicate") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Yetkili Kullanıcı',
                            text: 'Bu kullanıcı bu cihaz için zaten yetkili.',
                            color: "aliceblue",
                            position: "center",
                            background: "#1C7EAF",
                            confirmButtonText: 'Tamam',
                            confirmButtonColor: '#003C8B',
                            width: '25em'
                        })
                    }
                    else {
                        let yeniBildirim = { mesaj: "Yetkilendirme Ayarları", bildirim: 1, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
                        props.bildirimleriYonet("kanalRenk", yeniBildirim)

                        let location = window.location.href
                        location = location.split("#")[0]
                        location = location + "#modal-closed"
                        window.location.href = location

                    }
                } catch {
                    let yeniBildirim = { mesaj: "Yetkilendirme Ayarları", bildirim: 0, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
                    props.bildirimleriYonet("kanalRenk", yeniBildirim)
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Tüm Yetkiler Boş',
                    text: 'Lütfen en az bir yetki seçiniz.',
                    color: "aliceblue",
                    position: "center",
                    background: "#1C7EAF",
                    confirmButtonText: 'Tamam',
                    confirmButtonColor: '#003C8B',
                    width: '25em'
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Kullanıcı Adı Boş',
                text: 'Lütfen geçerli bir kullanıcı adı giriniz.',
                color: "aliceblue",
                position: "center",
                background: "#1C7EAF",
                confirmButtonText: 'Tamam',
                confirmButtonColor: '#003C8B',
                width: '25em'
            })
        }

    }

    // Son güncellenen tarihi almak için
    let validDate
    let dateFormat
    const rawDate = guncellenenTarih;
    if (rawDate && rawDate.includes('GMT')) {
        const [datePart, timePart, timeZonePart] = rawDate.split(' ');
        const [day, month, year] = datePart.split('.').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);
        const timeZoneOffset = parseInt(timeZonePart.split('GMT')[1], 10);
        validDate = new Date(Date.UTC(year, month - 1, day, hours - timeZoneOffset, minutes, seconds));
        dateFormat = guncellenenTarih.split('GMT')[0]
    } else if (rawDate && rawDate.includes('Z')) {
        validDate = new Date(guncellenenTarih)
        const date = new Date(guncellenenTarih);
        dateFormat = date.toLocaleString('tr-TR', { timeZone: 'Europe/Istanbul' });
    } else {
        dateFormat = ""
    }

    let periyot = ["günlük", "haftalık", "aylık", "3 Aylık"]
    let periyotMs = { 0: 24 * 60 * 60 * 1000, 1: 7 * 24 * 60 * 60 * 1000, 2: 30 * 24 * 60 * 60 * 1000, 3: 90 * 24 * 60 * 60 * 1000 }

    function tarihIsle(tarih) {
        const aylar = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];

        const gun = tarih.getDate() ? tarih.getDate() : "-";
        const ay = aylar[tarih.getMonth()] ? aylar[tarih.getMonth()] : "-";
        const yil = tarih.getFullYear() ? tarih.getFullYear() : "-";
        const saat = tarih.getHours() ? (
            tarih.getHours().toString().length === 1 ? "0" + tarih.getHours() : tarih.getHours()
        ) : "00";
        const dakika = tarih.getMinutes() ? (
            tarih.getMinutes().toString().length === 1 ? "0" + tarih.getMinutes() : tarih.getMinutes()
        ) : "00";
        const saniye = tarih.getSeconds() ? (
            tarih.getSeconds().toString().length === 1 ? "0" + tarih.getSeconds() : tarih.getSeconds()
        ) : "00";

        const tarihVerileriYil = gun + " " + ay + " " + yil;
        const tarihVerileriSaat = saat + ":" + dakika + ":" + saniye;

        return {
            tarihVerileriYil,
            tarihVerileriSaat,
        };
    }

    // Son rapor tarihini çekmek için
    let zamanDamgasiMs = new Date(zamanDamgasi).getTime();
    const sonrakiTarih = tarihIsle(new Date(periyotMs[raporFreq] + zamanDamgasiMs))
    const sonTarih = tarihIsle(new Date(zamanDamgasi))
    const sonTarihYil = sonTarih.tarihVerileriYil
    const sonTarihSaat = sonTarih.tarihVerileriSaat
    const sonrakiTarihYil = sonrakiTarih.tarihVerileriYil
    const sonrakiTarihSaat = sonrakiTarih.tarihVerileriSaat

    let token = CookieManager.getCookie("token");
    const isQueryParamsEmpty = !queryParams || queryParams === '';

    // if (!Array.isArray(yetkiliList)) {
    //     yetkiliList = [yetkiliList]; // Tek bir string varsa array'e çeviriyoruz
    // }

    return (
        <>
            {token ?
                <div id="cihaz">
                    {
                        <>
                            <div className="cihaz-isim" >
                                <div style={{ display: "flex", flexDirection: "column", rowGap: "3px" }} onClick={popupGizle}>
                                    <span className="isim-text">Cihaz Kullanıcı  : <em>{cihazKullanici}</em>  </span>
                                    <span className="isim-text">Cihaz İsim : <em>{cihazAdiT}</em> </span>
                                    <span className="isim-text">Cihaz Seri No : <em>{cihazSeriNo}</em> </span>
                                    {CookieManager.getCookie("kullaniciAdi") == cihazKullanici &&
                                        <a href="#modal-yetki" >
                                            <span className="isim-text">Cihaz Yetkilileri : <em>
                                                {keysArray && keysArray.join(", ")}
                                            </em>
                                            </span>
                                        </a>}
                                    <span className="isim-text">Son Güncelleme : <em>{dateFormat}</em> </span>
                                </div>
                                {
                                    ayarYetkisi ? (
                                        (Math.floor((new Date().getTime() - validDate) / 1000 / 60) < 10) ?
                                            <div className="omnicon-lock-ico">
                                                <ConfigLock show={configLock} onClick={mailPass}></ConfigLock>
                                            </div> :
                                            <div className="omnicon-lock-ico" style={{ display: "flex" }} title="Cihaz çevrimdışı olduğu için ayar yapılamıyor.">
                                                <FontAwesomeIcon icon={faWifi} style={{ fontSize: "25px", marginLeft: "15px", marginTop: "40px", color: "red" }} beatFade />
                                                <FontAwesomeIcon icon={faSlash} style={{ fontSize: "23px", fontWeight: "bolder", marginLeft: "-30px", marginTop: "40px", color: "red" }} beatFade />
                                            </div>) : null
                                }
                                <div>
                                    <Countdown eventBus={eventBus} onComplete={() => { lockConfigClient() }} onClick={() => { setShowConfigLockApprovalPopup(true) }}></Countdown>
                                </div>
                                <div className="rapor-alarm-text">
                                    {alarmSayisi > 0 &&
                                        <div>
                                            <a href="#modal-opened">
                                                <FontAwesomeIcon icon={faBell} className="cihaz-bilgi-kart-ust-taraf-ico2" shake style={{ height: 24, marginRight: 8 }} />
                                                <span>Alarmlar</span>
                                            </a>
                                        </div>}
                                    {raporYetkisi && (
                                        <div>
                                            <Link to={"/tablo/" + window.location.pathname.split("r/")[1]} style={{ marginLeft: "auto", marginRight: 45, display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", color: "#F7FBFF", textDecoration: "none" }}>
                                                <FontAwesomeIcon icon={faTable} style={{ height: 24, marginRight: 8 }} />
                                                <span>Raporlar</span>
                                            </Link>
                                        </div>
                                    )}
                                    <div>
                                        <a href="#ayarlar-modal">
                                            <FontAwesomeIcon icon={faGear} className="cihaz-bilgi-kart-ust-taraf-ico2" style={{ height: 24, marginRight: 8 }} />
                                            <span>Cihaz İşlemleri</span>
                                        </a>
                                    </div>
                                    {cihazTip.toLowerCase() === "omnicon" && (
                                        <div>
                                            <a href="#panel-modal">
                                                <FontAwesomeIcon icon={faExpand} className="cihaz-bilgi-kart-ust-taraf-ico2" style={{ height: 24, marginRight: 8 }} />
                                                <span >İnfo Panel Modu</span>
                                            </a>
                                        </div>)
                                    }
                                </div>
                            </div>
                            <div className="modal-container" id="modal-opened" >
                                <div className="modal">
                                    <div className="modal__details">
                                        <h1 className="modal__title">ALARMLAR</h1>
                                    </div>

                                    <p className="modal__text">
                                        {listItems}
                                        {listItems2}
                                    </p>
                                    <a href="#modal-closed" className="link-2"></a>
                                    <FontAwesomeIcon className="modal-alt-icon" icon={faBell} />
                                </div>
                            </div>
                            <div className="modal-container" id="modal-yetki">
                                <div className="modal">
                                    <div className="modal__details">
                                        <h1 className="modal__title">Yetkililer</h1>
                                        <hr></hr>
                                        <div style={{ height: "550px", overflow: "auto", display: "flex", flexWrap: "wrap", justifyContent: "center", columnGap: "25px" }}>
                                            {yetkiliList && Object.keys(yetkiliList).map((key) => {
                                                   let parsedYetkiliList;
                                                   try {
                                                       // Eğer yetkiliList[key] string ise JSON.parse yap, değilse direkt kullan
                                                       parsedYetkiliList = typeof yetkiliList[key] === "string" ? JSON.parse(yetkiliList[key]) : yetkiliList[key];
                                                   } catch (error) {
                                                       console.error("Yetkili listesi parse edilemedi: ", error);
                                                       return null;
                                                   }
                                                return (
                                                    <div key={key}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                justifyContent: "center",
                                                                margin: "5px",
                                                            }}
                                                        >
                                                            <span style={{ marginRight: "5px" }}>{key}</span>
                                                            <a href="#yetkilendir-modal-edit">
                                                                <FontAwesomeIcon
                                                                    icon={faPen}
                                                                    onClick={() => popupGoster2(key)}
                                                                    className="cihaz-bilgi-kart-ust-taraf-ico"
                                                                />
                                                            </a>
                                                        </div>

                                                        <div
                                                            style={{
                                                                fontSize: "15px",
                                                                marginBottom: "5px",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "flex-start",
                                                            }}
                                                        >
                                                            {Array.isArray(parsedYetkiliList) && parsedYetkiliList.map((value, index) => (
                                                                value && (
                                                                    <li key={index}>{yetkiList[index]}</li>
                                                                )
                                                            ))}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <a href="#modal-closed" className="link-2"></a>
                                    <FontAwesomeIcon className="modal-alt-icon" icon={faUserGear} />
                                </div>
                            </div>
                            <div className="modal-container" id="ayarlar-modal" >
                                <div className="modal" style={{ overflow: "hidden" }}>
                                    <div className="modal__details">
                                        <h1 className="modal__title">Cihaz İşlemleri</h1>
                                    </div>
                                    <div className="modal__text" style={{ cursor: "pointer" }}>
                                        {cihazTip?.toLowerCase() === "omnicon" && (
                                            smsmailYetkisi && (
                                                <>
                                                    <a href="#bildirim-modal">
                                                        <div className="cihaz-islemleri-text" style={{ flexDirection: "row" }}>
                                                            <FontAwesomeIcon icon={faSms} style={{ marginRight: "11px" }} />
                                                            <span>Cihaz Alarm Bildirimleri</span>
                                                        </div>
                                                    </a>
                                                    <hr style={{ width: "350px" }} />
                                                </>
                                            ))}
                                        {cihazTip?.toLowerCase() === "omnicon" && (
                                            raporYetkisi && (
                                                <>
                                                    <a href="#rapor-modal">
                                                        <div className="cihaz-islemleri-text" style={{ flexDirection: "row" }}>
                                                            <FontAwesomeIcon icon={faFileLines} style={{ marginRight: "15px" }} />
                                                            <span>Cihaz Raporlama Ayarları</span>
                                                        </div>
                                                    </a>
                                                    <hr style={{ width: "350px" }}></hr>
                                                </>
                                            ))}
                                        <a href="#isim-modal">
                                            <div className="cihaz-islemleri-text" style={{ flexDirection: "row" }}>
                                                <FontAwesomeIcon icon={faPen} style={{ marginRight: "11px", rotate: "270deg" }} />
                                                <span>Cihaz İsmini Düzenle</span>
                                            </div></a>
                                        <hr style={{ width: "350px" }} ></hr>
                                        {
                                            cihazTip?.toLowerCase() === "omnicon" && (
                                                <>
                                                    <div className="cihaz-islemleri-text" style={{ flexDirection: "row" }} onClick={kanallariDuzenle}>
                                                        <FontAwesomeIcon icon={faPalette} style={{ marginRight: "11px" }} />
                                                        <span>Kanalları Düzenle</span>
                                                    </div>
                                                    <hr style={{ width: "350px" }}></hr>
                                                </>
                                            )
                                        }


                                        {CookieManager.getCookie("kullaniciAdi") == cihazKullanici &&
                                            <>
                                                <a href="#yetkilendir-modal">
                                                    <div className="cihaz-islemleri-text" style={{ flexDirection: "row" }}>
                                                        <FontAwesomeIcon icon={faUserEdit} style={{ marginRight: "7px" }} />
                                                        <span>Kullanıcı Yetkilendir</span>
                                                    </div>
                                                </a> <hr style={{ width: "350px" }}></hr>
                                            </>
                                        }

                                        <div className="cihaz-sil-text" style={{ flexDirection: "row" }} onClick={() => { cihazSilApprove() }}>
                                            <FontAwesomeIcon icon={faTrashCan} style={{ marginRight: "15px" }} />
                                            <span>Cihazı Sil</span>
                                        </div>
                                    </div>
                                    <a href="#modal-closed" className="link-2"></a>
                                    <FontAwesomeIcon className="modal-alt-icon" style={{ rotate: "45deg" }} icon={faGear} />
                                </div>

                            </div>
                            <div className="modal-container" id="isim-modal" >
                                <div className="modal">
                                    <div className="modal__details">
                                        <h1 className="modal__title">Cihaz İsmi Düzenle</h1>
                                    </div>

                                    <p className="isim-modal__text">
                                        <span>Cihaz Kullanıcı  </span>: <em>{cihazKullanici}</em>
                                        <span>Cihaz Seri No </span>: <em>{cihazSeriNo}</em>
                                        <span>Cihaz Kurulum İsmi </span>: <em>{cihazIlkIsim}</em>
                                        <span>Cihaz İsim </span>:
                                        <input style={{ width: "169px", outline: "none", paddingLeft: "4px", color: "#42494F" }} className="arka-taraf-birimler-input2" value={cihazAdi} onChange={(e) => setCihazAdi(e.target.value)} />

                                    </p>
                                    <button className="modal__btn" onClick={() => { isimKaydet({ cihazAdi }, props.bildirimleriYonet) }}>KAYDET</button>
                                    <a href="#modal-closed" className="link-2"></a>
                                    <FontAwesomeIcon className="modal-alt-icon" icon={faPen} />
                                </div>
                            </div>
                            <div className="modal-container" id="panel-modal" >
                                <div className="modal">
                                    <a href="#modal-closed" className="link-2"></a>

                                    <div className="infopanel-kanallarDiv">
                                        <h2>Görüntülemek istediğiniz kanalları seçin:</h2>
                                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                                            {acikKanallar.map((kanal, index) => (
                                                <div className="checkboxContainer">
                                                    <label key={index} style={{ margin: "10px" }}>
                                                        <input className="customCheckbox" type="checkbox" value={kanal} onChange={handleChannelChange} />
                                                        <span className="checkmark"></span>
                                                        Kanal {kanal}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="infopanel-kanallarDiv">
                                        <h2>Ekstra bir metin eklemek ister misiniz?</h2>
                                        <div className="infopanel-inputDiv">
                                            <input style={{ width: "169px", outline: "none", paddingLeft: "4px", color: "#42494F" }} className="arka-taraf-birimler-input2" type="text" placeholder="Özel mesaj" value={customText} onChange={handleCustomTextChange} />
                                            <button style={{ padding: "10px 15px" }} className="modal__btn" onClick={handleAddText}>Ekle</button>
                                        </div>
                                        <div className="infopanel-listDiv">
                                            <ul>
                                                {texts.map((text, index) => (
                                                    <li key={index}>{text}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="infopanel-notDiv" style={{ fontSize: "15px" }}>
                                            <h4> Not: Alt satıra inmek için "/" ekleyiniz. </h4>
                                            <div className="infopanel-notDiv2">
                                                <div>
                                                    <span style={{ textDecoration: "underline" }}>Örnek Metin:</span><br></br> Merhaba Dünya Hoşgeldiniz <br></br><span style={{ textDecoration: "underline" }}>Ekranda Görünen:</span><br></br> Merhaba Dünya Hoşgeldiniz <br></br>
                                                </div>
                                                <div>
                                                    <span style={{ textDecoration: "underline" }}>Örnek Metin:</span><br></br> Merhaba Dünya/Hoşgeldiniz <br></br><span style={{ textDecoration: "underline" }}>Ekranda Görünen:</span><br></br> Merhaba Dünya <br></br> Hoşgeldiniz
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="infopanel-kanallarDiv">
                                        <h2>Görüntüleme aralığını belirleyin (ms):</h2>
                                        <input style={{ width: "169px", outline: "none", paddingLeft: "4px", color: "#42494F" }} className="arka-taraf-birimler-input2" type="number" placeholder="3000" value={intervalTime} onChange={handleIntervalChange} />
                                    </div>
                                    <div className="infopanel-buttonDiv">
                                        {shouldShowLink ? (
                                            <Link
                                                to={`/full-screen?${queryParams}`}
                                                style={{ padding: "10px 15px" }}
                                                className="button modal__btn"
                                            >
                                                Tam Ekran Moduna Geç
                                            </Link>
                                        ) : (
                                            <div style={{ padding: "10px 15px" }}>
                                                Tam Ekran Moduna Geçmek İçin En Az Bir Kanal Seçin ya da Metin Girin.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-container" id="bildirim-modal" >
                                <div className="modal">
                                    <div className="modal__details">
                                        <h1 className="modal__title">Cihaz Bildirim Ayarları</h1>
                                        <span style={{ display: "flex", justifyContent: "space-evenly", fontWeight: "bold" }}>
                                            <span style={{ width: "150px" }}>ALARM</span>
                                            <span style={{ width: "50px", marginLeft: "-40px", cursor: "pointer" }} onClick={() => alarmBildirimleriSMSAll()} title="Tümünü Seç">SMS</span>
                                            <span style={{ width: "50px", marginLeft: "-40px", cursor: "pointer" }} onClick={() => alarmBildirimleriMailAll()} title="Tümünü Seç">Mail</span>
                                        </span>
                                        <div style={{ height: "550px", overflow: "auto" }}>
                                            <table >
                                                {alarmlar?.slice(0, 16).map((alarm, i) => {
                                                    return (
                                                        <><tr style={{ width: "450px", height: "30px", display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                                                            <td style={{ width: "170px" }}>
                                                                <div className="profil-alarm-secme" onClick={() => alarmBildirimleriSatir(i)}>
                                                                    <span className="profil-cihaz-bilgileri-alt-div" style={{ color: "aliceblue" }}>
                                                                        {alarms[i]}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td style={{ marginLeft: "0px", marginBottom: "0" }} className="checkboxContainer">
                                                                <input className="customCheckbox" type="checkbox" checked={checkboxValuesSMS[i]} onChange={() => handleCheckboxChangeSMS(i)}></input>
                                                                <span className="checkmark"></span>
                                                            </td>
                                                            <td className="checkboxContainer">
                                                                <input className="customCheckbox" type="checkbox" checked={checkboxValuesMail[i]} onChange={() => handleCheckboxChangeMail(i)}></input>
                                                                <span className="checkmark"></span>
                                                            </td>
                                                        </tr>
                                                            <tr><hr style={{ width: "375px", marginRight: "30px", opacity: "0.6" }}></hr></tr>
                                                        </>
                                                    )
                                                })}
                                                {alarmlar?.slice(16,).map((alarm, i) => {
                                                    return (
                                                        <><tr style={{ width: "450px", height: "30px", display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                                                            <td style={{ width: "170px" }}>
                                                                <div className="profil-alarm-secme" onClick={() => alarmBildirimleriSatir2(i)}>
                                                                    <span className="profil-cihaz-bilgileri-alt-div" style={{ color: "aliceblue" }}>
                                                                        {alarms2[i]}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td style={{ marginLeft: "0px", marginBottom: "0px" }} className="checkboxContainer">
                                                                <input className="customCheckbox" type="checkbox" checked={checkboxValuesSMS2[i]} onChange={() => handleCheckboxChangeSMS2(i)}></input>
                                                                <span className="checkmark"></span>
                                                            </td>
                                                            <td className="checkboxContainer">
                                                                <input className="customCheckbox" type="checkbox" checked={checkboxValuesMail2[i]} onChange={() => handleCheckboxChangeMail2(i)}></input>
                                                                <span className="checkmark"></span>
                                                            </td>
                                                        </tr>
                                                            <tr><hr style={{ width: "375px", marginRight: "30px", opacity: "0.6" }}></hr></tr>
                                                        </>
                                                    )
                                                })}
                                            </table>
                                        </div>
                                    </div>
                                    <button className="modal__btn" onClick={() => { alarmBildirimleriSifirla() }}>SIFIRLA</button>
                                    <button className="modal__btn" onClick={() => { alarmBildirimleriKaydet() }}>KAYDET</button>
                                    <a href="#modal-closed" className="link-2"></a>
                                    <FontAwesomeIcon className="modal-alt-icon" icon={faEnvelope} />

                                </div>
                            </div>
                            <div className="modal-container" id="rapor-modal" >
                                <div className="modal">
                                    <div className="modal__details">
                                        <h1 className="modal__title">Rapor Alma Sıklığı</h1>
                                        {(raporFreqT == 4 || raporFreqT == undefined) ?
                                            <p>Bu cihaza ait rapor iletilmiyor.</p>
                                            :
                                            <>
                                                <p>Bu cihaza ait rapor, <b>{periyot[raporFreqT]}</b> olarak mail adresinize iletiliyor.</p>
                                                <p>Alınan son mail tarihi: <b>{sonTarihYil} {sonTarihSaat}</b> </p>
                                                {dataYok ? <p>Cihaz haberleşmediği sürece yeni mail iletilmeyecektir.</p> : <p>Sonraki mail tarihi: <b>{sonrakiTarihYil} {sonrakiTarihSaat}</b></p>}
                                            </>
                                        }
                                    </div>
                                    <div className="isim-modal__text">
                                        <span>Rapor Sıklığı</span>:
                                        <select name="raporFreq" id="raporFreq" className="cihaz-ekle-select" value={raporFreq} defaultValue={3} onChange={(e) => setRaporFreq(e.target.value)}>
                                            <option style={{ backgroundColor: "#4080C0" }} value="0">Günlük</option>
                                            <option style={{ backgroundColor: "#4080C0" }} value="1">Haftalık</option>
                                            <option style={{ backgroundColor: "#4080C0" }} value="2">Aylık</option>
                                            <option style={{ backgroundColor: "#4080C0" }} value="3">3 Aylık</option>
                                            {/* <option style={{backgroundColor: "#4080C0"}} value="4">Rapor İstemiyorum</option> */}
                                        </select>
                                    </div>
                                    <button className="modal__btn" onClick={() => { raporFreqKaydet(raporFreq, props.bildirimleriYonet) }}>KAYDET</button>
                                    <a href="#modal-closed" className="link-2"></a>
                                    <FontAwesomeIcon className="modal-alt-icon" icon={faFileLines} />
                                </div>
                            </div>
                            <div className="modal-container" id="yetkilendir-modal" >
                                <div className="modal">
                                    <div className="modal__details">
                                        <h1 className="modal__title">Kullanıcı Yetkilendirme</h1>
                                    </div>
                                    <p className="isim-modal__text">
                                        <div>
                                            <div>
                                                <span>Kullanıcı Adı </span>:
                                                <input style={{ marginLeft: "60px" }} value={kullaniciKodu} onChange={(e) => setKullaniciKodu(e.target.value)} className="kullanici-kodu-input" type="input" />
                                            </div>
                                            <span>Verilecek Yetkiler </span>:
                                            <table>
                                                {yetkiList.map((alarm, i) => {
                                                    return (
                                                        <><tr style={{ width: "450px", height: "30px", display: "flex", justifyContent: "space-evenly" }}>
                                                            <td style={{ width: "270px" }}>
                                                                <div className="profil-alarm-secme" onClick={() => alarmBildirimleriYetki(i)}>
                                                                    <span className="profil-cihaz-bilgileri-alt-div" style={{ color: "aliceblue" }}>
                                                                        {yetkiList[i]}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td style={{ marginLeft: "20px" }} className="checkboxContainer">
                                                                <input className="customCheckbox" type="checkbox" checked={checkboxValuesYetki[i]} onChange={() => handleCheckboxChangeYetki(i)}></input>
                                                                {
                                                                    i == 0 ? <span className="checkmark" style={{ backgroundColor: "rgb(124 140 155)" }}></span> : <span className="checkmark"></span>
                                                                }
                                                            </td>
                                                        </tr>
                                                            <tr><hr style={{ width: "375px", marginRight: "30px", opacity: "0.6" }}></hr></tr>
                                                        </>
                                                    )
                                                })}</table>
                                        </div>
                                    </p>
                                    <button className="modal__btn" onClick={() => { yetkileriKaydet() }}>KAYDET</button>
                                    <a href="#modal-closed" className="link-2"></a>
                                    <FontAwesomeIcon className="modal-alt-icon" icon={faUserEdit} />
                                </div>
                            </div>
                            <div className="modal-container" id="yetkilendir-modal-edit" >
                                <div className="modal">
                                    <div className="modal__details">
                                        <h1 className="modal__title">Kullanıcı Yetkilerini Düzenle</h1>
                                        <div style={{ display: "flex" }}>
                                            <span>Kullanıcı Adı </span>: {kullaniciAdiT}
                                        </div>
                                        <p style={{ display: "flex" }}>
                                            Mevcut Yetkileri :
                                        </p>
                                        <div className="yetkilist-div">
                                            {kullaniciYetkileriFunc(kullaniciAdiT)}
                                        </div>
                                    </div>
                                    <p className="isim-modal__text">
                                        <div>
                                            <span>Verilecek Yetkiler </span>:
                                            <table>
                                                {yetkiList.map((alarm, i) => {
                                                    return (
                                                        <><tr style={{ width: "450px", height: "30px", display: "flex", justifyContent: "space-evenly" }}>
                                                            <td style={{ width: "270px" }}>
                                                                <div className="profil-alarm-secme" onClick={() => alarmBildirimleriYetki(i)}>
                                                                    <span className="profil-cihaz-bilgileri-alt-div" style={{ color: "aliceblue" }}>
                                                                        {yetkiList[i]}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td style={{ marginLeft: "20px" }} className="checkboxContainer">
                                                                <input className="customCheckbox" type="checkbox" checked={checkboxValuesYetki[i]} onChange={() => handleCheckboxChangeYetki(i)}></input>
                                                                {
                                                                    i == 0 ? <span className="checkmark" style={{ backgroundColor: "rgb(124 140 155)" }}></span> : <span className="checkmark"></span>
                                                                }
                                                            </td>
                                                        </tr>
                                                            <tr><hr style={{ width: "400px", marginRight: "30px", opacity: "0.6" }}></hr></tr>
                                                        </>
                                                    )
                                                })}</table>
                                        </div>
                                    </p>
                                    <div style={{ display: "flex", justifyContent: "space-between", position: "relative", zIndex: "999" }}>
                                        <button className="modal__btn sil-buton" onClick={() => { yetkiliSilApprove() }}>KULLANICIYI SİL</button>
                                        <button className="modal__btn" onClick={() => { yetkileriKaydetEdit() }}>KAYDET</button>
                                    </div>
                                    <a href="#modal-closed" className="link-2"></a>
                                    <FontAwesomeIcon className="modal-alt-icon" icon={faUserEdit} />
                                </div>
                            </div>
                            {showConfigLockApprovalPopup &&
                                <ApprovalPopup
                                    text="Ayarları yeniden kilitlemek istediğinizden emin misiniz?"
                                    onApprove={() => { lockConfig() }}
                                    onReject={() => { }}
                                    state={[showConfigLockApprovalPopup, setShowConfigLockApprovalPopup]}
                                ></ApprovalPopup>}
                            {showCihazSilApprovalPopup &&
                                <ApprovalPopup
                                    text="Cihazı silmek istediğinizden emin misiniz?
                                Cihazı yeniden görüntüleyebilmeniz için cihaz sahibinin tekrar yetki vermesi gerekecek!"
                                    onApprove={() => { cihazSil() }}
                                    onReject={() => { }}
                                    state={[showCihazSilApprovalPopup, setShowCihazSilApprovalPopup]}
                                ></ApprovalPopup>}
                            {showYetkiliSilApprovalPopup &&
                                <ApprovalPopup
                                    text="Yetkili kullanıcıyı silmek istediğinizden emin misiniz?
                                Kullanıcının cihazı yeniden görüntüleyebilmesi için tekrar yetki vermeniz gerekecek!"
                                    onApprove={() => { yetkiliSil(kullaniciAdiT) }}
                                    onReject={() => { }}
                                    state={[showYetkiliSilApprovalPopup, setShowYetkiliSilApprovalPopup]}
                                ></ApprovalPopup>}
                            {<MailPopup tokenBilgileri={props.tokenBilgileri} eventBus={eventBus}></MailPopup>}
                        </>
                    }
                    {
                        <div id="cihaz-grid" style={{ justifyContent: ekranGenisligi > 1300 ? "start" : "center", gridTemplateColumns: gridTemplateColumnsHesapla(), marginTop: props.temaAyar ? "20px" : "10px" }}>
                            {
                                ekrandaGosterilecekleriOlustur(data, props.bildirimleriYonet).map((e) => {
                                    return (
                                        e
                                    )
                                })
                            }
                        </div>
                    }
                    <div className="ayarIcon" >
                        {props.temaAyar && <>
                            <div style={{ marginLeft: "-45px", color: "aliceblue", width: "fit-content", fontSize: "12px" }}>
                                {"Tema Ayarları"}
                            </div>
                            <div onClick={kanalRenkleriKaydet} style={{ marginLeft: "-40px", height: "30px", width: "70px", backgroundColor: "#9CFDFF", borderRadius: "3px", display: "flex", cursor: "pointer" }}>
                                <span style={{ margin: "auto", color: "#41494F", fontSize: "13px" }}>KAYDET</span>
                            </div>
                        </>}
                    </div>
                </div >
                : <div id="cihaz"></div>}
        </>
    )
}

export default Cihaz


