import "./cihaz-onay.css"
import React, { useEffect, useState } from "react"
import bekleyenOnaylar from "../data-islemleri/bekleyen-onaylar"
import { ApprovalPopup } from "../../cihaz-sayfasi/components/nova/common-components"
import axios from "axios"
import { CookieRounded } from "@mui/icons-material"
import CookieManager from "../../../../cookieManager"

const CihazOnay = (props) => {
	let yetkiList = ["Anlık verileri görüntüleyebilir.", "Rapor oluşturabilir.", "Grafikleri görüntüleyebilir.", "SMS ve Mail alabilir.", "Ayar yapabilir."]

	const [showCihazOnay, setShowCihazOnay] = useState(false)
	const [showCihazReddet, setShowCihazReddet] = useState(false)
	const [onayId, setOnayId] = useState()
	const [cihazTipx, setCihazTipx] = useState()
	const [baslangic, setBaslangic] = useState(false)
	const [bekleyenOnaylarObj, setBekleyenOnaylarObj] = useState([])
	const [onaylananOnaylarObj, setOnaylananOnaylarObj] = useState([])
	const [reddedilenOnaylarObj, setReddedilenOnaylarObj] = useState([])

	const BekleyenOnaylarKarti = ({ cihazKullanici, cihazSeri, cihazTip, date, id, yetkileri, durum }) => {
		const milliseconds = Date.parse(date);
		const datex = new Date(milliseconds);
		const formattedDateTime = datex.toLocaleString("tr-TR");

		const handleOnay = (cihazTip) => {
			onayla(id, cihazTip);
		};

		const handleReddet = () => {
			reddet(id);
		};

		return (
			<div className="bekleyen-onaylar-karti">
				<div className="bekleyen-onaylar-kart-detay"><h4>Cihaz Seri No:</h4> <span>{cihazSeri}</span></div>
				<div className="bekleyen-onaylar-kart-detay"><h4>Cihaz Tip: </h4><span>{cihazTip}</span></div>
				<div className="bekleyen-onaylar-kart-detay"><h4>Cihaz Kullanıcı: </h4><span>{cihazKullanici}</span></div>
				<div className="bekleyen-onaylar-kart-detay" style={{ height: "95px" }}><h4>Yetkiler: </h4><span className="yetkiler-span">{yetkiList.map((alarm, i) => {
					return (
						<>
							<span>
								{JSON.parse(yetkileri)[i] && alarm}
							</span>
						</>
					)
				})}</span></div>
				<div className="bekleyen-onaylar-kart-detay"><h4>Tarih: </h4><span>{formattedDateTime.slice(0, -3)}</span></div>
				<div className="onay-butonlari">
					<button className="modal__btn" onClick={() => handleOnay(cihazTip)}>Onayla</button>
					<button className="modal__btn sil-buton" onClick={handleReddet}>Reddet</button>
				</div>
				<p className="onay-durumu">{durum}</p>
			</div>
		);
	};

	const OnaylananOnaylarKarti = ({ cihazKullanici, cihazSeri, cihazTip, date, yetkileri }) => {
		const milliseconds = Date.parse(date);
		const datex = new Date(milliseconds);
		const formattedDateTime = datex.toLocaleString("tr-TR");

		return (
			<div className="bekleyen-onaylar-karti">
				<div className="bekleyen-onaylar-kart-detay"><h4>Cihaz Seri No:</h4> <span>{cihazSeri}</span></div>
				<div className="bekleyen-onaylar-kart-detay"><h4>Cihaz Tip: </h4><span>{cihazTip}</span></div>
				<div className="bekleyen-onaylar-kart-detay"><h4>Cihaz Kullanıcı: </h4><span>{cihazKullanici}</span></div>
				<div className="bekleyen-onaylar-kart-detay" style={{ height: "95px" }}><h4>Yetkiler: </h4><span className="yetkiler-span">{yetkiList.map((alarm, i) => {
					return (
						<>
							<span>
								{JSON.parse(yetkileri)[i] && alarm}
							</span>
						</>
					)
				})}</span></div>
				<div className="bekleyen-onaylar-kart-detay"><h4>Tarih: </h4><span>{formattedDateTime.slice(0, -3)}</span></div>
			</div>
		);
	};

	useEffect(() => {
		if (baslangic) return
		setBaslangic(true)
		try {
			props.kullaniciBilgileriGuncelle()
			props.konumGuncelle("cihazonay")
			bekleyenOnaylar(CookieManager.getCookie("token")).then((e) => {
				if (e) {
					setBekleyenOnaylarObj(e.bekleyen)
					setOnaylananOnaylarObj(e.onaylanan)
					setReddedilenOnaylarObj(e.reddedilen)
				}
			})
		} catch (e) {
		}
	}, [baslangic, props])

	const onayla = (id, cihazTip) => {
		setShowCihazOnay(true)
		setOnayId(id)
		setCihazTipx(cihazTip)
	};

	const cihazOnay = async (id, cihazTipx, durum) => {
		let gonderilecekData = {
			id: id, // Veritabanındaki ilgili isteğin id'si
			userId: CookieManager.getCookie("id"),
			durum: durum,
			kullaniciAdi: CookieManager.getCookie("kullaniciAdi"),
			cihazTip: cihazTipx
		}
		try {
			let res = await axios.put("https://api.enelsa.com:5015/api/cihazOnayKaydet", JSON.stringify(gonderilecekData), {
				headers: {
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + CookieManager.getCookie("token")
				}
			})
			// props.temaAyarDegistir()
			let yeniBildirim = { mesaj: "Cihaz Onayı", bildirim: 1, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
			props.bildirimleriYonet("kanalRenk", yeniBildirim)
		} catch {
			let yeniBildirim = { mesaj: "Cihaz Onayı", bildirim: 0, key: "kalanRenk-" + Math.random(10), sagaGorePozisyonu: -250, uyari: 0 }
			props.bildirimleriYonet("kanalRenk", yeniBildirim)
		}
		//console.log("onaylandı", id)
	}

	const reddet = (id) => {
		setShowCihazReddet(true)
		setOnayId(id)
	};

	return (
		<>
			<div className="cihaz-onay-page">
				<div>
					<h1 className="bekleyen-onaylar-title">Bekleyen Onaylar</h1>
					<div className="bekleyen-onaylar-kart-grid">
						{(bekleyenOnaylarObj) != undefined && Object.keys(bekleyenOnaylarObj).length > 0
							?
							Object.values(bekleyenOnaylarObj).map((onay) => (
								<BekleyenOnaylarKarti key={onay.id} {...onay} onayla={() => onayla(onay.id)} reddet={() => reddet(onay.id)} />
							))
							:
							<div>
								Bekleyen onayınız bulunmuyor.
							</div>
						}
					</div>
				</div>
				<div>
					{onaylananOnaylarObj && Object.keys(onaylananOnaylarObj)?.length > 0
						&&
						<><h1 className="bekleyen-onaylar-title">Onaylananlar</h1>
							<div className="bekleyen-onaylar-kart-grid">
								<div style={{ display: "flex", flexWrap: "wrap" }}>
									{Object.values(onaylananOnaylarObj).map((onay) => (
										<OnaylananOnaylarKarti key={onay.id} {...onay} />
									))}
								</div>
							</div></>
					}
				</div>
				<div>
					{reddedilenOnaylarObj && Object.keys(reddedilenOnaylarObj)?.length > 0
						&&
						<>
							<h1 className="bekleyen-onaylar-title">Reddedilenler</h1>
							<div className="bekleyen-onaylar-kart-grid">
								<div style={{ display: "flex", flexWrap: "wrap" }}>
									{Object.values(reddedilenOnaylarObj).map((onay) => (
										<OnaylananOnaylarKarti key={onay.id} {...onay} />
									))}
								</div>
							</div></>
					}
				</div>
				{showCihazOnay &&
					<ApprovalPopup
						text="Cihazı onaylamak istediğinizden emin misiniz?"
						onApprove={() => { cihazOnay(onayId, cihazTipx, "Onaylandı") }}
						onReject={() => { }}
						state={[showCihazOnay, setShowCihazOnay]}
					></ApprovalPopup>}
				{showCihazReddet &&
					<ApprovalPopup
						text="Cihazı reddetmek istediğinizden emin misiniz?
					Cihazı tekrar eklemek isterseniz cihaz kullanıcısının yeniden yetki vermesi gerekecek!"
						onApprove={() => { cihazOnay(onayId, "Reddedildi") }}
						onReject={() => { }}
						state={[showCihazReddet, setShowCihazReddet]}
					></ApprovalPopup>}
			</div>
		</>
	)
}
export default CihazOnay

