import React, { useEffect, useState } from "react"
import { faX, faWaveSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./paket-arka-taraf.css"
import ProsesPopUp from "./proses-pop-up"
import yuklemeDalgasi from "../img/dalga-animasyon.svg"
import axios from "axios"
import CookieManager from "../../../../../cookieManager"
import Swal from "sweetalert2"
import {
    mode,
    state,
    isletimModu,
    unitSelection,
    unitSelection2,
    icolarMod,
    icolarState
} from './dictionaries'
import {
    ChooseMenu,
    SelectMenu,
    Input,
    ConfigLock,
    EventBus,
    ApprovalPopup,
    MailPopup,
    Countdown
} from "./common-components"

import { ConfigClient } from "./common-requests"

const PaketArkaTaraf = (props) => {
    let [popupDurum1, setPopupDurum1] = useState(false)
    let [popupKonum1, setPopupKonum1] = useState(-250)
    let [index, setIndex] = useState(null)

    const popupGoster = (i) => {
        setPopupDurum1(true)
        setTimeout(() => setPopupKonum1(100), 100)
        setIndex(i)
    }

    const popupGizle = () => {
        setPopupKonum1(-250)
        setTimeout(() => setIndex(null), 320)
    }

    let girisMod = {
        "0": "Başlangıçta Tetik",
        "1": "Manuel Tetik",
        "2": "Sinyal Tetik",
        "3": "Periyodik",
        "4": "Zaman Program"
    }

    let [baslangic, setBaslangic] = useState(false)
    let [showSettingsMenu, setShowSettingsMenu] = useState(false)
    let [strokeRateUnitSelection, setStrokeRateUnitSelection] = useState(null)
    let [runMode, setRunMode] = useState(null)
    let [editx, setEditx] = useState(false)
    let [strokeRate, setStrokeRate] = useState(null)
    let [strokeRateLowLimit, setStrokeRateLowLimit] = useState(null)
    let [strokeRateHighLimit, setStrokeRateHighLimit] = useState(null)
    let [icoRunMode, setIcoRunMode] = useState(null)
    let [programPeriod, setProgramPeriod] = useState(null)
    let [currentDayNumber, setCurrentDayNumber] = useState(null)
    let [pulseCount, setPulseCount] = useState(null)
    let [pulseDivider, setPulseDivider] = useState(null)
    let [processStrokeRate, setProcessStrokeRate] = useState(null)
    let [processTime, setProcessTime] = useState(null)
    let [processStrokeCount, setProcessStrokeCount] = useState(null)
    let [processCapacity, setProcessCapacity] = useState(true)
    let [configLock, setConfigLock] = useState(true)
    const [eventBus, _] = useState(new EventBus())//should be immuted not setted needs to be stored in component memory
    let [showConfigLockApprovalPopup, setShowConfigLockApprovalPopup] = useState(false) // typeof Boolean
    let [showLoadScreen, setShowLoadScreen] = useState(true)
    let hour = ""
    let minute = ""
    if (props.veriler["periodHour"] != undefined) {
        let periodHourString = props.veriler["periodHour"].toString();
        hour = periodHourString.length === 1 ? "0" + periodHourString : periodHourString

        let periodMinuteString = props.veriler["periodMinute"].toString();
        minute = periodMinuteString.length === 1 ? "0" + periodMinuteString : periodMinuteString
    }

    let dakika = ""
    let saniye = ""
    let processTimex = ""
    if (props.veriler.processTime != undefined) {
        let dakikaa = Math.floor(props.veriler.processTime / 60);
        let dakikaString = dakikaa.toString();
        dakika = dakikaString.length === 1 ? "0" + dakikaString : dakikaString

        let saniyee = props.veriler.processTime % 60;
        let saniyeString = saniyee.toString();
        saniye = saniyeString.length === 1 ? "0" + saniyeString : saniyeString

        processTimex = dakika + " : " + saniye
    }

    let buttonsDivClass = props.ekranGenisligi > 790 ? "nova-bilgi-kart-paket nova-bilgi-kart-paketx" : "nova-bilgi-kart-paket"

    function butonlar() {
        const buttons = []
        for (let i = 0; i < 10; i++) {
            const programAdi = i
            let className = props.veriler.programs[programAdi].processStartDayNumber != 0 ? "active" : "passive"
            buttons.push(
                <button className={className} onClick={() => popupGoster(i)}>Program {i + 1}</button>
            );
        }
        return (
            <div className={buttonsDivClass}>
                {buttons}
            </div>
        )
    }

    let popupArkaplanHeight = props.ekranGenisligi < 790 ? "700px" : "570px"

    function AyarPopup() {
        return (
            <div className="ayar-popup-arkaplan" style={{ height: popupArkaplanHeight }}>
                {showLoadScreen &&
                    <>
                        <div className="ayar-popup-yukleme-container">
                            <div className="ayar-popup-yukleme ayar-popup-yukleme-dolu" style={{ backgroundImage: `url(${yuklemeDalgasi})` }}></div>
                        </div>
                        <span className="ayar-popup-kaydediliyor-metni">Ayarlar Kaydediliyor</span>
                    </>
                }
                {
                    showSettingsMenu && <div className='ayar-popup'>
                        <span><FontAwesomeIcon className='ayar-popup-kapat' icon={faX} onClick={() => { setShowSettingsMenu(false) }} /></span>
                        {ChooseMenu(strokeRateUnitSelection, setStrokeRateUnitSelection, unitSelection)}
                        <button className="ayar-popup-kaydet" onClick={() => { manuelKaydet(props.cihazKullanici, { strokeRateUnitSelection: parseInt(strokeRateUnitSelection) }) }}>Kaydet</button>
                    </div>
                }
            </div>
        )
    }

    let location = window.location.pathname.split("r/")[1]
    let seriNo = location.split("#")[0]

    async function manuelKaydet(cihazKullanici, data) {
        // console.log(data)
        let gonderilecekData = {}
        let keys = Object.keys(data)
        keys.map((e) => {
            if (!isNaN(data[e])) {
                gonderilecekData[e] = data[e]
            }
        })
        let gonderilecek = {
            cihazKullanici: cihazKullanici,
            data: gonderilecekData,
            seriNo: seriNo
        }
        try {
            const res = await axios.post("https://api.enelsa.com:5015/api/cihazAyarNova", JSON.stringify(gonderilecek), {
                headers: {
                    "Content-Type": "application/json",
                    "authorization": "Bearer " + CookieManager.getCookie("token")
                }
            })
            if (res.data === "ok") {
                let gonderilecekData = {
                    seriNo: seriNo
                }
                try {
                    const res = await axios.post('https://api.enelsa.com:5015/api/cihazAyarKontrol', JSON.stringify(gonderilecekData), {
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': 'Bearer ' + CookieManager.getCookie("token")
                        }
                    })
                    if (res.status == 200) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Ayar Başarılı',
                            text: 'Güncelleme işlemi 1-5 saniye içinde gerçekleşecektir.',
                            confirmButtonText: 'Tamam',
                            confirmButtonColor: 'rgb(70, 85, 105)',
                            width: '25em',
                        })
                        setEditx(true)
                    }

                } catch (e) {
                    // console.log(e)
                }
            }
            setEditx(true)
            setShowLoadScreen(true)
            setShowSettingsMenu(false)
        } catch (e) {
            setShowLoadScreen(false)
        }
    }


    useEffect(() => {
        setInitialConfigLockState()
    }, [])

    async function setInitialConfigLockState() {
        lockConfigClient()
        ConfigClient.getExpiration(CookieManager.getCookie("token"))
            .then(response => {
                // console.log(response.data)
                const { exp } = response.data
                if (exp !== null) {
                    setConfigLock(false)
                    eventBus.publish('set countdown', exp)
                }

            })
    }

    async function lockConfig() {
        lockConfigClient()
        eventBus.publish('clear countdown')
        const res = await ConfigClient.lock(CookieManager.getCookie("token"))
    }

    function lockConfigClient() {
        setConfigLock(true)
        setShowConfigLockApprovalPopup(false)
    }

    function runModeEdit() {
        runMode == 0 ? setRunMode("1") : setRunMode("0")
        icoRunMode == 0 ? setIcoRunMode("1") : setIcoRunMode("0")
    }

    async function mailPass() {
        eventBus.publish('ask mail verification', {
            token: CookieManager.getCookie("token"),
            success({ exp }) {
                eventBus.publish('set countdown', exp)
                setConfigLock(false)
                // console.log('code verification was success should open the gui')
            },
            reject() {
                // console.log('code verification was rejected')
            }
        })
        lockConfigClient()
    }

    async function varsayilanlaraSifirla() {
        setStrokeRate(props.veriler["strokeRate"])
        setStrokeRateLowLimit(props.veriler["strokeRateLowLimit"])
        setStrokeRateHighLimit(props.veriler["strokeRateHighLimit"])
        setStrokeRateUnitSelection(props.veriler["strokeRateUnitSelection"])
        setProcessTime(processTimex)
        setProcessCapacity(props.veriler["processCapacity"])
        setProcessStrokeCount(props.veriler["processStrokeCount"])
        setProcessStrokeRate(props.veriler["processStrokeRate"])
        setPulseCount(props.veriler["pulseCountPackage"])
        setRunMode(props.veriler["runMode"])
        setIcoRunMode(props.veriler["runMode"])
    }

    useEffect(() => {
        setBaslangic(true)
        setStrokeRate(props.veriler["strokeRate"])
        setStrokeRateLowLimit(props.veriler["strokeRateLowLimit"])
        setStrokeRateHighLimit(props.veriler["strokeRateHighLimit"])
        setStrokeRateUnitSelection(props.veriler["strokeRateUnitSelection"])
        setProcessTime(processTimex)
        setProcessCapacity(props.veriler["processCapacity"])
        setProcessStrokeCount(props.veriler["processStrokeCount"])
        setProcessStrokeRate(props.veriler["processStrokeRate"])
        setPulseCount(props.veriler["pulseCountPackage"])
        setPulseDivider(props.veriler["pulseDividerPackage"])
        setProgramPeriod(props.veriler["programPeriod"])
        setCurrentDayNumber(props.veriler["currentDayNumber"])
        setRunMode(props.veriler["runMode"])
        setIcoRunMode(props.veriler["runMode"])
    }, [baslangic, editx && props])

    useEffect(() => {
        setShowLoadScreen(false)
        setShowSettingsMenu(false)
        setEditx(false)
    }, [props.veriler])

    function runModeEdit() {
        runMode == 0 ? setRunMode("1") : setRunMode("0")
        icoRunMode == 0 ? setIcoRunMode("1") : setIcoRunMode("0")
    }

    let classNovaAlt = props.ekranGenisligi > 790 ? "nova-bilgi-kart-alt" : (props.ekranGenisligi > 520) ? "nova-bilgi-kart-alt-tablet" : "nova-bilgi-kart-alt-mobil"
    let tetikSinyalAlt = props.ekranGenisligi > 790 ? "tetik-sinyal" : (props.ekranGenisligi > 520) ? "tetik-sinyal-tablet" : "tetik-sinyal-mobil"
    let tetikPeriyoduAlt = props.ekranGenisligi > 790 ? "tetik-periyot" : (props.ekranGenisligi > 520) ? "tetik-periyot-tablet" : "tetik-periyot-mobil"

    return (
        <>
            <div className="cihaz-bilgi-kart-icerik-cerceve2">
                <div className="nova-bilgi-kart-ust">
                    <div className="nova-isletim-modu">
                        <div>İşletim Modu : {isletimModu[props.veriler["operatingMode"]]}</div>
                        <div>Sinyal Giriş Modu : {girisMod[props.veriler["packageSubMode"]]}</div>
                    </div>
                </div>
                <div className="nova-bilgi-kart-orta-ppm">
                    <div className="vurus-sayisi">
                        <div className="vurus-ust">
                            Vuruş Oranı
                        </div>
                        <hr className="vurus-cizgi"></hr>
                        <div className="vurus-alt">
                            <span>{props.veriler["strokeRate"]}
                                {configLock ?
                                    <button className="nova-bilgi-kart-orta-unit-selection-button" >{unitSelection[strokeRateUnitSelection]}</button>
                                    :
                                    <button className="nova-bilgi-kart-orta-unit-selection-button" onClick={() => { setShowSettingsMenu(true) }}>{unitSelection[strokeRateUnitSelection]}</button>
                                }
                            </span>
                        </div>
                        <div>
                            <hr className=""></hr>
                            <div className="icos">
                                <div className="calisma-modux">
                                    <div>Çalışma Modu : <span>{mode[runMode]}</span> {<span className={(mode[props.veriler["runMode"]] != mode[runMode]) ? "editStar" : "star"}>*</span>}</div>
                                    <span style={{ cursor: "pointer" }} onClick={runModeEdit}>{icolarMod[icoRunMode]} </span>
                                </div>
                                <div className="calisma-modux">
                                    <div>Durum : <span>{state[props.veriler["runModeState"]]} {<span className="star">*</span>}</span></div>
                                    <span>{icolarState[props.veriler["runModeState"]]} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(props.veriler["packageSubMode"] == "4") && butonlar()}
                <div className={classNovaAlt}>
                    <div className="kapasite-limit">
                        <div>Kapasite Alt Limit : <span>{Input(strokeRateLowLimit, setStrokeRateLowLimit, "nova-manuel-alt-input")} {<span className={(props.veriler["strokeRateLowLimit"] != strokeRateLowLimit) ? "editStar" : "star"}>*</span>} {unitSelection2[strokeRateUnitSelection]}</span> </div>
                        <div>Kapasite Üst Limit : <span>{Input(strokeRateHighLimit, setStrokeRateHighLimit, "nova-manuel-alt-input")} {<span className={(props.veriler["strokeRateHighLimit"] != strokeRateHighLimit) ? "editStar" : "star"}>*</span>} {unitSelection2[strokeRateUnitSelection]}</span> </div>
                    </div>
                    {(props.veriler["packageSubMode"] == "4") &&
                        <div className="kapasite-limit">
                            <div>Program Periyodu (Gün): <span>{Input(programPeriod, setProgramPeriod, "nova-manuel-alt-input")} {<span className={(props.veriler["programPeriod"] != programPeriod) ? "editStar" : "star"}>*</span>} </span></div>
                            <div>Program Gün No : <span>{Input(currentDayNumber, setCurrentDayNumber, "nova-manuel-alt-input")} {<span className={(props.veriler["currentDayNumber"] != currentDayNumber) ? "editStar" : "star"}>*</span>} </span></div>
                        </div>
                    }
                    {(props.veriler["packageSubMode"] != "4") &&
                        <>
                            <div className="kapasite-limit">
                                <div>Proses Vuruş Oranı : <span>{Input(processStrokeRate, setProcessStrokeRate, "nova-manuel-alt-input")} {<span className={(props.veriler["processStrokeRate"] != processStrokeRate) ? "editStar" : "star"}>*</span>} <FontAwesomeIcon icon={faWaveSquare}></FontAwesomeIcon>d</span></div>
                                <div>Proses Zamanı : <span>{processTime} {<span className={(processTimex != processTime) ? "editStar" : "star"}>*</span>}</span></div>
                            </div>
                            <div className="kapasite-limit">
                                <div>Proses Vuruş Sayısı : <span>{Input(processStrokeCount, setProcessStrokeCount, "nova-manuel-alt-input")} {<span className={(props.veriler["processStrokeCount"] != processStrokeCount) ? "editStar" : "star"}>*</span>} <FontAwesomeIcon icon={faWaveSquare}></FontAwesomeIcon></span></div>
                                <div>Proses Kapasitesi : <span>{processCapacity} {<span className={(props.veriler["processCapacity"] != processCapacity) ? "editStar" : "star"}>*</span>} L</span></div>
                            </div>
                        </>
                    }
                </div>
                {(props.veriler["packageSubMode"] == "2") &&
                    <div className={tetikSinyalAlt}>
                        <div>Tetik Sinyal Sayısı : <span>{Input(pulseCount, setPulseCount, "nova-manuel-alt-input")}{<span className={(props.veriler["pulseCountPackage"] != pulseCount) ? "editStar" : "star"}>*</span>} s</span></div>
                        <div>Tetik Sinyal Bölen : <span>{Input(pulseDivider, setPulseDivider, "nova-manuel-alt-input")}{<span className={(props.veriler["pulseDividerPackage"] != pulseDivider) ? "editStar" : "star"}>*</span>} s</span></div>
                    </div>
                }
                {(props.veriler["packageSubMode"] == "3") &&
                    <div className={tetikPeriyoduAlt}>
                        <div>Tetik Periyodu : <span>{hour} : {minute}</span></div>
                    </div>
                }
            </div>
            {
                <div className="buttonDiv">
                    <button onClick={() => varsayilanlaraSifirla()} className="nova-kaydet-button">TEMİZLE</button>
                    <button onClick={() => manuelKaydet(props.cihazKullanici,
                        {
                            strokeRate: parseInt(strokeRate),
                            strokeRateLowLimit: parseInt(strokeRateLowLimit),
                            strokeRateHighLimit: parseInt(strokeRateHighLimit),
                            runMode: parseInt(runMode),
                            programPeriod: parseInt(programPeriod),
                            currentDayNumber: parseInt(currentDayNumber),
                            pulseCount: parseInt(pulseCount),
                            pulseDivider: parseInt(pulseDivider),
                            processStrokeRate: parseInt(processStrokeRate),
                            processStrokeCount: parseInt(processStrokeCount)
                        }
                    )} className="nova-kaydet-button">KAYDET</button>
                </div>
            }
            {(showLoadScreen || showSettingsMenu) && <AyarPopup></AyarPopup>}
            {(index !== undefined && index !== null) && (
                <ProsesPopUp
                    veriler={props.veriler}
                    index={index}
                    popupKonum={popupKonum1}
                    kapat={popupGizle}
                />
            )}

            {showConfigLockApprovalPopup &&
                <ApprovalPopup
                    text="Ayarları yeniden kilitlemek istediğinizden emin misiniz ?"
                    onApprove={() => { lockConfig() }}
                    onReject={() => { }}
                    state={[showConfigLockApprovalPopup, setShowConfigLockApprovalPopup]}
                ></ApprovalPopup>}

            {<MailPopup eventBus={eventBus}></MailPopup>}
        </>
    )
}

export default PaketArkaTaraf