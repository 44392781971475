import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { faExpand } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import cihazAnlikVeri from '../cihazlar-sayfasi/data-islemleri/cihaz-anlik-veri';
import "./infopanel.css"

const FullScreenDisplay = ({ values, isim, intervalTime }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % values.length);
        }, intervalTime);

        const dateTimeInterval = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        // Cleanup interval on component unmount
        return () => {
            clearInterval(interval);
            clearInterval(dateTimeInterval);
        };
    }, [values, intervalTime]);

    const currentValue = values[currentIndex];
    const isText = typeof currentValue === "string";

    let textLines = [];
    if (isText) {
        textLines = currentValue.split("/"); // Metni iki satıra böl
    } else if (currentValue && typeof currentValue === "object") {
        const { isim, name, value, unit } = currentValue;
        textLines = [isim, name, `${value} ${unit}`];
    }

    const previousUrl = document.referrer; // Tarayıcıdan bir önceki URL'i alır
    const modifiedUrl = previousUrl ? previousUrl.replace("#panel-modal", "") : "/"; // Değişiklik yap

    const handleBackClick = () => {
        navigate(modifiedUrl); // Bir önceki sayfaya geri gider
    };

    const divRef = useRef(null);

    const handleFullScreen = () => {
        if (divRef.current) {
            if (!document.fullscreenElement) {
                divRef.current.requestFullscreen();
            } else {
                document.exitFullscreen();
            }
        }
    };

    return (
        <div ref={divRef} className='infopanel-container'>
            <div className='back-button' onClick={handleBackClick}>
                ←
            </div>
            <div className='full-screen-button'>
                <div onClick={handleFullScreen}>
                    <FontAwesomeIcon icon={faExpand} />
                </div>
            </div>
            <div className='infopanel-valueDiv'>
                {textLines.map((line, index) => (
                    <div key={index} style={{
                        fontSize: index === 0 ? '30rem' : '25rem',
                        fontWeight: index === 0 ? 'bold' : 'normal',
                        marginTop: index === 0 ? '0' : '10px',
                        textAlign: 'center'
                    }}>
                        {line}
                    </div>
                ))}
            </div>
            <div className='infopanel-isimDiv'>
                {isim}
            </div>
            <div className='datetime-container'>
                {currentDateTime.toLocaleDateString()} {currentDateTime.toLocaleTimeString()}
            </div>
        </div>
    );
};

const FullScreenPage = (props) => {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    let [data, setData] = useState([]);
    let [kanallar, setKanallar] = useState([]);
    let [isim, setIsim] = useState("");
    let [kanalIsimleri, setKanalIsimleri] = useState([]);

    useEffect(() => {
        props.konumGuncelle("infoPanel");

        const datalariOlustur = async () => {
            try {
                const e = await cihazAnlikVeri(queryParams.seriNo);
                setIsim(e?.isim || "");
                setKanalIsimleri(e?.kanalIsimleri || []);
                setData(e?.veriler || []);
                setKanallar(e?.data?.data || []);
            } catch (error) {
                console.error("Veri alma hatası:", error);
            }
        };

        datalariOlustur(); // Başlangıçta veriyi çek

        const intervalId = setInterval(() => {
            datalariOlustur(); // Veriyi her 10 saniyede bir güncelle
        }, 10000); // 10000 ms = 10 saniye

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [queryParams.seriNo]);

    const prepareDisplayData = (kanallar = [], queryParams = {}) => {
        let gosterilecekVeriler = [];
        let channels = queryParams.channels ? queryParams.channels : [];
        let texts = queryParams.texts ? queryParams.texts : [];

        if (!Array.isArray(texts)) {
            texts = [texts]; // Tek bir string varsa array'e çeviriyoruz
        }

        if (!Array.isArray(channels)) {
            channels = [channels]; // Tek bir string varsa array'e çeviriyoruz
        }

        // Kanal verilerini ekleme
        if (channels.length > 0) {
            channels.forEach((channel) => {
                const index = parseInt(channel, 10); // Kanal indeksini integer yapıyoruz
                if (kanallar[index]) {
                    kanallar[index].isim = kanalIsimleri[index - 1] || ""; // Kanal ismini ekliyoruz
                    gosterilecekVeriler.push(kanallar[index]); // Kanal verisini object olarak ekliyoruz
                }
            });
        }

        // Metinleri ekleme
        if (texts.length > 0) {
            texts.forEach((text) => {
                gosterilecekVeriler.push(text); // Her metni string olarak ekliyoruz
            });
        }

        return gosterilecekVeriler;
    };

    const intervalTime = queryParams.intervalTime ? parseInt(queryParams.intervalTime, 10) : 3000;
    const kanalVerileri = prepareDisplayData(kanallar, queryParams);

    return <FullScreenDisplay values={kanalVerileri} isim={isim} intervalTime={intervalTime} />;
};

export default FullScreenPage;
