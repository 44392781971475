import React, { useState,useRef,useEffect } from "react"
import axios from "axios"
import "./login.css"
import EnelsaLogo from "../../assets/logo-enelsa.png"
import "./activation.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import Swal from "sweetalert2"

const ActivationPage = (props)=>{
	const [referansCodes, setReferansCodes] = useState([])
	const [verificationCode, setVerificationCode] = useState("")
    const [referansCode, setReferansCode] = useState("")
    
    const inputRefs = useRef([]);

    useEffect(() => {
		referansCodes.length !== 0 &&
    	inputRefs.current[0].focus();
        referansCodeGuncelle()
    }, []);

    const handleInputChange = (event, index) => {
    	const value = event.target.value;
    	const maxLength = parseInt(event.target.getAttribute('maxLength'));

		let updatedVerificationCode = verificationCode;

		if (value === '') {
			updatedVerificationCode = updatedVerificationCode.slice(0, index);
		} else {
			updatedVerificationCode = updatedVerificationCode.slice(0, index) + value + updatedVerificationCode.slice(index + 1);
		}

		setVerificationCode(updatedVerificationCode);
       
		if (value.length >= maxLength) {
			if (index < inputRefs.current.length - 1) {
			  inputRefs.current[index + 1].focus();
			} else {
			  event.target.blur();
			}
		  } else if (index > 0 && value === '') {
			inputRefs.current[index - 1].focus();
		  }
    };

    async function referansCodeGuncelle() {
        const res = await axios.get("https://api.enelsa.com:5001/api/activationCode", {headers: {
            'authorization': 'Bearer ' + props.tokenBilgileri.token
        }})
        // console.log(res.data)
        setReferansCode(res.data)
	}

    async function verificationCodeKaydet() {
		if (verificationCode)
			{
				const res = await axios.put("https://api.enelsa.com:5001/api/activation",JSON.stringify({obj : verificationCode}), {headers: {
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + props.tokenBilgileri.token
				}})
                if (res.data == "wrong") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Doğrulama Kodu Hatası',
                        text: 'Lütfen doğrulama kodunu kontrol ediniz.',
                        color: "aliceblue",
                        position: "center",
                        background: "#505d68",
                        confirmButtonText: 'Tamam',
                        confirmButtonColor: 'rgb(70, 85, 105)',
                        width: '25em'
                      })
                } else if(res.data == "ok") {
                    window.location.replace('/anasayfa')
                }
			} 
	}

    return(
        <div className="login-popup aktivasyon-popup">
            <div style={{margin: "auto", position: "relative"}} className="deneme">
                <div id="login-screen-pencere">
                    <img src={EnelsaLogo} alt="enelsa-logo" id="login-screen-img"/>
                    <div id="login-screen-girisi-div" >
                        <div>
                            <div className="cihaz-ekle-icerik cihaz-ekle-icerik-alt" style={{ color: "#29317F"}}>
                                 <span>
                                    Referans Kodu : {referansCode}
                                </span>
                                <span>
                                    Aktivasyon Kodunu Giriniz
                                </span>
                               
                                <div className="inputContainer" style={{ color: "#29317F"}}>
                                    {Array.from({ length: 6 }, (_, index) => (
                                        <div className="cod" key={index} style={{ borderColor: "#29317F"}}>
                                            <input  style={{ color: "#29317F"}} type="text" maxLength={1} ref={(el) => (inputRefs.current[index] = el)} onChange={(event) => handleInputChange(event, index)} value={verificationCode[index] || ''}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="cihaz-ekle-icerik cihaz-ekle-icerik-alt" >
                                <button className="cihaz-ekle-button" style={{ borderColor: "#29317F", color: "#29317F", marginTop: "50px"}} onClick={()=> verificationCodeKaydet() }>KAYDET</button>
                            </div>
                            <div onClick={props.cikisYap} className="profil-popup-alt-ico butonddd" style={{justifyContent: "flex-end"}}>
                                        <FontAwesomeIcon icon={faRightFromBracket} className="profil-popup-ico"/><span className="profil-popup-ico-text">Çıkış</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActivationPage