import React, { useEffect, useState } from "react"
import "./lisans.css"
import axios from "axios"
import { faX } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AltKullaniciLisans from "./components/alt-kullanici-lisans"

const Lisans = (props) => {
	function selectMenusuOlustur(
		baslik,
		valueDegeri,
		valueDegeriFonksiyonu,
		secimleri,
		renk
	) {
		return (
			<div className="arka-taraf-birimler-input">
				<span>{baslik}</span>
				<select
					className="arka-taraf-birimler-input2"
					style={{ width: "175px", backgroundColor: renk }}
					defaultValue={"Lütfen seçiniz"}
					value={valueDegeri}
					onChange={(e) => valueDegeriFonksiyonu(e.target.value)}>
					<option value={0}>{"Lütfen Seçiniz"}</option>
					{Object.keys(secimleri).map((i) => {
						return <option value={secimleri[i]}>{secimleri[i]}</option>
					})}
				</select>
			</div>
		)
	}

	function inputOlustur(
		baslik,
		valueDegeri,
		valueDegeriFonksiyonu,
		renk,
		maxValue
	) {
		return (
			<div className="arka-taraf-birimler-input">
				<span>{baslik}</span>
				<input
					className="arka-taraf-birimler-input2"
					type="number"
					max={maxValue}
					min={1}
					style={{
						width: "169px",
						outline: "none",
						paddingLeft: "4px",
						backgroundColor: renk,
					}}
					value={valueDegeri}
					onChange={(e) => valueDegeriFonksiyonu(e.target.value)}
				/>
			</div>
		)
	}

	const [baslangic, setBaslangic] = useState(false)
	let [popupDurum, setPopupDurum] = useState(false)
	let [popupKonum, setPopupKonum] = useState(-250)
	let [popupBilgi, setPopupBilgi] = useState([])

	let [popupDurum2, setPopupDurum2] = useState(false)
	let [popupKonum2, setPopupKonum2] = useState(-250)
	let [popupDurum3, setPopupDurum3] = useState(false)
	let [popupKonum3, setPopupKonum3] = useState(-250)

	let [popupDurumOnay, setPopupDurumOnay] = useState(false)
	let [popupKonumOnay, setPopupKonumOnay] = useState(-250)
	let [popupDurumOnay2, setPopupDurumOnay2] = useState(false)
	let [popupKonumOnay2, setPopupKonumOnay2] = useState(-250)
	let [popupDurumOnay3, setPopupDurumOnay3] = useState(false)
	let [popupKonumOnay3, setPopupKonumOnay3] = useState(-250)

	let ekranGenisligi = window.innerWidth - props.menuGenisligi
	let [adminS, setAdmin] = useState()
	let [cihazS, setCihaz] = useState()
	let [abonelikS, setAbonelik] = useState()
	let [altKullanicilar2, setaltKullanicilar] = useState([])
	let [altKullanici, setaltKullanici] = useState("")
	let [adminAktarilacak, setAdminAktarilacak] = useState("1")
	let [cihazAktarilacak, setCihazAktarilacak] = useState("1")
	let [abonelikAktarilacak, setAbonelikAktarilacak] = useState("1")
	let [uyariText, setUyariText] = useState("")
	let [uyariGoster, setUyariGoster] = useState(false)

	//popuplar
	const popupGoster = () => {
		setPopupDurum(true)
		setTimeout(() => setPopupKonum(100), 100)
	}

	const popupGizle = () => {
		setPopupKonum(-250)
		setTimeout(() => setPopupDurum(false), 320)
	}

	const popupGoster2 = () => {
		setPopupDurum2(true)
		setTimeout(() => setPopupKonum2(100), 100)
	}

	const popupGizle2 = () => {
		setPopupKonum2(-250)
		setTimeout(() => setPopupDurum2(false), 320)
	}

	const popupGoster3 = () => {
		setPopupDurum3(true)
		setTimeout(() => setPopupKonum3(100), 100)
	}

	const popupGizle3 = () => {
		setPopupKonum3(-250)
		setTimeout(() => setPopupDurum3(false), 320)
	}
	const popupGosterOnay = () => {
		setPopupDurumOnay(true)
		setTimeout(() => setPopupKonumOnay(100), 100)
	}

	const popupGizleOnay = () => {
		setPopupKonumOnay(-250)
		setTimeout(() => setPopupDurumOnay(false), 320)
	}
	const popupGosterOnay2 = () => {
		setPopupDurumOnay2(true)
		setTimeout(() => setPopupKonumOnay2(100), 100)
	}

	const popupGizleOnay2 = () => {
		setPopupKonumOnay2(-250)
		setTimeout(() => setPopupDurumOnay2(false), 320)
	}

	const popupGosterOnay3 = () => {
		setPopupDurumOnay3(true)
		setTimeout(() => setPopupKonumOnay3(100), 100)
	}

	const popupGizleOnay3 = () => {
		setPopupKonumOnay3(-250)
		setTimeout(() => setPopupDurumOnay3(false), 320)
	}
	let lisansBilgileriniGuncelle = async () => {
		if (props.kullaniciBilgileri.kullaniciId !== "-") {
			let kullaniciKredileri = await axios.get(
				"https://api.enelsa.com:5001/api/lisansBilgileri/" +
					props.kullaniciBilgileri.kullaniciId,
				{
					headers: {
						authorization: "Bearer " + props.tokenBilgileri.token,
					},
				}
			)
			// // console.log(kullaniciKredileri.data)
			setAdmin(kullaniciKredileri.data.admin)
			setCihaz(kullaniciKredileri.data.cihaz)
			setAbonelik(kullaniciKredileri.data.abonelik)
		} else {
		}
	}
	if (props.kullaniciBilgileri.id != "-") {
		lisansBilgileriniGuncelle()
	}

	useEffect(() => {
		if (baslangic) return
		setBaslangic(true)
		try {
			props.kullaniciBilgileriGuncelle()
			lisansBilgileriniGuncelle()
			erisebilecegiKullanicilar()
			props.konumGuncelle("lisans")
		} catch {}
	}, [props.kullaniciBilgileri, props.kullaniciBilgileriGuncelle])

	let erisebilecegiKullanicilar = async () => {
		let a = await axios.get("https://api.enelsa.com:5001/api/altKullanicilar", {
			headers: {
				authorization: "Bearer " + props.tokenBilgileri.token,
			},
		})
		if ((a.data)["altKullanicilar"] == undefined) {
			setaltKullanicilar({})
		}else {
			setaltKullanicilar(a.data["altKullanicilar"])
		}
	}

	let adminKredisiAktar = async (tip, kullanici, miktar) => {
		// // console.log(props.kullaniciBilgileri.kullaniciId)
		if (kullanici != 0) {
			let i = tip == "admin" ? adminS : tip == "cihaz" ? cihazS : abonelikS
			if (miktar <= i) {
				let aktarilacakKullanici = {
					tip: tip,
					aktaranKullanici: props.kullaniciBilgileri.kullaniciId,
					kullanici: kullanici,
					miktar: miktar
				}
				try {
					let res = await axios.put("https://api.enelsa.com:5001/api/adminKredisiAktar", JSON.stringify(aktarilacakKullanici),
						{
							headers: {
								"Content-Type": "application/json",
								authorization: "Bearer " + props.tokenBilgileri.token,
							}
						}
					)
					// props.temaAyarDegistir()
					lisansBilgileriniGuncelle()
					popupGizle(true)
					popupGizle2(true)
					popupGizle3(true)
					popupGizleOnay(true)
					popupGizleOnay2(true)
					popupGizleOnay3(true)
					setUyariGoster(false)
					setUyariText("")
				} catch {}
			} else {
				setUyariGoster(true)
				setUyariText(
					"Girilen miktar, sahip olduğunuz " + tip + " kredisinden büyük olamaz!"
				)
			}
		} else {
			setUyariGoster(true)
			setUyariText("Lütfen aktarılacak kullanıcı seçiniz!")
		}
	}

	const ekrandaGosterilecekleriOlustur = (datalar, bildirimleriYonet) => {
		if(datalar == undefined || datalar == []) {
			return
		} else {
			let ekrandaGosterilecekler = []
			let veriler = Object.keys(datalar)
			veriler.forEach((e) => {
				ekrandaGosterilecekler.push(
					<div>
						<AltKullaniciLisans veriler={datalar[e]} tokenBilgileri={props.tokenBilgileri}/>
					</div>
				)
			})
			return ekrandaGosterilecekler
		}
	}

	return (
		<>
			<div className="lisans-box">
				<div className="lisans">
					<span>
						Admin Kredisi Miktarı : {adminS}{" "}
						<button onClick={popupGoster}>Admin Kredisi Aktar </button>
					</span>
					<span>
						Cihaz Kredisi Miktarı : {cihazS}{" "}
						<button onClick={popupGoster2}>Cihaz Kredisi Aktar </button>
					</span>
					<span>
						Abonelik Kredisi Miktarı : {abonelikS}
						<button onClick={popupGoster3}>Abonelik Kredisi Aktar </button>
					</span>
				</div>
				<div className="lisansAlt">
					{ekrandaGosterilecekleriOlustur(altKullanicilar2,props.bildirimleriYonet).map((e) => { return e })}
				</div>
			</div>
			<div className="cihaz-bilgi-kart-popup" style={{ display: popupDurum ? "flex" : "none" }}>
				<div className="cihaz-bilgi-kart-popup-cerceve" style={{ padding: "15px", width: "auto", top: popupKonum + "px", background: "linear-gradient(90deg, rgba(66,73,79,1) 0%, rgba(121,121,121,0.7763480392156863) 63%)"}}>
					<div className="cihaz-bilgi-kart-popup-icerk icerik">
						<FontAwesomeIcon icon={faX} style={{ alignSelf: "end", color: "#F7FBFF", cursor: "pointer" }} onClick={popupGizle} />
						<p style={{ marginInline: "auto", marginBottom: "10px", fontSize: "17px" }}> Admin Kredisi Aktar </p>
						{selectMenusuOlustur( "Aktarılacak Kullanıcı", altKullanici, setaltKullanici, altKullanicilar2, "#42494F" )}
						{inputOlustur( "Miktar ", adminAktarilacak, setAdminAktarilacak, "#42494F", adminS )}
						<span style={{ width: "300px", color: "#580000" }}> {uyariGoster && uyariText} </span>
						<div style={{ display: "flex" }} className="butonDiv">
							<div onClick={popupGosterOnay} className="arka-taraf-birimler-buton2">
								<span>Kaydet</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="cihaz-bilgi-kart-popup" style={{ display: popupDurumOnay ? "flex" : "none" }}>
				<div className="cihaz-bilgi-kart-popup-cerceve" style={{ padding: "15px", width: "auto", top: popupKonumOnay + "px", background: "linear-gradient(90deg, rgba(66,73,79,1) 0%, rgba(121,121,121,0.7763480392156863) 63%)"}}>
					<div className="cihaz-bilgi-kart-popup-icerk icerik">
						<FontAwesomeIcon icon={faX} style={{ alignSelf: "end", color: "#F7FBFF", cursor: "pointer" }} onClick={popupGizleOnay} />
						<p style={{ marginInline: "auto", marginBottom: "10px", fontSize: "17px" }}> Admin Kredisi Aktar </p>
						<p> <b>{altKullanici}</b> kullanıcısına <b>{adminAktarilacak}</b> adet ADMİN kredisi aktarılacaktır. Onaylıyor musunuz? </p>
						<span style={{ width: "300px", color: "#580000" }}> {uyariGoster && uyariText} </span>
						<div style={{ display: "flex" }} className="butonDiv">
							<div onClick={() => adminKredisiAktar("admin", altKullanici, adminAktarilacak)} className="arka-taraf-birimler-buton2">
								<span>Kaydet</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="cihaz-bilgi-kart-popup" style={{ display: popupDurum2 ? "flex" : "none" }}>
				<div className="cihaz-bilgi-kart-popup-cerceve" style={{padding: "15px", width: "auto", top: popupKonum2 + "px", background: "linear-gradient(90deg, rgba(66,73,79,1) 0%, rgba(121,121,121,0.7763480392156863) 63%)"}}>
					<div className="cihaz-bilgi-kart-popup-icerk icerik">
						<FontAwesomeIcon icon={faX} style={{ alignSelf: "end", color: "#F7FBFF", cursor: "pointer" }} onClick={popupGizle2}/>
						<p style={{marginInline: "auto", marginBottom: "10px", fontSize: "17px"}}>Cihaz Kredisi Aktar</p>
						{selectMenusuOlustur("Aktarılacak Kullanıcı",altKullanici,setaltKullanici,altKullanicilar2,"#42494F")}
						{inputOlustur("Miktar ",cihazAktarilacak,setCihazAktarilacak,"#42494F",cihazS)}
						<span style={{ width: "300px", color: "#580000" }}>{uyariGoster && uyariText}</span>
						<div style={{ display: "flex" }} className="butonDiv">
							<div onClick={popupGosterOnay2} className="arka-taraf-birimler-buton2">
								<span>Kaydet</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="cihaz-bilgi-kart-popup" style={{ display: popupDurumOnay2 ? "flex" : "none" }}>
				<div className="cihaz-bilgi-kart-popup-cerceve" style={{ padding: "15px", width: "auto", top: popupKonumOnay2 + "px", background: "linear-gradient(90deg, rgba(66,73,79,1) 0%, rgba(121,121,121,0.7763480392156863) 63%)"}}>
					<div className="cihaz-bilgi-kart-popup-icerk icerik">
						<FontAwesomeIcon icon={faX} style={{ alignSelf: "end", color: "#F7FBFF", cursor: "pointer" }} onClick={popupGizleOnay2} />
						<p style={{ marginInline: "auto", marginBottom: "10px", fontSize: "17px" }}> Cihaz Kredisi Aktar </p>
						<p> <b>{altKullanici}</b> kullanıcısına <b>{cihazAktarilacak}</b> adet CİHAZ kredisi aktarılacaktır. Onaylıyor musunuz? </p>
						<span style={{ width: "300px", color: "#580000" }}> {uyariGoster && uyariText} </span>
						<div style={{ display: "flex" }} className="butonDiv">
							<div onClick={() => adminKredisiAktar("cihaz", altKullanici, cihazAktarilacak)} className="arka-taraf-birimler-buton2">
								<span>Kaydet</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="cihaz-bilgi-kart-popup" style={{ display: popupDurum3 ? "flex" : "none" }}>
				<div className="cihaz-bilgi-kart-popup-cerceve" style={{padding: "15px", width: "auto", top: popupKonum3 + "px", background:"linear-gradient(90deg, rgba(66,73,79,1) 0%, rgba(121,121,121,0.7763480392156863) 63%)"}}>
					<div className="cihaz-bilgi-kart-popup-icerk icerik">
						<FontAwesomeIcon icon={faX} style={{ alignSelf: "end", color: "#F7FBFF", cursor: "pointer" }} onClick={popupGizle3}/>
						<p style={{marginInline: "auto",marginBottom: "10px",fontSize: "17px"}}> Abonelik Kredisi Aktar </p>
						{selectMenusuOlustur("Aktarılacak Kullanıcı",altKullanici,setaltKullanici,altKullanicilar2,"#42494F")}
						{inputOlustur("Miktar ",abonelikAktarilacak,setAbonelikAktarilacak,"#42494F",abonelikS)}
						<span style={{ width: "300px", color: "#580000" }}> {uyariGoster && uyariText} </span>
						<div style={{ display: "flex" }} className="butonDiv">
							<div onClick={popupGosterOnay3} className="arka-taraf-birimler-buton2">
								<span>Kaydet</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="cihaz-bilgi-kart-popup" style={{ display: popupDurumOnay3 ? "flex" : "none" }}>
				<div className="cihaz-bilgi-kart-popup-cerceve" style={{ padding: "15px", width: "auto", top: popupKonumOnay3 + "px", background: "linear-gradient(90deg, rgba(66,73,79,1) 0%, rgba(121,121,121,0.7763480392156863) 63%)"}}>
					<div className="cihaz-bilgi-kart-popup-icerk icerik">
						<FontAwesomeIcon icon={faX} style={{ alignSelf: "end", color: "#F7FBFF", cursor: "pointer" }} onClick={popupGizleOnay3} />
						<p style={{ marginInline: "auto", marginBottom: "10px", fontSize: "17px" }}> Abonelik Kredisi Aktar </p>
						<p> <b>{altKullanici}</b> kullanıcısına <b>{abonelikAktarilacak}</b> adet ABONELİK kredisi aktarılacaktır. Onaylıyor musunuz? </p>
						<span style={{ width: "300px", color: "#580000" }}> {uyariGoster && uyariText} </span>
						<div style={{ display: "flex" }} className="butonDiv">
							<div onClick={() => adminKredisiAktar("abonelik", altKullanici, abonelikAktarilacak)} className="arka-taraf-birimler-buton2">
								<span>Kaydet</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Lisans
