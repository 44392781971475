import React, { useEffect, useState } from "react"
import "./kullanici-ekle.css"
import axios from "axios"
import md5 from "md5-hash"
import { faX } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import { yetkiler } from "./utils/kullanici-yetkileri"
import MultiSelect from "./utils/multiSelect"

const KullaniciEkle = (props) => {

	const [checkedState, setCheckedState] = useState(
		new Array(yetkiler.length).fill(false)
	  );
	  const handleOnChange = (position) => {
		const updatedCheckedState = checkedState.map((item, index) =>
		  index === position ? !item : item
		);
	
		setCheckedState(updatedCheckedState);
	};

	let [popupDurum2, setPopupDurum2] = useState(false)
	let [popupKonum2, setPopupKonum2] = useState(-250)

	let [popupDurumBayiPlasiyer, setPopupDurumBayiPlasiyer] = useState(false)
	let [popupKonumBayiPlasiyer, setPopupKonumBayiPlasiyer] = useState(-250)

	let [popupDurumUserAdmin, setPopupDurumUserAdmin] = useState(false)
	let [popupKonumUserAdmin, setPopupKonumUserAdmin] = useState(-250)

	let [popupDurumUser, setPopupDurumUser] = useState(false)
	let [popupKonumUser, setPopupKonumUser] = useState(-250)

	let [popupDurumGrup, setPopupDurumGrup] = useState(false)
	let [popupKonumGrup, setPopupKonumGrup] = useState(-250)

	let [popupDurumGrupKullanici, setPopupDurumGrupKullanici] = useState(false)
	let [popupKonumGrupKullanici, setPopupKonumGrupKullanici] = useState(-250)
	
	const [baslangic, setBaslangic] = useState(false)
	let [isShown, setIsShown] = useState(false)
	let [uyariText, setUyariText] = useState("")
	let [uyariTextRenk, setUyariTextRenk] = useState("aliceblue")

	let [isShownBP, setIsShownBP] = useState(false)
	let [uyariTextBP, setUyariTextBP] = useState("")
	let [uyariTextRenkBP, setUyariTextRenkBP] = useState("aliceblue")

	let [isShownUV, setIsShownUV] = useState(false)
	let [uyariTextUV, setUyariTextUV] = useState("")
	let [uyariTextRenkUV, setUyariTextRenkUV] = useState("aliceblue")

	let [isShownGrup, setIsShownGrup] = useState(false)
	let [uyariTextGrup, setUyariTextGrup] = useState("")
	let [uyariTextRenkGrup, setUyariTextRenkGrup] = useState("aliceblue")

	let [isShownGrupKullanici, setIsShownGrupKullanici] = useState(false)
	let [uyariTextGrupKullanici, setUyariTextGrupKullanici] = useState("")
	let [uyariTextRenkGrupKullanici, setUyariTextRenkGrupKullanici] = useState("aliceblue")

	let [bayiAdi, setBayiAdi] = useState("")
	let [mailAdresi, setMailAdresi] = useState("")
	let [telefonNumarasi, setTelefonNumarasi] = useState("")

	let [plasiyerBayileri, setPlasiyerBayileri] = useState([])
	let [bayiAdminKullaniciAdi, setBayiAdminKullaniciAdi] = useState("")
	let [bayiAdminSifre, setBayiAdminSifre] = useState("")
	let [bayiAdminAd, setBayiAdminAd] = useState("")
	let [bayiAdminMailAdresi, setBayiAdminMailAdresi] = useState("")
	let [bayiAdminTelefonNumarasi, setBayiAdminTelefonNumarasi] = useState("")
	
	let [bayiPlasiyerKullaniciAdi, setBayiPlasiyerKullaniciAdi] = useState("")
	let [bayiPlasiyerSifre, setBayiPlasiyerSifre] = useState("")
	let [bayiPlasiyerAd, setBayiPlasiyerAd] = useState("")
	let [bayiPlasiyerMailAdresi, setBayiPlasiyerMailAdresi] = useState("")
	let [bayiPlasiyerTelefonNumarasi, setBayiPlasiyerTelefonNumarasi] = useState("")

	let [networkAdi, setNetworkAdi] = useState("")
	let [userAdminKullaniciAdi, setUserAdminKullaniciAdi] = useState("")
	let [userAdminSifre, setUserAdminSifre] = useState("")
	let [userAdminAd, setUserAdminAd] = useState("")
	let [userAdminMailAdresi, setUserAdminMailAdresi] = useState("")
	let [userAdminTelefonNumarasi, setUserAdminTelefonNumarasi] = useState("")

	let [userKullaniciAdi, setUserKullaniciAdi] = useState("")
	let [userSifre, setUserSifre] = useState("")
	let [userAd, setUserAd] = useState("")
	let [userMailAdresi, setUserMailAdresi] = useState("")
	let [userTelefonNumarasi, setUserTelefonNumarasi] = useState("")
	
	let [grupAdi, setGrupAdi] = useState("")
	let [grupYetkili, setGrupYetkili] = useState([])
	let [grupYetkiliList, setGrupYetkiliList] = useState([])
	let [grupList, setGrupList] = useState([])

	let [grupKullaniciKullaniciAdi, setGrupKullaniciKullaniciAdi] = useState("")
	let [grupKullaniciSifre, setGrupKullaniciSifre] = useState("")
	let [grupKullaniciAd, setGrupKullaniciAd] = useState("")
	let [grupKullaniciMailAdresi, setGrupKullaniciMailAdresi] = useState("")
	let [grupKullaniciTelefonNumarasi, setGrupKullaniciTelefonNumarasi] = useState("")
	let [kullaniciGrubu, setKullaniciGrubu] = useState("")
	let [gruplar, setGruplar] = useState([])

	let [pencereAcik, setPencereAcik] = useState(false)
	let [pencereAcik2, setPencereAcik2] = useState(false)
	let [pencereAcik3, setPencereAcik3] = useState(false)
	let [pencereAcik4, setPencereAcik4] = useState(false)
	let [acikKenUzunlugu, setAcikKenUzunlugu] = useState(320)


	const popupGoster2 = () => {
		setIsShown(false)
		setPopupDurum2(true)
		setTimeout(() => setPopupKonum2(100), 100)
	}
	const popupGizle2 = () => {
		setPopupKonum2(-250)
		setTimeout(() => setPopupDurum2(false), 320)
	}

	const popupGosterBayiPlasiyer = () => {
		setIsShownBP(false)
		setPopupDurumBayiPlasiyer(true)
		setTimeout(() => setPopupKonumBayiPlasiyer(100), 100)
	}
	const popupGizleBayiPlasiyer = () => {
		setPopupKonumBayiPlasiyer(-250)
		setTimeout(() => setPopupDurumBayiPlasiyer(false), 320)
	}

	const popupGosterUserAdmin = () => {
		setIsShownUV(false)
		setPopupDurumUserAdmin(true)
		setTimeout(() => setPopupKonumUserAdmin(100), 100)
	}
	const popupGizleUserAdmin = () => {
		setPopupKonumUserAdmin(-250)
		setTimeout(() => setPopupDurumUserAdmin(false), 320)
	}
	const popupGosterUser = () => {
		setIsShownUV(false)
		setPopupDurumUser(true)
		setTimeout(() => setPopupKonumUser(100), 100)
	}
	const popupGizleUser = () => {
		setPopupKonumUser(-250)
		setTimeout(() => setPopupDurumUser(false), 320)
	}
	const popupGosterGrup = () => {
		setIsShownGrup(false)
		setPopupDurumGrup(true)
		setTimeout(() => setPopupKonumGrup(100), 100)
		const selectedOption = JSON.parse(localStorage.getItem('selectedOption'));
		let keys = Object.keys(selectedOption)
                let yetkiliListx = []
                keys.forEach((i) => {
                    yetkiliListx.push(selectedOption[keys[i]]["value"])
                })
		setGrupYetkili(yetkiliListx)
	}
	const popupGizleGrup = () => {
		setPopupKonumGrup(-250)
		setTimeout(() => setPopupDurumGrup(false), 320)
	}
	const popupGosterGrupKullanici = () => {
		setIsShownGrupKullanici(false)
		setPopupDurumGrupKullanici(true)
		setTimeout(() => setPopupKonumGrupKullanici(100), 100)
	}
	const popupGizleGrupKullanici = () => {
		setPopupKonumGrupKullanici(-250)
		setTimeout(() => setPopupDurumGrupKullanici(false), 320)
	}
	function selectMenusuOlustur(baslik,valueDegeri,valueDegeriFonksiyonu,secimleri,renk) {
		return (
			<div className="arka-taraf-birimler-input">
				<span>{baslik}</span>
				<select
					className="arka-taraf-birimler-input2"
					style={{ width: "175px", color: renk, marginLeft: "10px" }}
					defaultValue={"Lütfen seçiniz"}
					value={valueDegeri}
					onChange={(e) => valueDegeriFonksiyonu(e.target.value)}>
					<option value={0}>{"Lütfen Seçiniz"}</option>
					{Object.keys(secimleri).map((i) => {
						return <option value={secimleri[i]}>{secimleri[i]}</option>
					})}
				</select>
			</div>
		)
	}
	
	function multiSelectMenusuOlustur(baslik,valueDegeri,valueDegeriFonksiyonu,secimleri,renk) {
		return (
			<div className="arka-taraf-birimler-input" style={{display: "flex", alignItems: "center"}}>
				<span>{baslik}</span>
				{<MultiSelect grupYetkiliList={grupYetkiliList} grupList={grupList}/>}
			</div>
		)
	}
	function inputOlustur(baslik,valueDegeri,valueDegeriFonksiyonu,renk,placeholder) {
		return (
			<div className="arka-taraf-birimler-input">
				<span>{baslik}</span>
				<input
					className="arka-taraf-birimler-input2"
					type="text"
					style={{width: "169px",outline: "none",paddingLeft: "4px",marginLeft: "10px",color: renk,}}
					value={valueDegeri}
					placeholder={placeholder}
					onChange={(e) => valueDegeriFonksiyonu(e.target.value)}
				/>
			</div>
		)
	}

	function telefonInputOlustur(baslik,valueDegeri,valueDegeriFonksiyonu,renk) {
		return (
			<div className="arka-taraf-birimler-input">
				<span>{baslik}</span>
				<PhoneInput 
					international
					countryCallingCodeEditable={false}
					defaultCountry="TR" 
					className="arka-taraf-birimler-input2" 
					style={{width: "169px",paddingLeft: "4px",marginLeft: "10px",color: renk}} 
					value={valueDegeri} 
					onChange={valueDegeriFonksiyonu}
					error={valueDegeri ? (isValidPhoneNumber(valueDegeri) ? undefined : 'Invalid phone number') : 'Phone number required'}/>
			</div>
		)
	}

	let yetkiliListesiGuncelle = async () => {
        try {
            let yetkiliListesi = await axios.get("https://api.enelsa.com:5001/api/altKullanicilar", {
                headers: {
                    'authorization': 'Bearer ' + props.tokenBilgileri.token
                }
            })
			if (yetkiliListesi.data["gruplar"] != undefined){
				setGrupList(yetkiliListesi.data["gruplar"])
				setGruplar(yetkiliListesi.data["gruplar"])
            	setGrupYetkiliList(yetkiliListesi.data["altKullanicilar"])
			} else {
            	setGrupList([])
            	setGruplar([])
			} 

        } catch {
			// // console.log("kullaniciBilgileri.data")
        }
    }

	let bayiListesiGuncelle = async () => {
        try {
            let bayiListesi = await axios.get("https://api.enelsa.com:5001/api/bayiListesi", {
                headers: {
                    'authorization': 'Bearer ' + props.tokenBilgileri.token
                }
            })
			if (bayiListesi != "") {
            	setPlasiyerBayileri(bayiListesi.data)
			} else {
            	setPlasiyerBayileri([])
			} 

        } catch {
            // // console.log("kullaniciBilgileri.data")
            //window.location.replace('/anasayfa')
        }
    }

	function bayiOlustur() {
		return (
			<div className="arka-taraf-birimler-cerceve2">
				<div style={{ height: pencereAcik ? acikKenUzunlugu : 30 + "px" }} className="arka-taraf-birimler-alt-cerceve">
					<div className="arka-taraf-birimler-ust-cerceve2" onClick={() => props.kullaniciBilgileri.kullaniciTipi != "Factory-Plasiyer" ? setPencereAcik(!pencereAcik) : setPencereAcik(true)} style={{ backgroundColor: props.renk2, transition: "all 0.5s", marginBottom: "10px", justifyContent: props.kullaniciBilgileri.kullaniciTipi != "Factory-Plasiyer" ? "space-between" : "center" }}>
						<span style={{ marginLeft: "10px" }}></span>
						<span style={{ textAlign: "center" }}>Yeni Bayi Ağı Oluştur</span>
						{props.kullaniciBilgileri.kullaniciTipi != "Factory-Plasiyer" && <span style={{ rotate: (pencereAcik ? -1 : 1) * 90 + "deg", marginRight: "10px" }}>{">"}</span>}
					</div>
					<div className="arka-taraf-birimler-icerik" style={{ height: "auto" }}>
						<div className="kullanici-ekleme bayi-ekleme">
							<div className="kullanici-ekleme-box bayi-ekleme-box">
								<span style={{textAlign: "center", margin: "10px"}}>Bayi</span>
								{inputOlustur("Adı", bayiAdi, setBayiAdi, "#42494f")} 
								{inputOlustur("Mail Adresi", mailAdresi, setMailAdresi, "#42494f")}
								{telefonInputOlustur("Telefon Numarası", telefonNumarasi, setTelefonNumarasi, "#42494f")}
								<span style={{textAlign: "center", margin: "10px"}}>Admin</span> 
								{inputOlustur("Kullanıcı Adı",bayiAdminKullaniciAdi, setBayiAdminKullaniciAdi, "#42494f", "a_bayiadi")}
								{inputOlustur("Şifre",bayiAdminSifre, setBayiAdminSifre, "#42494f")}
								{inputOlustur("İsim Soyisim",bayiAdminAd, setBayiAdminAd, "#42494f")}
								{inputOlustur("Mail Adresi", bayiAdminMailAdresi, setBayiAdminMailAdresi, "#42494f")}
								{telefonInputOlustur("Telefon Numarası", bayiAdminTelefonNumarasi, setBayiAdminTelefonNumarasi, "#42494f")}
							</div>
							<div onClick={popupGoster2} style={{ cursor: "pointer", bottom: "0" }} >
								<span>Kaydet</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	function bayiPlasiyerOlustur() {
		return (
			<div className="arka-taraf-birimler-cerceve2">
				<div style={{ height: pencereAcik3 ? acikKenUzunlugu : 30 + "px" }} className="arka-taraf-birimler-alt-cerceve">
					<div className="arka-taraf-birimler-ust-cerceve2" onClick={() => props.kullaniciBilgileri.kullaniciTipi != "Bayi-Admin" ? setPencereAcik3(!pencereAcik) : setPencereAcik3(true)} style={{ backgroundColor: props.renk2, transition: "all 0.5s", justifyContent: props.kullaniciBilgileri.kullaniciTipi != "Bayi-Admin" ? "space-between" : "center" }}>
						<span style={{ marginLeft: "10px" }}></span>
						<span style={{ textAlign: "center" }}>Yeni Plasiyer Oluştur</span>
						{props.kullaniciBilgileri.kullaniciTipi != "Bayi-Admin" && <span style={{ rotate: (pencereAcik ? -1 : 1) * 90 + "deg", marginRight: "10px" }}>{">"}</span>}
					</div>
					<div className="arka-taraf-birimler-icerik" style={{ height: "auto" }}>
						<div className="kullanici-ekleme bayi-plasiyer-ekleme">
							<div className="kullanici-ekleme-box bayi-plasiyer-ekleme-box">
								{inputOlustur("Kullanıcı Adı",bayiPlasiyerKullaniciAdi, setBayiPlasiyerKullaniciAdi, "#42494f", "p_plasiyerisim")}
								{inputOlustur("Şifre",bayiPlasiyerSifre, setBayiPlasiyerSifre, "#42494f")}
								{inputOlustur("İsim Soyisim",bayiPlasiyerAd, setBayiPlasiyerAd, "#42494f")}
								{inputOlustur("Mail Adresi", bayiPlasiyerMailAdresi, setBayiPlasiyerMailAdresi, "#42494f")}
								{telefonInputOlustur("Telefon Numarası", bayiPlasiyerTelefonNumarasi, setBayiPlasiyerTelefonNumarasi, "#42494f")}
							</div>
							<div onClick={popupGosterBayiPlasiyer} style={{ cursor: "pointer", bottom: "0" }}>
								<span>Kaydet</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	function kullaniciNetworkOlustur() {
		return (
			<div className="arka-taraf-birimler-cerceve2">
				<div style={{ height: pencereAcik2 ? acikKenUzunlugu : 30 + "px" }} className="arka-taraf-birimler-alt-cerceve">
					<div className="arka-taraf-birimler-ust-cerceve2" onClick={() => setPencereAcik2(!pencereAcik2)} style={{ backgroundColor: props.renk2, transition: "all 0.5s", marginBottom: "10px", justifyContent: "space-between"}}>
						<span style={{ marginLeft: "10px" }}></span>
						<span style={{ textAlign: "center" }}>Yeni Kullanıcı Ağı Oluştur</span>
						<span style={{ rotate: (pencereAcik2 ? -1 : 1) * 90 + "deg", marginRight: "10px" }}>{">"}</span>
					</div>
					<div className="arka-taraf-birimler-icerik" style={{ height: "auto" }}>
						<div className="kullanici-ekleme kullanici-network-ekleme">
							<div className="kullanici-ekleme-box kullanici-network-ekleme-box">
								{inputOlustur("Ağ Adı", networkAdi, setNetworkAdi, "#42494f")} 
								<span style={{textAlign: "center", margin: "10px"}}>Admin</span> 
								{inputOlustur("Kullanıcı Adı", userAdminKullaniciAdi, setUserAdminKullaniciAdi, "#42494f", "a_agadi")}
								{inputOlustur("Şifre", userAdminSifre, setUserAdminSifre, "#42494f")}
								{inputOlustur("İsim Soyisim", userAdminAd, setUserAdminAd, "#42494f")}
								{inputOlustur("Mail Adresi", userAdminMailAdresi, setUserAdminMailAdresi, "#42494f")}
								{telefonInputOlustur("Telefon Numarası", userAdminTelefonNumarasi, setUserAdminTelefonNumarasi, "#42494f")}
							</div>
							<div onClick={popupGosterUserAdmin} style={{ cursor: "pointer", bottom: "0" }} >
								<span>Kaydet</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	function kullaniciOlustur() {
		return (
			<div className="arka-taraf-birimler-cerceve2">
				<div style={{ height: pencereAcik3 ? acikKenUzunlugu : 30 + "px" }} className="arka-taraf-birimler-alt-cerceve">
					<div className="arka-taraf-birimler-ust-cerceve2" onClick={() => setPencereAcik3(!pencereAcik3)} style={{ backgroundColor: props.renk2, transition: "all 0.5s", marginBottom: "10px", justifyContent: "space-between"}}>
						<span style={{ marginLeft: "10px" }}></span>
						<span style={{ textAlign: "center" }}>Yeni Kullanıcı Oluştur</span>
						<span style={{ rotate: (pencereAcik3 ? -1 : 1) * 90 + "deg", marginRight: "10px" }}>{">"}</span>
					</div>
					<div className="arka-taraf-birimler-icerik" style={{ height: "auto" }}>
						<div className="kullanici-ekleme son-kullanici-ekleme">
							<div className="kullanici-ekleme-box son-kullanici-ekleme-box">
								{inputOlustur("Kullanıcı Adı", userKullaniciAdi, setUserKullaniciAdi, "#42494f")}
								{inputOlustur("Şifre", userSifre, setUserSifre, "#42494f")}
								{inputOlustur("İsim Soyisim", userAd, setUserAd, "#42494f")}
								{inputOlustur("Mail Adresi", userMailAdresi, setUserMailAdresi, "#42494f")}
								{telefonInputOlustur("Telefon Numarası", userTelefonNumarasi, setUserTelefonNumarasi, "#42494f")}
							</div>
							<div onClick={popupGosterUser} style={{ cursor: "pointer", bottom: "0" }} >
								<span>Kaydet</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	function grupOlustur() {
		let yetkiliList = grupList != undefined ? [...grupYetkiliList, ...grupList] : grupYetkiliList
		localStorage.setItem("selectedOption", JSON.stringify(" "))
		return (
			<>
			<div className="arka-taraf-birimler-cerceve2">
				<div style={{ height: pencereAcik ? 700 : 30 + "px" }} className="arka-taraf-birimler-alt-cerceve">
					<div className="arka-taraf-birimler-ust-cerceve2" onClick={() =>setPencereAcik(!pencereAcik)} style={{ transition: "all 0.5s", justifyContent: "space-between" }}>
						<span style={{ marginLeft: "10px" }}></span>
						<span style={{ textAlign: "center" }}>Yeni Grup Oluştur</span>
						<span style={{ rotate: (pencereAcik ? -1 : 1) * 90 + "deg", marginRight: "10px" }}>{">"}</span>
					</div>
					<div className="arka-taraf-birimler-icerik" style={{ height: "auto" }}>
						<div className="kullanici-ekleme bayi-ekleme">
							<div className="kullanici-ekleme-box bayi-ekleme-box">
								{inputOlustur("Grup Adı", grupAdi, setGrupAdi, "#42494f")}
								{multiSelectMenusuOlustur("Grup Yetkilisi")}
								<div className="arka-taraf-birimler-cerceve3">
									<div style={{ height: pencereAcik2 ? 200 : 30 + "px", overflow: pencereAcik2 ? "auto" : "hidden" }} className="arka-taraf-birimler-alt-cerceve">
										<div className="arka-taraf-birimler-ust-cerceve3" onClick={() =>setPencereAcik2(!pencereAcik2)} style={{ transition: "all 0.5s", marginBottom: "10px", justifyContent: "space-between" }}>
											<span style={{ marginLeft: "10px" }}></span>
											<span style={{ textAlign: "center" }}>Grup Yetkilerini Ayarla</span>
											<span style={{ rotate: (pencereAcik2 ? -1 : 1) * 90 + "deg", marginRight: "10px" }}>{">"}</span>
										</div>
										<div className="arka-taraf-birimler-icerik" style={{ height: "auto" }}>
											{yetkiler.map(({ name, value }, index) => {
												return (
													<div className="toppings-list-item">
														<div className="left-section">
														<input
															type="checkbox"
															id={`custom-checkbox-${index}`}
															name={name}
															value={value}
															checked={checkedState[index]}
															onChange={() => handleOnChange(index)}
														/>
														<label htmlFor={`custom-checkbox-${index}`}>{name}</label>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</div>
							<div onClick={() => popupGosterGrup()} style={{ cursor: "pointer", bottom: "0" }} >
								<span>Kaydet</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			</>
		)
	}

	function grupKullanicisiOlustur() {
		return (
			<div className="arka-taraf-birimler-cerceve2">
				<div style={{ height: pencereAcik4 ? acikKenUzunlugu : 30 + "px" }} className="arka-taraf-birimler-alt-cerceve">
					<div className="arka-taraf-birimler-ust-cerceve2" onClick={() => setPencereAcik4(!pencereAcik4)} style={{ backgroundColor: props.renk2, transition: "all 0.5s", marginBottom: "10px", justifyContent: "space-between"}}>
						<span style={{ marginLeft: "10px" }}></span>
						<span style={{ textAlign: "center" }}>Yeni Grup Kullanıcısı Oluştur</span>
						<span style={{ rotate: (pencereAcik4 ? -1 : 1) * 90 + "deg", marginRight: "10px" }}>{">"}</span>
					</div>
					<div className="arka-taraf-birimler-icerik" style={{ height: "auto" }}>
						<div className="kullanici-ekleme son-kullanici-ekleme">
							<div className="kullanici-ekleme-box son-kullanici-ekleme-box">
								{selectMenusuOlustur("Grubu", kullaniciGrubu, setKullaniciGrubu, gruplar, "#42494f")}
								{inputOlustur("Kullanıcı Adı", grupKullaniciKullaniciAdi, setGrupKullaniciKullaniciAdi, "#42494f")}
								{inputOlustur("Şifre", grupKullaniciSifre, setGrupKullaniciSifre, "#42494f")}
								{inputOlustur("İsim Soyisim", grupKullaniciAd, setGrupKullaniciAd, "#42494f")}
								{inputOlustur("Mail Adresi", grupKullaniciMailAdresi, setGrupKullaniciMailAdresi, "#42494f")}
								{telefonInputOlustur("Telefon Numarası", grupKullaniciTelefonNumarasi, setGrupKullaniciTelefonNumarasi, "#42494f")}
							</div>
							<div onClick={popupGosterGrupKullanici} style={{ cursor: "pointer", bottom: "0" }} >
								<span>Kaydet</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	function listOlustur(datalar,yetkiler) {
		let ekrandaGosterilecekler = []
		let keys = Object.keys(datalar)
		let values = Object.values(datalar)
		let yetkilerValues = Object.values(yetkiler[0].split(","))

		keys.forEach((e) => {
			ekrandaGosterilecekler.push(
			<div style={{margin:"3px 5px 0 5px", display: "flex", alignItems: "center"}}>
				{yetkilerValues[e] == "true" && values[e] }
			</div>
			)
		})
		return ekrandaGosterilecekler
	} 

	async function bayiKaydet(datalar, bildirimleriYonet) {
		const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
		let gonderilecekData = {
			user_Id: props.kullaniciBilgileri.kullaniciId,
			bayiAdi: datalar.bayiAdi,
			mailAdresi: datalar.mailAdresi,
			telefonNumarasi: datalar.telefonNumarasi,
			userName : datalar.userName,
			password: datalar.password,
			nameSurname: datalar.nameSurname,
			email: datalar.email,
			phone: datalar.phone
		}
		if (bayiAdi != "" && mailAdresi != "") {
			if(reg.test(mailAdresi) == true) {
				try {
				const res = await axios.put('https://api.enelsa.com:5001/api/bayiOlustur', JSON.stringify(gonderilecekData), {
				headers: {
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + props.tokenBilgileri.token
				}
				})
					if (res.data["text"] == "duplicate") {
						setUyariTextRenk("yellow")
						setUyariText("Bayi adı zaten kayıtlı!")
						setIsShown(true)
					} else {
						let yeniBildirim = { mesaj: "Yeni Bayi", bildirim: 1 }
						props.bildirimleriYonet("kanalRenk", yeniBildirim)
						setBayiAdi("")
						setMailAdresi("")
						setIsShown(false)
						setTelefonNumarasi("")
						setBayiAdminAd("")
						setBayiAdminKullaniciAdi("")
						setBayiAdminSifre("")
						setBayiAdminMailAdresi("")
						setBayiAdminTelefonNumarasi("")
						popupGizle2()
					}
				} catch {
					let yeniBildirim = { mesaj: "Yeni Bayi", bildirim: 0 }
					props.bildirimleriYonet("kanalRenk", yeniBildirim)
				}
			} else {
				setUyariTextRenk("orange")
				setUyariText("Lütfen geçerli bir mail adresi girin!")
				setIsShown(true)
			}
		} else {
				setUyariTextRenk("red")
				setUyariText("Bayi adı veya mail adresi boş olamaz!")
				setIsShown(true)
		}
	}
	async function bayiPlasiyerKaydet(datalar, bildirimleriYonet) {
		const reg = /^(p_)[a-zA-Z0-9.]*$/
		const regMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
		let gonderilecekData = {
			type: "bayiPlasiyer",
			userName: datalar.bayiPlasiyerKullaniciAdi,
			password: datalar.bayiPlasiyerSifre,
			nameSurname: datalar.bayiPlasiyerAd,
			email: datalar.email,
			phone: datalar.phone
		}
		if(datalar.bayiPlasiyerKullaniciAdi != "" && datalar.bayiPlasiyerSifre != "" && datalar.bayiPlasiyerAd != "") {
			if (reg.test(datalar.bayiPlasiyerKullaniciAdi) == true) {
				if(datalar.email != null && regMail.test(datalar.email)) {
					try {
					const res = await axios.put('https://api.enelsa.com:5001/api/userOlustur', JSON.stringify(gonderilecekData), {
					headers: {
						'Content-Type': 'application/json',
						'authorization': 'Bearer ' + props.tokenBilgileri.token
					}
				}) 
				if(res.data["text"] == "duplicate") {
					setUyariTextRenkBP("yellow")
					setUyariTextBP("Bu kullanıcı adı zaten mevcut! Lütfen yeni bir kullanıcı adı girin.")
					setIsShownBP(true)
				} else {
					let yeniBildirim = { mesaj: "Yeni Bayi Plasiyeri", bildirim: 1 }
					props.bildirimleriYonet("kanalRenk", yeniBildirim)
					setBayiPlasiyerAd("")
					setBayiPlasiyerKullaniciAdi("")
					setBayiPlasiyerSifre("")
					setBayiPlasiyerMailAdresi("")
					setBayiPlasiyerTelefonNumarasi("")
					popupGizleBayiPlasiyer()
				}
				} catch {
					let yeniBildirim = { mesaj: "Yeni Bayi Plasiyeri", bildirim: 0 }
					props.bildirimleriYonet("kanalRenk", yeniBildirim)
				}
				}
				else {
					setUyariTextRenkBP("red")
					setUyariTextBP("Lütfen geçerli bir mail adresi giriniz.")
					setIsShownBP(true)
				}
			} else {
				setUyariTextRenkBP("red")
				setUyariTextBP("Lütfen kullanıcı adını 'p_plasiyerismi' olacak şekilde belirleyin!")
				setIsShownBP(true)
			}
		} else {
			setUyariTextRenkBP("red")
			setUyariTextBP("Lütfen tüm alanları doldurun!")
			setIsShownBP(true)
		}
	}
	async function userAdminKaydet(datalar, bildirimleriYonet) {
		const reg = /^(a_)[a-zA-Z0-9.]*$/
		const regMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
		let gonderilecekData = {
			type: "userAdmin",
			userName: datalar.userAdminKullaniciAdi,
			password: datalar.userAdminSifre,
			nameSurname: datalar.userAdminAd,
			email: datalar.userAdminMailAdresi,
			phone: datalar.userAdminTelefonNumarasi,
			networkAdi: datalar.networkAdi
		}
		if(datalar.userAdminKullaniciAdi != "" && datalar.userAdminSifre != "" && datalar.userAdminAd != "" && datalar.networkAdi != "") {
			if (reg.test(datalar.userAdminKullaniciAdi) == true) {
				if(datalar.userAdminMailAdresi != null && regMail.test(datalar.userAdminMailAdresi)) {
					try {
					const res = await axios.put('https://api.enelsa.com:5001/api/userOlustur', JSON.stringify(gonderilecekData), {
					headers: {
						'Content-Type': 'application/json',
						'authorization': 'Bearer ' + props.tokenBilgileri.token
					}
				}) 
				if(res.data["text"] == "duplicate") {
					setUyariTextRenkUV("yellow")
					setUyariTextUV("Bu kullanıcı adı zaten mevcut! Lütfen yeni bir kullanıcı adı girin.")
					setIsShownUV(true)
				} else {
					let yeniBildirim = { mesaj: "Yeni Kullanıcı Ağı", bildirim: 1 }
					props.bildirimleriYonet("kanalRenk", yeniBildirim)
					setUserAdminAd("")
					setUserAdminKullaniciAdi("")
					setUserAdminSifre("")
					setUserAdminMailAdresi("")
					setUserAdminTelefonNumarasi("")
					popupGizleUserAdmin()
				}
				} catch {
					let yeniBildirim = { mesaj: "Yeni Kullanıcı Ağı", bildirim: 0 }
					props.bildirimleriYonet("kanalRenk", yeniBildirim)
				}
				}
				else {
					setUyariTextRenkUV("red")
					setUyariTextUV("Lütfen geçerli bir mail adresi giriniz.")
					setIsShownUV(true)
				}
			} else {
				setUyariTextRenkUV("red")
				setUyariTextUV("Lütfen kullanıcı adını 'a_Adminismi' olacak şekilde belirleyin!")
				setIsShownUV(true)
			}
		} else {
			setUyariTextRenkUV("red")
			setUyariTextUV("Lütfen tüm alanları doldurun!")
			setIsShownUV(true)
		}
	}
	async function userKaydet(datalar, bildirimleriYonet) {
		const regMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
		let gonderilecekData = {
			type: "user",
			userName: datalar.userKullaniciAdi,
			password: datalar.userSifre,
			nameSurname: datalar.userAd,
			email: datalar.userMailAdresi,
			phone: datalar.userTelefonNumarasi
		}
		if(datalar.userKullaniciAdi != "" && datalar.userSifre != "" && datalar.userAd != "") {
			if(datalar.userMailAdresi != null && regMail.test(datalar.userMailAdresi)) {
				try {
				const res = await axios.put('https://api.enelsa.com:5001/api/userOlustur', JSON.stringify(gonderilecekData), {
				headers: {
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + props.tokenBilgileri.token
				}
			}) 
			if(res.data["text"] == "duplicate") {
				setUyariTextRenkUV("yellow")
				setUyariTextUV("Bu kullanıcı adı zaten mevcut! Lütfen yeni bir kullanıcı adı girin.")
				setIsShownUV(true)
			} else {
				let yeniBildirim = { mesaj: "Yeni İzleyici", bildirim: 1 }
				props.bildirimleriYonet("kanalRenk", yeniBildirim)
				setUserAd("")
				setUserKullaniciAdi("")
				setUserSifre("")
				setUserMailAdresi("")
				setUserTelefonNumarasi("")
				popupGizleUser()
			}
			} catch {
				let yeniBildirim = { mesaj: "Yeni İzleyici", bildirim: 0 }
				props.bildirimleriYonet("kanalRenk", yeniBildirim)
			}
			}
			else {
				setUyariTextRenkUV("red")
				setUyariTextUV("Lütfen geçerli bir mail adresi giriniz.")
				setIsShownUV(true)
			}
		} else {
			setUyariTextRenkUV("red")
			setUyariTextUV("Lütfen tüm alanları doldurun!")
			setIsShownUV(true)
		}
	}
	
	async function grupKaydet(datalar, bildirimleriYonet) {
		let gonderilecekData = {
			grupAdi: datalar.ad,
			grupYetkilisi: datalar.grupYetkili,
			checkedState: datalar.checkedState
		}
		// console.log(gonderilecekData)
		if(datalar.ad != "") {
			try {
				const res = await axios.put('https://api.enelsa.com:5001/api/grupOlustur', JSON.stringify(gonderilecekData), {
					headers: {
						'Content-Type': 'application/json',
						'authorization': 'Bearer ' + props.tokenBilgileri.token
					}
			}) 
			if(res.data["text"] == "duplicate") {
				setUyariTextRenkGrup("yellow")
				setUyariTextGrup("Bu grup adı zaten mevcut! Lütfen yeni bir grup adı girin.")
				setIsShownGrup(true)
			} else {
				let yeniBildirim = { mesaj: "Yeni Grup", bildirim: 1 }
				props.bildirimleriYonet("kanalRenk", yeniBildirim)
				setGrupAdi("")
				setGrupYetkili("")
				popupGizleGrup()
			}
			} catch {
				let yeniBildirim = { mesaj: "Yeni Grup", bildirim: 0 }
				props.bildirimleriYonet("kanalRenk", yeniBildirim)
			}
		} else {
			setUyariTextRenkGrup("red")
			setUyariTextGrup("Lütfen tüm alanları doldurun!")
			setIsShownGrup(true)
		}
	}
	async function grupKullaniciKaydet(datalar, bildirimleriYonet) {
		const regMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
		let gonderilecekData = {
			type: "grupUser",
			userName: datalar.grupKullaniciKullaniciAdi,
			password: datalar.grupKullaniciSifre,
			nameSurname: datalar.grupKullaniciAd,
			email: datalar.grupKullaniciMailAdresi,
			phone: datalar.grupKullaniciTelefonNumarasi,
			grubu: datalar.grubu
		}
		if(datalar.grupKullaniciKullaniciAdi != "" && datalar.grupKullaniciSifre != "" && datalar.grupKullaniciAd != "") {
			if(regMail.test(datalar.grupKullaniciMailAdresi)) {
				try {
				const res = await axios.put('https://api.enelsa.com:5001/api/grupKullaniciOlustur', JSON.stringify(gonderilecekData), {
				headers: {
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + props.tokenBilgileri.token
				}
			}) 
			if(res.data["text"] == "duplicate") {
				setUyariTextRenkUV("yellow")
				setUyariTextUV("Bu kullanıcı adı zaten mevcut! Lütfen yeni bir kullanıcı adı girin.")
				setIsShownUV(true)
			} else {
				let yeniBildirim = { mesaj: "Yeni Grup Kullanıcısı", bildirim: 1 }
				props.bildirimleriYonet("kanalRenk", yeniBildirim)
				setGrupKullaniciAd("")
				setGrupKullaniciKullaniciAdi("")
				setGrupKullaniciSifre("")
				setGrupKullaniciMailAdresi("")
				setGrupKullaniciTelefonNumarasi("")
				popupGizleGrupKullanici()
				setUyariTextUV("")
			}
			} catch {
				let yeniBildirim = { mesaj: "Yeni Grup Kullanıcısı", bildirim: 0 }
				props.bildirimleriYonet("kanalRenk", yeniBildirim)
			}
			}
			else {
				setUyariTextRenkUV("red")
				setUyariTextUV("Lütfen geçerli bir mail adresi giriniz.")
				setIsShownUV(true)
			}
		} else {
			setUyariTextRenkUV("red")
			setUyariTextUV("Lütfen tüm alanları doldurun!")
			setIsShownUV(true)
		}
	}
	useEffect(() => {
		if (baslangic) return
		setBaslangic(true)
		try {
			props.kullaniciBilgileriGuncelle()
			props.konumGuncelle("kullanici")
			bayiListesiGuncelle()
			yetkiliListesiGuncelle()
			setIsShown(false)
		} catch {}
	}, [props, isShown, popupDurum2])

	return (
		<>
		{props.kullaniciBilgileri.kullaniciTipi == "Factory-Plasiyer" 
			? 
			<div className="kullanici-box factory-plasiyer-box">
				{bayiOlustur()}
			</div>
			: props.kullaniciBilgileri.kullaniciTipi == "Bayi-Admin" 
				?
					<div className="kullanici-box bayi-admin-box">
						{bayiPlasiyerOlustur()}
					</div>
				: props.kullaniciBilgileri.kullaniciTipi == "Bayi-Plasiyer" 
					? 
						<div className="kullanici-box bayi-plasiyer-box">
							{bayiOlustur()}
							{kullaniciNetworkOlustur()}
							{kullaniciOlustur()}
						</div>
					: props.kullaniciBilgileri.kullaniciTipi == "User-Editör"
						? 
							<div className="kullanici-box user-viewer-box">
								{kullaniciOlustur()}
							</div>
						: props.kullaniciBilgileri.kullaniciTipi == "User-Admin" || props.kullaniciBilgileri.kullaniciAdi == "arge_test"
							? 
								<div className="kullanici-box user-viewer-box">
									{kullaniciOlustur()}
									{grupOlustur()}
									{grupKullanicisiOlustur()}
								</div>
							:
							window.location.replace('/anasayfa')
		}
		
		<div className="cihaz-bilgi-kart-popup" style={{ display: popupDurum2 ? "flex" : "none" }}>
			<div className="cihaz-bilgi-kart-popup-cerceve" style={{ height: "300px", padding: "15px", width: "auto", top: popupKonum2 + "px", background: "linear-gradient(90deg, rgba(66,73,79,1) 0%, rgba(121,121,121,0.7763480392156863) 63%)" }}>
				<div className="cihaz-bilgi-kart-popup-icerk icerik">
					<FontAwesomeIcon icon={faX} style={{ alignSelf: "end", color: "#F7FBFF", cursor: "pointer" }} onClick={popupGizle2}/>
					<p style={{ marginInline: "auto", marginBottom: "10px", fontSize: "17px"}}>
						<span style={{ color: "#001a30", fontWeight: "bold" }}>Bayi Ağı</span> İçin Kayıt Yapılacak Bilgiler : 
					</p>
					<div className="textDivs" style={{ display: "flex", flexDirection: "column" }}>
						<span style={{textAlign: "center", margin: "5px"}}>Bayi</span>
						<span><div>Adı</div><em> {": " + bayiAdi} </em></span> 
						<span><div>Mail Adresi</div><em> {": " + mailAdresi} </em> </span>
						<span><div>Telefon Numarası</div><em> {": " + telefonNumarasi} </em> </span>
						<span style={{textAlign: "center", margin: "5px"}}>Admin</span>
						<span><div>Kullanıcı Adı </div><em> {": " + bayiAdminKullaniciAdi} </em> </span> 
						<span><div>Şifre </div><em> {": " + bayiAdminSifre} </em> </span>
						<span><div>İsim Soyisim </div><em> {": " + bayiAdminAd} </em> </span>
						<span><div> Mail Adresi </div><em> {": " + bayiAdminMailAdresi} </em> </span>
						<span><div> Telefon Numarası </div><em> {": " + bayiAdminTelefonNumarasi} </em> </span>
					</div>
					<div style={{ display: "flex" }} className="butonDiv">
						<div style={{ display: "flex", flexDirection: "column" }}>
							<span style={{ margin: "15px" }} >İşlemi onaylıyor musunuz?</span>
						<span style={{ color: uyariTextRenk}}> {isShown && uyariText} </span> 
						</div>
						<div className="arka-taraf-birimler-buton2" onClick={popupGizle2} style={{ marginRight: "15px" }}>
							<span>Yeniden Düzenle</span>
						</div>
						<div className="arka-taraf-birimler-buton2" onClick={() => {bayiKaydet({bayiAdi: bayiAdi, mailAdresi: mailAdresi, telefonNumarasi: telefonNumarasi, userName: bayiAdminKullaniciAdi, password: md5(bayiAdminSifre), nameSurname: bayiAdminAd, email: bayiAdminMailAdresi, phone: bayiAdminTelefonNumarasi})}}>
							<span>Kaydet</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="cihaz-bilgi-kart-popup" style={{ display: popupDurumBayiPlasiyer ? "flex" : "none" }}>
			<div className="cihaz-bilgi-kart-popup-cerceve" style={{ height: "200px", padding: "15px", width: "auto", top: popupKonumBayiPlasiyer + "px", background: "linear-gradient(90deg, rgba(66,73,79,1) 0%, rgba(121,121,121,0.7763480392156863) 63%)" }}>
				<div className="cihaz-bilgi-kart-popup-icerk icerik">
					<FontAwesomeIcon icon={faX} style={{ alignSelf: "end", color: "#F7FBFF", cursor: "pointer" }} onClick={popupGizleBayiPlasiyer}/>
					<p style={{ marginInline: "auto", marginBottom: "10px", fontSize: "17px"}}>
						<span style={{ color: "#001a30", fontWeight: "bold" }}>Bayi Plasiyeri</span> İçin Kayıt Yapılacak Bilgiler : 
					</p>
					<div className="textDivs" style={{ display: "flex", flexDirection: "column" }}>
						<span><div>İsim Soyisim</div><em> {": " + bayiPlasiyerAd} </em></span> 
						<span><div>Kullanıcı Adı</div><em> {": " + bayiPlasiyerKullaniciAdi} </em></span> 
						<span><div>Şifre</div><em> {": " + bayiPlasiyerSifre} </em> </span>
						<span><div>Mail Adresi</div><em> {": " + bayiPlasiyerMailAdresi} </em> </span>
						<span><div>Telefon Numarası</div><em> {": " + bayiPlasiyerTelefonNumarasi} </em> </span>
					</div>
					<div style={{ display: "flex" }} className="butonDiv">
						<div style={{ display: "flex", flexDirection: "column" }}>
							<span style={{ margin: "15px" }} >İşlemi onaylıyor musunuz?</span>
						<span style={{ color: uyariTextRenkBP}}> {isShownBP && uyariTextBP} </span> 
						</div>
						<div className="arka-taraf-birimler-buton2" onClick={popupGizleBayiPlasiyer} style={{ marginRight: "15px" }}>
							<span>Yeniden Düzenle</span>
						</div>
						<div className="arka-taraf-birimler-buton2" onClick={() => {bayiPlasiyerKaydet({bayiPlasiyerAd: bayiPlasiyerAd, bayiPlasiyerKullaniciAdi: bayiPlasiyerKullaniciAdi,bayiPlasiyerSifre: md5(bayiPlasiyerSifre), email: bayiPlasiyerMailAdresi, phone: bayiPlasiyerTelefonNumarasi})}}>
							<span>Kaydet</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="cihaz-bilgi-kart-popup" style={{ display: popupDurumUserAdmin ? "flex" : "none" }}>
			<div className="cihaz-bilgi-kart-popup-cerceve" style={{ height: "200px", padding: "15px", width: "auto", top: popupKonumUserAdmin + "px", background: "linear-gradient(90deg, rgba(66,73,79,1) 0%, rgba(121,121,121,0.7763480392156863) 63%)" }}>
				<div className="cihaz-bilgi-kart-popup-icerk icerik">
					<FontAwesomeIcon icon={faX} style={{ alignSelf: "end", color: "#F7FBFF", cursor: "pointer" }} onClick={popupGizleUserAdmin}/>
					<p style={{ marginInline: "auto", marginBottom: "10px", fontSize: "17px"}}>
						<span style={{ color: "#001a30", fontWeight: "bold" }}>{networkAdi}</span> Ağı İçin Kayıt Yapılacak Bilgiler : 
					</p>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<span style={{textAlign: "center", margin: "5px"}}>Admin</span>
						<span>İsim Soyisim : <em> {userAdminAd} </em></span> 
						<span>Kullanıcı Adı : <em> {userAdminKullaniciAdi} </em></span> 
						<span>Şifre : <em> {userAdminSifre} </em> </span>
						<span>Mail Adresi : <em> {userAdminMailAdresi} </em> </span>
						<span>Telefon Numarası : <em> {userAdminTelefonNumarasi} </em> </span>
					</div>
					<div style={{ display: "flex" }} className="butonDiv">
						<div style={{ display: "flex", flexDirection: "column" }}>
							<span style={{ margin: "15px" }} >İşlemi onaylıyor musunuz?</span>
						<span style={{ color: uyariTextRenkUV}}> {isShownUV && uyariTextUV} </span> 
						</div>
						<div className="arka-taraf-birimler-buton2" onClick={popupGizleUserAdmin} style={{ marginRight: "15px" }}>
							<span>Yeniden Düzenle</span>
						</div>
						<div className="arka-taraf-birimler-buton2" onClick={() => {userAdminKaydet({networkAdi:networkAdi, userAdminAd: userAdminAd, userAdminKullaniciAdi: userAdminKullaniciAdi,userAdminSifre: md5(userAdminSifre), userAdminMailAdresi: userAdminMailAdresi, userAdminTelefonNumarasi: userAdminTelefonNumarasi})}}>
							<span>Kaydet</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="cihaz-bilgi-kart-popup" style={{ display: popupDurumUser ? "flex" : "none" }}>
			<div className="cihaz-bilgi-kart-popup-cerceve" style={{ height: "200px", padding: "15px", width: "auto", top: popupKonumUser + "px", background: "linear-gradient(90deg, rgba(66,73,79,1) 0%, rgba(121,121,121,0.7763480392156863) 63%)" }}>
				<div className="cihaz-bilgi-kart-popup-icerk icerik">
					<FontAwesomeIcon icon={faX} style={{ alignSelf: "end", color: "#F7FBFF", cursor: "pointer" }} onClick={popupGizleUser}/>
					<p style={{ marginInline: "auto", marginBottom: "10px", fontSize: "17px"}}>
						<span style={{ color: "#001a30", fontWeight: "bold" }}>Kullanıcı</span> İçin Kayıt Yapılacak Bilgiler : 
					</p>
					<div className="textDivs" style={{ display: "flex", flexDirection: "column" }}>
						<span><div>İsim Soyisim</div><em> {": " + userAd} </em></span> 
						<span><div>Kullanıcı Adı</div><em> {": " + userKullaniciAdi} </em></span> 
						<span><div>Şifre</div><em> {": " + userSifre} </em> </span>
						<span><div>Mail Adresi</div><em> {": " + userMailAdresi} </em> </span>
						<span><div>Telefon Numarası</div><em> {": " + userTelefonNumarasi} </em> </span>
					</div>
					<div style={{ display: "flex" }} className="butonDiv">
						<div style={{ display: "flex", flexDirection: "column" }}>
							<span style={{ margin: "15px" }} >İşlemi onaylıyor musunuz?</span>
						<span style={{ color: uyariTextRenkUV}}> {isShownUV && uyariTextUV} </span> 
						</div>
						<div className="arka-taraf-birimler-buton2" onClick={popupGizleUser} style={{ marginRight: "15px" }}>
							<span>Yeniden Düzenle</span>
						</div>
						<div className="arka-taraf-birimler-buton2" onClick={() => {userKaydet({userAd: userAd, userKullaniciAdi: userKullaniciAdi,userSifre: md5(userSifre), userMailAdresi: userMailAdresi, userTelefonNumarasi: userTelefonNumarasi})}}>
							<span>Kaydet</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="cihaz-bilgi-kart-popup" style={{ display: popupDurumGrup ? "flex" : "none" }}>
			<div className="cihaz-bilgi-kart-popup-cerceve" style={{ height: "200px", padding: "15px", width: "auto", top: popupKonumGrup + "px", background: "linear-gradient(90deg, rgba(66,73,79,1) 0%, rgba(121,121,121,0.7763480392156863) 63%)" }}>
				<div className="cihaz-bilgi-kart-popup-icerk icerik">
					<FontAwesomeIcon icon={faX} style={{ alignSelf: "end", color: "#F7FBFF", cursor: "pointer" }} onClick={popupGizleGrup}/>
					<p style={{ marginInline: "auto", marginBottom: "10px", fontSize: "17px"}}>
						<span style={{ color: "#001a30", fontWeight: "bold" }}>Grup</span> İçin Kayıt Yapılacak Bilgiler : 
					</p>
					<div className="textDivs" style={{ display: "flex", flexDirection: "column" }}>
						<span><div>Grup Adı</div><em> {": " + grupAdi} </em></span> 
						<span><div>Yetkililer</div><em> {": " + grupYetkili} </em></span> 
						{/* <span><div>Yetkiler</div><em> {": "} {listOlustur(yetkiList,checkedState)} </em></span>  */}
					</div>
					<div style={{ display: "flex" }} className="butonDiv">
						<div style={{ display: "flex", flexDirection: "column" }}>
							<span style={{ margin: "15px" }} >İşlemi onaylıyor musunuz?</span>
						<span style={{ color: uyariTextRenkGrup}}> {isShownGrup && uyariTextGrup} </span> 
						</div>
						<div className="arka-taraf-birimler-buton2" onClick={popupGizleGrup} style={{ marginRight: "15px" }}>
							<span>Yeniden Düzenle</span>
						</div>
						<div className="arka-taraf-birimler-buton2" onClick={() => grupKaydet({grupYetkili: grupYetkili, ad: grupAdi, checkedState: checkedState})}>
							<span>Kaydet</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="cihaz-bilgi-kart-popup" style={{ display: popupDurumGrupKullanici ? "flex" : "none" }}>
			<div className="cihaz-bilgi-kart-popup-cerceve" style={{ height: "200px", padding: "15px", width: "auto", top: popupKonumGrupKullanici + "px", background: "linear-gradient(90deg, rgba(66,73,79,1) 0%, rgba(121,121,121,0.7763480392156863) 63%)" }}>
				<div className="cihaz-bilgi-kart-popup-icerk icerik">
					<FontAwesomeIcon icon={faX} style={{ alignSelf: "end", color: "#F7FBFF", cursor: "pointer" }} onClick={popupGizleGrupKullanici}/>
					<p style={{ marginInline: "auto", marginBottom: "10px", fontSize: "17px"}}>
						<span style={{ color: "#001a30", fontWeight: "bold" }}>Grup Kullanıcısı</span> İçin Kayıt Yapılacak Bilgiler : 
					</p>
					<div className="textDivs" style={{ display: "flex", flexDirection: "column" }}>
						<span><div>Grubu</div><em> {": " + kullaniciGrubu} </em></span> 
						<span><div>İsim Soyisim</div><em> {": " + grupKullaniciAd} </em></span> 
						<span><div>Kullanıcı Adı</div><em> {": " + grupKullaniciKullaniciAdi} </em></span> 
						<span><div>Şifre</div><em> {": " + grupKullaniciSifre} </em> </span>
						<span><div>Mail Adresi</div><em> {": " + grupKullaniciMailAdresi} </em> </span>
						<span><div>Telefon Numarası</div><em> {": " + grupKullaniciTelefonNumarasi} </em> </span>
					</div>
					<div style={{ display: "flex" }} className="butonDiv">
						<div style={{ display: "flex", flexDirection: "column" }}>
							<span style={{ margin: "15px" }} >İşlemi onaylıyor musunuz?</span>
						<span style={{ color: uyariTextRenkUV}}> {isShownUV && uyariTextUV} </span> 
						</div>
						<div className="arka-taraf-birimler-buton2" onClick={popupGizleGrupKullanici} style={{ marginRight: "15px" }}>
							<span>Yeniden Düzenle</span>
						</div>
						<div className="arka-taraf-birimler-buton2" onClick={() => grupKullaniciKaydet({grubu: kullaniciGrubu, grupKullaniciAd: grupKullaniciAd, grupKullaniciKullaniciAdi: grupKullaniciKullaniciAdi,grupKullaniciSifre: md5(grupKullaniciSifre), grupKullaniciMailAdresi: grupKullaniciMailAdresi, grupKullaniciTelefonNumarasi: grupKullaniciTelefonNumarasi})}>
							<span>Kaydet</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	)
}

export default KullaniciEkle
